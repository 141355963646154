import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {InspectionFormsList, SIGNS_TYPES, WorkPermitTypes} from '@/globals';
import {JobNonConformityRecordsModel, JobSubmitedWorkPermitModel, WorkPermitModel} from '@/sdk';
import {JobApi} from '@/sdk/api-services/job.api';
import {Component} from 'vue-property-decorator';
import {
    AdminDataFieldService,
    JobSubmittedWorkPermitsService,
    JobsInspectionFormsService,
    SiteTeamMembersService,
    WorkPermitsService,
} from '../../shared/services';
import {JobService} from '../../shared/services/job.service';

import JobLinkWorkerComponent from './job-link-inspector/job-link-worker.component';
import JobLinkJobManagerComponent from './job-link-job-manager/job-link-job-manager.component';
import SingleInspectionFormComponent from '../../shared/single-inspection-form/single-inspection-form.component';
import {WorkPermitApi} from '@/sdk/api-services';
import SubmitJobWorkPermitDialogComponent from './submit-job-work-permit-dialog/submit-job-work-permit.dialog';
import JobNonConformityRecordFilesDialogComponent from './job-non-conformity-record-files-dialog/job-non-conformity-record-files-dialog.component';
import JobStatusComponent from './job-status/job-status.component';
import WorkPermitHistoryComponent from './work-permit-history/work-permit-history.component';
import JobCorrectiveActionStatusComponent from './job-corrective-action-status/job-corrective-action-status.component';
import JobDetailsFormComponent from './job-details-form/job-details-form.component';

@Component({
    components: {
        JobLinkWorkerComponent,
        JobLinkJobManagerComponent,

        // InspectionFormExdComponent,
        // InspectionFormExeComponent,
        // InspectionFormExnComponent,
        // InspectionFormExtComponent,
        // InspectionFormExpComponent,
        // InspectionFormExiComponent,

        // NormalWorkPermitComponent,
        // HotWorkComponent,
        // ConfinedSpaceWorkPermitComponent,
        // ElectricalWorkPermitComponent,
        // GassFreePermitComponent,

        // AddWorkPermitForJobDialogComponent,
        SingleInspectionFormComponent,
        SubmitJobWorkPermitDialogComponent,
        JobStatusComponent,
        WorkPermitHistoryComponent,
        JobCorrectiveActionStatusComponent,
        JobDetailsFormComponent,
    },
})
export default class JobDetailsComponent extends VueWrapper {
    public jobsSrv: JobService = new JobService();

    public siteTeamMembersSrv: SiteTeamMembersService = new SiteTeamMembersService();
    public jobSubmittedWorkPermitsSrv: JobSubmittedWorkPermitsService = new JobSubmittedWorkPermitsService();
    public jobsInspectionFormSrv: JobsInspectionFormsService = new JobsInspectionFormsService();

    public adminDataFieldSrv: AdminDataFieldService = new AdminDataFieldService();

    public jobsApi: JobApi = new JobApi();

    // public permiRequestPanel: number | null = null;

    // public draftNormalWPExpansion: number | null = null;
    // public activeNormalWPExpansion: number | null = null;
    // public pastNormalWPExpansion: number | null = null;

    // public activeNormalWPExpansionList: number | null = null;

    public workPermitsSrv: WorkPermitsService = new WorkPermitsService();

    public inspectionTab: string | null = null;
    public jobPersonnelsTab: number | null = null;

    public get inspetionForms() {
        return InspectionFormsList;
    }

    public get SIGNS_TYPES() {
        return SIGNS_TYPES;
    }

    public onNewJobClick() {
        this.loadInspectionFormQuestions();
    }

    // public getWorkPermitStatus(id: number) {
    //     switch (id) {
    //         case 0:
    //             return 'Not Yet Requested';
    //             break;
    //         case 1:
    //             return 'Requested For Safety Guidelines';
    //             break;
    //         case 2:
    //             return 'Approved For Safety Guidelines';
    //             break;
    //         case 3:
    //             return 'Work can commence';
    //             break;
    //         case 4:
    //             return 'Completed';
    //             break;
    //         default:
    //             break;
    //     }
    // }

    // public getSafetyGuidelineStatus(id: number) {
    //     switch (id) {
    //         case 0:
    //             return 'Not Yet Issued';
    //             break;
    //         case 1:
    //             return 'Issued';
    //             break;
    //         case 2:
    //             return 'Confirmed';
    //             break;
    //         case 3:
    //             return 'Completed';
    //             break;
    //         default:
    //             break;
    //     }
    // }

    public addWorkPermitClick() {
        new JobSubmittedWorkPermitsService().reset();
        // this.$refs.addWorkPermitForJobDialogRef.open();
    }

    public jobDetailsTabClick() {
        // this.jobsSrv.getJobNonConformityRecords(this.jobsSrv.selectedJob!.id!);
    }

    // public editWorkPermit(permit: JobNormalWorkPermit) {
    //     this.jobNormalWorkPermitSrv.reset();

    //     if (this.jobsSrv.selectedJob?.id && permit.id) {
    //         this.LoaderSrv.showFullScreenLoader();
    //         this.jobNormalWorkPermitSrv
    //             .getJobNormalWorkPermitById(this.jobsSrv.selectedJob.id, permit.id)
    //             .catch(err => {
    //                 this.AlertSrv.show('error', err.message || err.error?.message || 'Unable to load work permit.');
    //             })
    //             .then(() => {
    //                 this.$refs.addWorkPermitForJobDialogRef.open();
    //             })
    //             .finally(() => {
    //                 this.LoaderSrv.hideFullScreenLoader();
    //             });
    //     }
    // }

    public inspectionTabChange(e: any) {
        this.loadInspectionFormQuestions();
    }

    public async loadInspectionFormQuestions() {
        if (this.inspectionTab) {
            this.LoaderSrv.showFullScreenLoader();
            try {
                await this.jobsInspectionFormSrv.loadInspectionFormQuestionsAgainsJob(
                    this.inspectionTab,
                    this.jobsSrv.selectedJob!.id!,
                    this.UserSessionSrv.userId!
                );
            } catch (error) {
                this.AlertSrv.show('error', 'Unable to load inspection forms.');
            } finally {
                this.LoaderSrv.hideFullScreenLoader();
            }
        }
    }

    public saveAssignedWorkPermits() {
        // if (this.jobsSrv.selectedJob) {
        //     this.LoaderSrv.showFullScreenLoader();
        //     this.jobsApi
        //         .saveAssignedJobWorkPermits(this.jobsSrv.selectedJob.id!, this.jobsSrv.selectedWorkPermits)
        //         .subscribe(
        //             res => {
        //                 if (this.jobsSrv.selectedJob) {
        //                     this.jobsSrv.getJobWorkPermists(this.jobsSrv.selectedJob.id!);
        //                     this.AlertSrv.show('success', 'Work Permits has been assigned successfully!');
        //                 }
        //             },
        //             err => {
        //                 this.AlertSrv.show('error', 'Unalbe to assign Work Permits to job.');
        //             }
        //         )
        //         .add(() => {
        //             this.LoaderSrv.hideFullScreenLoader();
        //         });
        // }
    }

    public saveAssignedInspectionForms() {
        if (this.jobsSrv.selectedJob) {
            this.LoaderSrv.showFullScreenLoader();
            this.jobsApi
                .saveAssignedInspectionForms(this.jobsSrv.selectedJob.id!, this.jobsSrv.selectedInspectionForms)
                .subscribe(
                    res => {
                        if (this.jobsSrv.selectedJob) {
                            // this.jobsSrv.getJobAssignedInspectionForms(this.jobsSrv.selectedJob.id!);
                            this.AlertSrv.show('success', 'Inspection Forms has been assigned successfully!');
                        }
                    },
                    err => {
                        this.AlertSrv.show('error', 'Unalbe to assign Inspection Forms to job.');
                    }
                )
                .add(() => {
                    this.LoaderSrv.hideFullScreenLoader();
                });
        }
    }
}
