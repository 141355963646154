import {AnyObject} from '@/globals';
export class SiteModel {
    public id?: string | null = null;
    public organizationCode: string | null = null;
    public organizationName?: string | null = null;
    public orgAddress?: string | null = null;
    public orgWebsite?: string | null = null;
    public orgContactName?: string | null = null;
    public orgContactEmail?: string | null = null;
    public orgContactTelephone?: string | null = null;
    public orgContactMobile?: string | null = null;
    public siteCode?: string | null = null;
    public siteName?: string | null = null;
    public siteAddress?: string | null = null;
    public jobOrderCode?: string | null = null;
    public jobOrderName?: string | null = null;
    // public role?: string | null = null;
    public jobOrderStatus?: string | null = null;
    public jobOrderStartDate?: Date | string | null = null;
    public jobOrderEndDate?: Date | string | null = null;
    // public accountStatus?: string | null = null;
    // public licenseType?: string | null = null;
    // public licenseStatus?: string | null = null;
    // public licenseStartDate?: Date | string | null = null;
    // public licenseEndDate?: Date | string | null = null;
    // public accountStartDate?: Date | string | null = null;
    // public accountEndDate?: Date | string | null = null;
    // public accountNumber?: string | null = null;
    // public accountType?: string | null = null;
    // public paymentMethod?: string | null = null; //a
    // public paymentStatus?: string | null = null;
    // public userLoginId?: string | null = null;
    // public userLoginPassword?: string | null = null;
    // public userRole?: string | null = null;
    // public userSupervisor?: string | null = null;
    public emergencyContactName?: string | null = null;
    public emergencyContactEmail?: string | null = null;
    public emergencyContactTelephone?: string | null = null;
    public emergencyContactMobile?: string | null = null;
    public orgEmergencyContactName?: string | null = null;
    public orgEmergencyContactEmail?: string | null = null;
    public orgEmergencyContactTelephone?: string | null = null;
    public orgEmergencyContactMobile?: string | null = null;
    public localEmergencyResponse?: string | null = null; //a
    public accountId: string | null = null;
    public drawingCanvas1?: string | null = null;
    public created?: string | null = null;
    public updated?: string | null = null;

    // areas: Area[];

    [key: string]: any;

    constructor(data?: Partial<SiteModel>) {
        Object.assign(this, data);
    }
}
