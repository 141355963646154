import MainLayoutComponent from '@/components/layouts/main-layout/main-layout.component';
import {UserSession} from '@/sdk';
import {AuthenticationRoutes} from '@/views/authentication/routes/authentication.routes';
import {ClientRoutes} from '@/views/client/client.routes';
import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import goTo from 'vuetify/lib/services/goto';
import Home from '../views/Home.vue';
import {JobSetupRoutes} from '@/views/job-setup/routes/job-setup.routes';
import {TeamSetupRoutes} from '@/views/team-setup/routes/team-setup.routes';
import {JobReportRoutes} from '@/views/job-report/routes/job-report.routes';
// import {UserSetupRoutes} from '@/views/user-setup/routes/user-setup.routes';
import AdminLayoutComponent from '@/components/layouts/admin-layout/admin-layout.component';
import {AdminPanelRoutes} from '@/views/admin-panel/admin-panel.routes';
import {AccountRoutes} from '@/views/account/account.routes';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    ...ClientRoutes,

    ...AccountRoutes,

    ...AuthenticationRoutes,
    // ...SiteSeuptRoutes,
    ...JobSetupRoutes,
    ...TeamSetupRoutes,
    ...JobReportRoutes,
    // ...UserSetupRoutes,
    ...AdminPanelRoutes,

    {
        path: '*',
        component: MainLayoutComponent,
        // children: [
        //     {
        //         path: '*',

        //         name: 'Page Not Found',
        //         component: PageNotFoundComponent,
        //         meta: {
        //             title: 'Error',
        //             robots: 'Content=noindex, nofollow'
        //         }
        //     }
        // ]
    },
];

// const router = new VueRouter({
//     mode: 'history',
//     base: process.env.BASE_URL,
//     scrollBehavior: (to, from, savedPosition) => {
//         let scrollTo: any = 0;

//         if (to.hash) {
//             scrollTo = to.hash;
//         } else if (savedPosition) {
//             scrollTo = savedPosition.y;
//         }

//         return goTo(scrollTo);
//     },
//     routes,
// });

const UserSessionSrv = new UserSession();

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,

    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo: any = 0;

        if (to.hash) {
            scrollTo = to.hash;
        } else if (savedPosition) {
            scrollTo = savedPosition.y;
        }

        return goTo(scrollTo);
    },
    routes,
});

router.beforeEach((to, from, next) => {
    // Get current user from cookie.
    const isUserLoggedIn = UserSessionSrv.isUserAuthenticated;
    // new ApiAuth().SessionValue && !!new ApiAuth().SessionValue!.UserId;

    const isNonAuthRoute = ['auth', 'signin', 'signup', 'Forgot Password', 'Resend Email', 'Cancelled Membership'].includes(to.name!);
    const parentRouteNames = routes.map(route => {
        return route.name;
    });
    const childRoutes: any = [];

    function findingRouteNames(routes: Array<RouteConfig>) {
        routes.forEach(route => {
            if ('children' in route) {
                findingRouteNames(route.children!);
            } else {
                childRoutes.push(route.name);
            }
        });
    }
    findingRouteNames(routes);

    // const allRouteNames = [...parentRouteNames, ...childRoutes];
    // const isRouteExist = allRouteNames.includes(to.name);
    const isPublicRoute = [
        'accountCreation',
        'Page Not Found',
        'informationDetails',
        'informationDetailsArea',
        'informationDetailsSub',
        'informationDetailsEquipment',
        'searchSite',
        'buttonBar',
        'header',
    ].includes(to.name!);

    // new FilterService().Filter.Status = null;
    // new StateService();

    // if (!isRouteExist) {
    //     console.log(allRouteNames);

    //     next({name: 'Page Not Found'});
    // } else
    // Perform Authentication

    // console.log('route exists: ', isRouteExist);
    // if (!isRouteExist) {
    //     next({name: 'Page Not Found'});
    // } else
    if (isPublicRoute) {
        next();
    } else if (!isUserLoggedIn && isNonAuthRoute) {
        next();
    } else if (isUserLoggedIn && isNonAuthRoute) {
        next({name: 'sites-list', query: {...to.query, redirect_reason: 'UNAUTHORIZIED'}});
    } else if (!isUserLoggedIn && !isNonAuthRoute) {
        next({name: 'signin', query: {redirect_reason: 'UNAUTHORIZIED'}});
    } else {
        next();
    }

    // scrollToTop();
});

export default router;
