import {ServiceClass} from '@/decorators';
import {CertificationSchemeModel} from '@/sdk';
import {CertificationSchemesApi} from '@/sdk/api-services';

@ServiceClass()
export class CertificationSchemesService {
    public certificationSchemesApi: CertificationSchemesApi = new CertificationSchemesApi();

    public certificationSchemesData: Array<CertificationSchemeModel> = [];

    public selectedObj: CertificationSchemeModel | null = null;

    public loadCertificationSchemes() {
        return this.certificationSchemesApi.getCertificationSchemesAsync().then(x => {
            this.certificationSchemesData = x ?? [];

            return x;
        });
    }

    public loadCertificationSchemeById(id: string) {
        return this.certificationSchemesApi.getCertificationSchemeByIdAsync(id).then(x => {
            this.selectedObj = x ?? null;

            return x;
        });
    }
}
