import JobReportLayoutComponent from '@/views/job-report/layout/job-report-layout.component.vue';
import JobReportMainComponent from '@/views/job-report/job-report-main/job-report-main.component.vue';
import {RouteConfig} from 'vue-router';

export const JobReportRoutes: Array<RouteConfig> = [
    {
        path: '/job-report',
        name: 'Job Report',
        component: JobReportLayoutComponent,
        redirect: {name: 'site-jobs-report'},
        children: [
            {
                // Should it be the Site or the Inspector?
                path: 'main/:siteId',
                name: 'site-jobs-report',
                component: JobReportMainComponent,
                meta: {
                    title: '',
                    description: '',
                },
            },
        ],
    },
];
