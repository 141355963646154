import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {Component} from 'vue-property-decorator';
import {AutentictionApi} from '@/sdk/api-services';

@Component
export default class AccountCreationComponent extends VueWrapper {
    public firstName: String | null = null;
    public lastName: String | null = null;
    public email: String | null = null;
    // public username: String | null = null;
    public password: String | null = null;

    public authenticationApi: AutentictionApi = new AutentictionApi();

    public createAccount() {
        this.LoaderSrv.showFullScreenLoader();
        this.authenticationApi
            .CreateAccount({
                firstName: this.firstName,
                lastName: this.lastName,
                email: this.email,
                // username: this.username,
                password: this.password,
                emailVerified: true,
                active: true,
            })
            .subscribe(
                res => {
                    if (res.Data) {
                        // console.log('USER CREATED: ' + this.username);
                    }
                    console.log(res);
                },
                err => {
                    console.log('err', err);
                }
            )
            .add(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }
}
