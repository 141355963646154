import {ServiceClass} from '@/decorators';
import {BaseApi} from './base.api';
import {AnyObject, Count} from '@/globals';

@ServiceClass()
export class DashboardApi extends BaseApi {
    public getAreasCountBySiteId(siteId: string) {
        return this.GET_RequestAsync<Count>(`${this.ApiUrl}/dashboard/sites/${siteId}/areas-count`);
    }

    public getSubAreasCountBySiteId(siteId: string) {
        return this.GET_RequestAsync<Count>(`${this.ApiUrl}/dashboard/sites/${siteId}/sub-areas-count`);
    }

    public getEquipmentCountBySiteId(siteId: string) {
        return this.GET_RequestAsync<Count>(`${this.ApiUrl}/dashboard/sites/${siteId}/equipment-count`);
    }

    public getWarningsCountBySiteId(siteId: string) {
        return this.GET_RequestAsync<Count>(`${this.ApiUrl}/dashboard/sites/${siteId}/warnings-count`);
    }

    public getCompletedCorrectiveActionsCount(siteId: string) {
        return this.GET_RequestAsync<Count>(`${this.ApiUrl}/dashboard/sites/${siteId}/corrective-actions-count/completed`);
    }

    public getOnGoingCorrectiveActionsCount(siteId: string) {
        return this.GET_RequestAsync<Count>(`${this.ApiUrl}/dashboard/sites/${siteId}/corrective-actions-count/on-going`);
    }

    public getCompletedInspectionsCount(siteId: string) {
        return this.GET_RequestAsync<Count>(`${this.ApiUrl}/dashboard/sites/${siteId}/inspections-count/completed`);
    }

    public getOnGoingInspectionsCount(siteId: string) {
        return this.GET_RequestAsync<Count>(`${this.ApiUrl}/dashboard/sites/${siteId}/inspections-count/on-going`);
    }

    public getDelayedInspectionsCount(siteId: string) {
        return this.GET_RequestAsync<Count>(`${this.ApiUrl}/dashboard/sites/${siteId}/inspections-count/delayed`);
    }

    public getAsPlannedInspectionsCount(siteId: string) {
        return this.GET_RequestAsync<Count>(`${this.ApiUrl}/dashboard/sites/${siteId}/inspections-count/as-planned`);
    }

    public getLocationCountOfEachTypeByTimeType(siteId: string, countBy: string) {
        return this.GET_RequestAsync<{areasCount: AnyObject; equipmentsCount: AnyObject}>(`${this.ApiUrl}/dashboard/sites/${siteId}/location-count/${countBy}`);
    }
}
