import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {Component, Prop} from 'vue-property-decorator';

@Component
export default class InspectionFormExiComponent extends VueWrapper {
    @Prop({
        required: false,
    })
    gradeOfInspection!: Array<string>;

    public d = ['D'];
    public c = ['C'];
    public v = ['V'];
    public dc = ['D', 'C'];
    public dv = ['D', 'V'];
    public cv = ['C', 'V'];
    public dcv = ['D', 'C', 'V'];

    public results: Array<String> = ['Pass', 'Fail', 'NA', ''];
    public grade1to10: Array<String> = ['10', '9', '8', '7', '6', '5', '4', '3', '2', '1'];
    public grade1to10odd: Array<String> = ['10', '9', '7', '5', '3', '1'];
    public grade5to10: Array<String> = ['10', '9', '8', '7', '6', '5'];
    public grade1to5to10: Array<String> = ['10', '5', '4', '3', '2', '1'];
    public grade5to7: Array<String> = ['10', '7', '5'];
    public grade1to5: Array<String> = ['5', '3', '1'];
}
