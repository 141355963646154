import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {Component} from 'vue-property-decorator';

import ClientAppBarComponent from '@/components/vuetify/client-app-bar/client-app-bar.component';
import JobReportMainComponent from '../job-report-main/job-report-main.component';
import SiteSetupButtonBarComponent from '@/views/client/site-setup/site-setup-button-bar/site-setup-button-bar.component';

@Component({
    components: {
        ClientAppBarComponent,
        JobReportMainComponent,
        SiteSetupButtonBarComponent,
    },
})
export default class JobSetupLayoutComponent extends VueWrapper {}
