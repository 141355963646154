import {SwitchBoardModel} from './switch-boards.model';

export class DistributionPanelModel {
    id?: string | null = null;
    projectName?: string | null = null;
    tag?: string | null = null;
    type?: string | null = null;
    powerBoardType?: string | null = null;
    powerBoardVoltage?: string | null = null;
    powerBoardFrequency?: string | null = null;
    created?: string | null = null;
    updated?: string | null = null;
    locationId?: string | null = null;
    locationName?: string | null = null;
    parentId?: string | null = null;

    switchBoards?: Array<SwitchBoardModel> | null = null;

    constructor(data?: Partial<DistributionPanelModel>) {
        Object.assign(this, data);
    }
}
