import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {Component} from 'vue-property-decorator';

@Component
export default class InformationDetailsAreaComponent extends VueWrapper {
    items: Array<String> = ['Option 1', 'Option 2', 'Option 3', 'Option 4', 'Option 5', 'Option 6', 'Option 7', 'Option 8'];
    scrollInvoked: number = 0;

    onScroll() {
        this.scrollInvoked++;
    }
}
