import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {DocumentationModel, UploadedFilesModel} from '@/sdk';
import {Component} from 'vue-property-decorator';

@Component
export default class PreviewDocumentDialogComponent extends VueWrapper {
    public readonly name: string = 'preview-document-dialog';

    public document?: DocumentationModel | null = null;
    public uploadedFile?: UploadedFilesModel | null = null;

    public open(d?: DocumentationModel, uploadedFile?: UploadedFilesModel) {
        this.document = d;
        this.uploadedFile = uploadedFile;

        this.CoreSrv.OpenModal(this.name);
    }

    public close() {
        this.CoreSrv.CloseModal(this.name);
    }
}
