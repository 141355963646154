import {ServiceClass} from '@/decorators';
import {BaseApi} from './base.api';
import {DustDbModel, GasDbModel} from '../models';

@ServiceClass()
export class DustDbApi extends BaseApi {
    public uploadFile(file: File) {
        const formData = new FormData();
        formData.append('file', file);

        return this.POST_FileRequest(`${this.ApiUrl}/dust-db/upload-file`, formData);
    }

    public getDustDb() {
        return this.GET_Request<Array<DustDbModel>>(`${this.ApiUrl}/dust-db`);
    }
}
