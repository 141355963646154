import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {KeyValuePair} from '@/globals';
import {LocationModel} from '@/sdk';
import {SitesApi} from '@/sdk/api-services';
import {LocationApi} from '@/sdk/api-services/location.api';
import {Component} from 'vue-property-decorator';
import {LocationDcoumentationService} from '../services';
import {SiteLocationService} from '../services/site-location.service';

@Component({
    name: 'SaveDocumentationFolderDialogComponent',
})
export default class SaveDocumentationFolderDialogComponent extends VueWrapper {
    public readonly name: string = 'save-documentation-folder';
    public locationDocumentationSrv: LocationDcoumentationService = new LocationDcoumentationService();

    public mounted() {
        //
    }

    public saveFolder() {
        this.locationDocumentationSrv.SaveDocumantation();
    }
}
