import VueWrapper from '@/components/core/Vue/vue.wrapper';
import AdminAppBarComponent from '@/components/vuetify/admin-app-bar/admin-app-bar.component';
import AdminDrawerComponent from '@/components/vuetify/admin-drawer/admin-drawer.component';
import {Component} from 'vue-property-decorator';

@Component({
    components: {
        AdminAppBarComponent,
        AdminDrawerComponent,
    },
})
export default class AdminLayoutComponent extends VueWrapper {}
