export class SignupViewModel {
    invitationCode: string | null = null;
    firstName: string | null = null;
    middleName?: string | null = null;
    lastName: string | null = null;
    email: string | null = null;
    workPhone?: string | null = null;
    mobilePhone?: string | null = null;
    password: string | null = null;
    confirmPassword: string | null = null;
    accountTitle: string | null = null;
    accountType: string | null = null;
    period: string | null = null;
    paymentMethod: string | null = null;
    termsAndConditions: boolean | null = null;
    accountId: string | null = null;

    constructor(data?: Partial<SignupViewModel>) {
        Object.assign(this, data);
    }
}
