export class CreateUserViewModel {
    public id?: string | null = null;
    public firstName?: string | null = null;
    public middleName?: string | null = null;
    public lastName?: string | null = null;
    public loginId?: string | null = null;
    public email?: string | null = null;

    public password?: string | null = null;
    public secondaryEmail?: string | null = null;
    public companyName?: string | null = null;
    public companyWebsite?: string | null = null;
    public workEmail?: string | null = null;
    public workPhone?: string | null = null;
    public mobilePhone?: string | null = null;

    public accountId?: string | null = null;

    // public accountType?: string | null = null;
    // public accountStatus?: string | null = null;
    // public accountStartDate?: Date | string | null = null;
    // public accountEndDate?: Date | string | null = null;
    // public licenseType?: string | null = null;
    // public licenseStartDate?: Date | string | null = null;
    // public licenseEndDate?: Date | string | null = null;
    // public licenseStatus?: string | null = null;

    // public paymentMethod?: string | null = null;
    // public paymentStatus?: string | null = null;

    // public contractualContactName?: string | null = null;
    // public contractualContactEmail?: string | null = null;
    // public contractualContactPhone?: string | null = null;
    // public contractualContactMobilePhone?: string | null = null;

    public roleId?: string | null = null;

    // public selectedSiteIds: Array<string> = [];

    public selectedSiteRoleIds: Array<{roleId: string; siteId: string}> = [];

    constructor(data?: Partial<CreateUserViewModel>) {
        Object.assign(this, data);
    }
}
