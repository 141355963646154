import {ServiceClass} from '@/decorators';
import {BaseApi} from './base.api';
import {WorkPermitModel} from '../models';

@ServiceClass()
export class WorkPermitApi extends BaseApi {
    public async getWorkPermits(filter: any = {}) {
        return this.GET_RequestAsync<Array<WorkPermitModel>>(`${this.ApiUrl}/work-permits?filter=${encodeURIComponent(JSON.stringify(filter))}`);
    }

    public async getWorkPermitById(id: string) {
        return this.GET_RequestAsync<WorkPermitModel>(`${this.ApiUrl}/work-permits/${id}`);
    }

    public async downloadFile(id: string) {
        return this.downloadRequest<any>(`${this.ApiUrl}/work-permits/${id}/download-file`);
    }
}
