import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {KeyValuePair} from '@/globals';
import {LocationModel, SiteModel} from '@/sdk';
import {SitesApi} from '@/sdk/api-services';
import {LocationApi} from '@/sdk/api-services/location.api';
import {Component} from 'vue-property-decorator';
import {SiteLocationService} from '../services/site-location.service';

@Component({
    name: 'SaveLocationDialogComponent',
})
export default class SaveLocationDialogComponent extends VueWrapper {
    public siteLocationsSrv: SiteLocationService = new SiteLocationService();

    public mounted() {
        //
    }

    public get title() {
        if (this.siteLocationsSrv.addAt === 'eqp') {
            return this.siteLocationsSrv.location.id ? 'Edit Equipment' : 'Add Equipment';
        } else if (this.siteLocationsSrv.addAt === 'dist') {
            return this.siteLocationsSrv.location.id ? 'Edit Distribution Panel' : 'Add Distribution Panel';
        } else if (this.siteLocationsSrv.addAt === 'sub') {
            return this.siteLocationsSrv.location.id ? 'Edit Sub Area' : 'Add Sub Area';
        }

        return this.siteLocationsSrv.location.id ? 'Edit Area' : 'Add Area';
    }

    public saveLocation() {
        if (this.siteLocationsSrv.site?.id) {
            if (!this.siteLocationsSrv.location.id) {
                this.siteLocationsSrv.location.siteId = this.siteLocationsSrv.site.id;
                if (this.siteLocationsSrv.parentLocation?.id) {
                    this.siteLocationsSrv.location.locationId = this.siteLocationsSrv.parentLocation.id;
                }

                this.LoaderSrv.showFullScreenLoader();
                new SitesApi()
                    .CreateSiteLocation(this.siteLocationsSrv.site.id, this.siteLocationsSrv.location)
                    .subscribe(
                        res => {
                            if (res.Data) {
                                this.AlertSrv.show('success', 'Location has been saved successfully');
                                this.$emit('success');
                                this.siteLocationsSrv.getSiteLocations();
                                this.siteLocationsSrv.updateLocationToCanvasDiagram();
                                this.siteLocationsSrv.closeSiteLocationDialog();
                            }
                        },
                        err => {
                            this.AlertSrv.show('error', 'Unable to save location. Please try agian later!');
                        }
                    )
                    .add(() => {
                        this.LoaderSrv.hideFullScreenLoader();
                    });
            } else {
                this.LoaderSrv.showFullScreenLoader();
                console.log('this.siteLocationsSrv.location.id 1111', this.siteLocationsSrv.location.id);
                const locationIdToEdit = this.siteLocationsSrv.location.id;
                new LocationApi()
                    .UpdateLocation(this.siteLocationsSrv.location.id, this.siteLocationsSrv.location)
                    .subscribe(
                        res => {
                            if (res.Status) {
                                const site = this.siteLocationsSrv.site;
                                const canvasJSON = site && site.drawingCanvas1 ? JSON.parse(site.drawingCanvas1) : null;
                                if (canvasJSON && canvasJSON.nodeDataArray) {
                                    const updatedCanvasJSON = canvasJSON.nodeDataArray.map((node: any) => {
                                        console.log('this.siteLocationsSrv.location.id', locationIdToEdit);
                                        console.log('node.key', node.key);
                                        if (node.key === locationIdToEdit) {
                                            node.text = this.siteLocationsSrv.location.title;
                                            return node;
                                        } else {
                                            return node;
                                        }
                                    });
                                    console.log('updatedCanvasJSON', updatedCanvasJSON);
                                    if (updatedCanvasJSON) {
                                        canvasJSON.nodeDataArray = updatedCanvasJSON;
                                        console.log('canvasJSON', canvasJSON);
                                        new SitesApi()
                                            .UpdateSite(site!.id!, new SiteModel({...site, drawingCanvas1: JSON.stringify(canvasJSON)}))
                                            .subscribe(res => {
                                                if (res.Status) {
                                                    console.log('Site Updated');
                                                }
                                            });
                                    }
                                }
                                this.AlertSrv.show('success', 'Location has been updated successfully');
                                this.$emit('success');
                                this.siteLocationsSrv.getSiteLocations();
                                this.siteLocationsSrv.closeSiteLocationDialog();
                                window.location.reload();
                            }
                        },
                        err => {
                            this.AlertSrv.show('error', 'Unable to update location. Please try agian later!');
                        }
                    )
                    .add(() => {
                        this.LoaderSrv.hideFullScreenLoader();
                    });
            }
        }
    }
}
