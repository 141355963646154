import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {AreaSections, KeyValuePair} from '@/globals';
import {AdminDataFieldService} from '@/views/shared/services';
import {Component} from 'vue-property-decorator';

@Component
export default class AreaFieldsDbComponent extends VueWrapper {
    public areaSections: Array<KeyValuePair> = [
        {
            key: AreaSections.GeneralInformation,
            value: 'General Information',
            active: false,
        },
        {
            key: AreaSections.AreaClassification,
            value: 'Area Classification',
            active: false,
        },
    ];

    public adminDataFieldsSrv: AdminDataFieldService = new AdminDataFieldService();

    public get getFieldOptions() {
        return this.adminDataFieldsSrv.selectedField ? this.adminDataFieldsSrv.selectedFieldOptions[this.adminDataFieldsSrv.selectedField.id!] : [];
    }

    public getAreaFields(key: string) {
        return this.adminDataFieldsSrv.areaFields.filter(x => x.section === key && ['select', 'multi-select'].includes(x.type!));
    }
}
