import {AppVersion} from '@/app.config';
import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {KeyValuePair, ShowableRoute, UserMembershipStatus} from '@/globals';
import {Component} from 'vue-property-decorator';

@Component
export default class AdminDrawerComponent extends VueWrapper {
    public MainLinks: Array<ShowableRoute> = [];
    public plans = false;
    public mini = '';

    public certificationSchemes: Array<KeyValuePair> = [
        {
            key: 'IECEx',
            value: 'IECEx',
        },
        {
            key: 'ATEX',
            value: 'ATEX',
        },
        {
            key: 'KCs',
            value: 'KCs',
        },
        {
            key: 'North America',
            value: 'North America',
        },
    ];

    public selectedScheme: string | null = 'IECEx';

    public window = window;

    // public userSrv: UsersService = new UsersService();
    // public user: UsersModel = new UsersModel();

    public created() {
        // this.userSrv.User$.subscribe(user => {
        //     this.user = user;
        // });
        this.MainLinks = [
            {
                Title: 'Fields DB',
                Name: 'fields-db',
                Icon: 'mdi-database-settings',
                Chip: '',
                Color: '',
            },
            {
                Title: 'Gas DB',
                Name: 'gas-db',
                Icon: 'mdi-database-settings',
                Chip: '',
                Color: '',
            },
            {
                Title: 'Dust DB',
                Name: 'dust-db',
                Icon: 'mdi-database-settings',
                Chip: '',
                Color: '',
            },
            {
                Title: 'Accounts',
                Name: 'account-setup',
                Icon: 'mdi-account-settings',
                Chip: '',
                Color: '',
                inactive: false,
            },
            {
                Title: 'Inspection Forms DB',
                Name: 'inspection-forms-db',
                Icon: 'mdi-account-settings',
                Chip: '',
                Color: '',
                inactive: false,
            },
        ];
    }
}
