export class ProjectModel {
    public id?: string | null = null;
    public title: string | null = null;
    public startDate?: string | null = null;
    public endDate?: string | null = null;

    [key: string]: any;

    constructor(data?: Partial<ProjectModel>) {
        Object.assign(this, data);
    }
}
