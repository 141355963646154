import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {AnyObject, KeyValuePair} from '@/globals';
import {Component, Prop, Watch} from 'vue-property-decorator';
import {AdminDataFieldService, SiteLocationService} from '../services';
import {LocationModel, SiteModel, SwitchBoardModel} from '@/sdk';
import {LocationApi, SitesApi} from '@/sdk/api-services';
import {WiringCableModel} from '@/sdk/models/location/wiring-cables.model';

@Component({
    name: 'DistributionPanelFormComponent',
    components: {},
})
export default class DistributionPanelFormComponent extends VueWrapper {
    @Prop({
        required: false,
    })
    readOnlyFlag!: Boolean;

    @Prop({
        required: true,
    })
    locationPath!: Array<any>;

    @Prop({
        required: false,
    })
    diagramViewStyle!: boolean;

    @Prop({
        required: false,
    })
    diagramFullPageConfig!: boolean;

    public siteLocationsSrv: SiteLocationService = new SiteLocationService();

    public adminDataFieldsSrv: AdminDataFieldService = new AdminDataFieldService();

    public parentAreaName: string | null = null;

    public powerBoardType: Array<KeyValuePair> = [
        {
            key: '3P3W',
            value: '3P3W',
        },
        {
            key: '3P4W',
            value: '3P4W',
        },
        {
            key: '1P2W',
            value: '1P2W',
        },
    ];

    public showNewBoard: boolean | false = false;

    public newBoard: SwitchBoardModel = new SwitchBoardModel();
    public newSwitchBoardObj: SwitchBoardModel = new SwitchBoardModel();
    public newWiringCableObj: WiringCableModel = new WiringCableModel();
    public showSearchResults: boolean | undefined;

    public addSwitchBoard() {
        this.showNewBoard = !this.showNewBoard;
    }

    public async saveSwitchBoard() {
        console.log('Save Board', this.newBoard);

        if (!this.newBoard.id) {
            this.newBoard.locationId = this.siteLocationsSrv.selectedLcoation?.id;

            this.LoaderSrv.showFullScreenLoader();
            new LocationApi()
                .CreateLocationSwitchBoard(this.newBoard)
                .subscribe(
                    async res => {
                        // console.log('res.Data', res.Data);
                        this.AlertSrv.show('success', 'Switch Board has been saved successfully!');
                        this.showNewBoard = false;
                        await this.updateSyncLocation(this.newBoard);
                        await this.syncToDiagramCanvas(this.newBoard);
                        this.newBoard = new SwitchBoardModel();
                        this.locationItemClick(this.siteLocationsSrv.selectedLcoation!);
                    },
                    err => {
                        console.log(this.siteLocationsSrv.selectedLcoation?.id);
                        this.AlertSrv.show('error', 'Unable to save switch board. Please try again later!');
                    }
                )
                .add(() => {
                    this.LoaderSrv.hideFullScreenLoader();
                });
        }
    }

    public async syncToDiagramCanvas(board: any) {
        console.log('syncToDiagramCanvas => board =>', board);
        this.siteLocationsSrv.getSiteLocations();
        const siteLocations = this.siteLocationsSrv.siteLocations;
        const site = this.siteLocationsSrv.site;
        if (board.connectWith) {
            const connectedLocation = siteLocations.find(location => location.id === board.connectWith);
            console.log('syncToDiagramCanvas => connectedLocation =>', connectedLocation);
            const defaultSavedModelContent = {
                class: 'GraphLinksModel',
                linkFromPortIdProperty: 'fromPort',
                linkToPortIdProperty: 'toPort',
                nodeDataArray: [
                    {
                        key: -1,
                        category: 'Conditional',
                        loc: '700 150',
                        text: 'Start',
                        equipment: 'equip.png',
                        dp: 'dp.png',
                        earthWire: 'earthWire.png',
                        arrow: 'arrow.png',
                        hand: 'hand.png',
                        undo: 'undo.png',
                        redo: 'redo.png',
                        zoomIn: 'zoomIn.png',
                        zoomOut: 'zoomOut.png',
                    },
                    {
                        key: -99,
                        category: 'ConditionalPower',
                        text: 'Power Source',
                        loc: '100 150',
                        icon: 'power.png',
                        color: 'moccasin',
                        move: true,
                    },
                ],
                linkDataArray: [],
            };
            const dataToCanvasJsonString = site && site.drawingCanvas1 ? site!.drawingCanvas1! : JSON.stringify(defaultSavedModelContent);
            const siteCanvasJson = JSON.parse(dataToCanvasJsonString);
            if (
                (connectedLocation && !connectedLocation?.switchBoards) ||
                (connectedLocation && connectedLocation?.switchBoards && connectedLocation?.switchBoards.length === 0)
            ) {
                const linkFound = siteCanvasJson.linkDataArray.find((link: any) => link.from === board.locationId && link.to === connectedLocation.id);
                if (!linkFound) {
                    console.log('syncToDiagramCanvas => siteCanvasJson =>', siteCanvasJson);
                    const newConnectionLink = {
                        from: board.locationId,
                        to: connectedLocation.id,
                        fromPort: 'R',
                        toPort: 'L',
                    };
                    siteCanvasJson.linkDataArray.push(newConnectionLink);
                    console.log('syncToDiagramCanvas => newConnectionLink =>', newConnectionLink);
                    console.log('syncToDiagramCanvas => siteCanvasJson =>', siteCanvasJson);

                    new SitesApi().UpdateSite(site!.id!, new SiteModel({...site, drawingCanvas1: JSON.stringify(siteCanvasJson)})).subscribe(
                        res => {
                            console.log('Site has been saved successfully!', res);
                            console.log('Site has been saved successfully!', res.Data);
                            this.siteLocationsSrv.site!.drawingCanvas11 = JSON.stringify(siteCanvasJson);
                            // this.AlertSrv.show('success', 'Site has been saved successfully!');
                        },
                        err => {
                            // this.AlertSrv.show('error', 'Unable to save site. Please try again later.');
                        }
                    );
                    window.location.reload();
                }
            } else if (
                (connectedLocation && !connectedLocation?.wiringCables) ||
                (connectedLocation && connectedLocation?.wiringCables && connectedLocation?.wiringCables.length === 0)
            ) {
                const linkFound = siteCanvasJson.linkDataArray.find((link: any) => link.from === board.locationId && link.to === connectedLocation.id);
                if (!linkFound) {
                    console.log('syncToDiagramCanvas => siteCanvasJson =>', siteCanvasJson);
                    const newConnectionLink = {
                        from: board.locationId,
                        to: connectedLocation.id,
                        fromPort: 'R',
                        toPort: 'L',
                    };
                    siteCanvasJson.linkDataArray.push(newConnectionLink);
                    console.log('syncToDiagramCanvas => newConnectionLink =>', newConnectionLink);
                    console.log('syncToDiagramCanvas => siteCanvasJson =>', siteCanvasJson);

                    new SitesApi().UpdateSite(site!.id!, new SiteModel({...site, drawingCanvas1: JSON.stringify(siteCanvasJson)})).subscribe(
                        res => {
                            console.log('Site has been saved successfully!', res);
                            console.log('Site has been saved successfully!', res.Data);
                            this.siteLocationsSrv.site!.drawingCanvas11 = JSON.stringify(siteCanvasJson);
                            // this.AlertSrv.show('success', 'Site has been saved successfully!');
                        },
                        err => {
                            // this.AlertSrv.show('error', 'Unable to save site. Please try again later.');
                        }
                    );
                    window.location.reload();
                }
            }
        }
    }

    public getLocationToUpdateSync(entryID: any) {
        const siteLocations = this.siteLocationsSrv.siteLocations;
        if (siteLocations) {
            // console.log('this.siteLocationsSrv.selectedLcoation', siteLocations);
            // console.log('entryID', entryID);
            // console.log(
            //     'siteLocations.find',
            //     siteLocations.find(site => site.type === 'equipment' && site.id === entryID)
            // );
            return siteLocations.find(site => site.id === entryID);
        }
        return null;
    }

    public async updateSyncLocation(cable: any) {
        if (cable.connectWith) {
            const connectedSite = this.getLocationToUpdateSync(cable.connectWith);
            console.log('connectedSite', connectedSite);
            if (connectedSite && connectedSite.id) {
                await new LocationApi()
                    .getLocationById(connectedSite.id, {
                        include: [
                            {
                                relation: 'wiringCables',
                                order: ['sequence'],
                            },
                            {
                                relation: 'switchBoards',
                            },
                        ],
                    })
                    .subscribe(
                        res => {
                            if (res.Data) {
                                // this.siteLocationsSrv.selectedLcoation = new LocationModel(res.Data);
                                console.log('res.Data', res.Data);
                                console.log('res.Data', this.siteLocationsSrv.selectedLcoation);

                                const responseData = res.Data;
                                if (responseData && responseData.wiringCables) {
                                    const wiringCableFound = responseData.wiringCables.find(
                                        cableFnd => cableFnd.connectWith === this.siteLocationsSrv!.selectedLcoation!.id
                                    );
                                    // const newObjToUpdate = {};
                                    console.log('wiringCableFound', wiringCableFound);

                                    if (wiringCableFound) {
                                        if (cable.connectDirection) {
                                            if (cable.connectDirection === 'IN') {
                                                wiringCableFound.connectDirection = 'OUT';
                                            } else if (cable.connectDirection === 'OUT') {
                                                wiringCableFound.connectDirection = 'IN';
                                            } else if (cable.connectDirection === 'N/A') {
                                                wiringCableFound.connectDirection = 'N/A';
                                            }
                                        } else {
                                            wiringCableFound.connectDirection = 'N/A';
                                        }
                                        wiringCableFound.connectWith = this.siteLocationsSrv?.selectedLcoation?.id;
                                        console.log('wiringCableFound', wiringCableFound);
                                        new LocationApi()
                                            .UpdateWiringCable(wiringCableFound)
                                            .subscribe(
                                                res => {
                                                    console.log('res.Data 2', res.Data);
                                                    // this.AlertSrv.show('success', 'Wiring Entry has been updated successfully!');
                                                    // this.setActive(cable.id);
                                                    // this.locationItemClick(this.siteLocationsSrv.selectedLcoation!);
                                                },
                                                err => {
                                                    console.log(this.siteLocationsSrv.selectedLcoation?.id);
                                                    this.AlertSrv.show('error', 'Unable to auto update Wiring Entry for Connection. Please try again later!');
                                                }
                                            )
                                            .add(() => {
                                                this.LoaderSrv.hideFullScreenLoader();
                                            });
                                        // const newObjToUpdate = {
                                        //     connectWith: wiringCableFound.connectWith,
                                        //     connectDirection: cable.connectDirection === 'IN' ? 'OUT' : 'IN',
                                        // };
                                    } else {
                                        console.log('NOT FOUND, CREATE NEW');
                                        // console.log('this.newWiringCableObj.connectWith', this.newWiringCableObj);
                                        // console.log('this.newWiringCableObj.connectWith', this.newSeq);
                                        this.newWiringCableObj.locationId = responseData.id;
                                        this.newWiringCableObj.sequence = responseData.wiringCables!.length + 1;

                                        if (cable.connectDirection) {
                                            if (cable.connectDirection === 'IN') {
                                                this.newWiringCableObj.connectDirection = 'OUT';
                                            } else if (cable.connectDirection === 'OUT') {
                                                this.newWiringCableObj.connectDirection = 'IN';
                                            } else if (cable.connectDirection === 'N/A') {
                                                this.newWiringCableObj.connectDirection = 'N/A';
                                            }
                                        } else {
                                            this.newWiringCableObj.connectDirection = 'N/A';
                                        }
                                        this.newWiringCableObj.connectWith = this.siteLocationsSrv?.selectedLcoation?.id;

                                        console.log('CreateLocationWiringCable newWiringCableObj', this.newWiringCableObj);

                                        new LocationApi()
                                            .CreateLocationWiringCable(this.newWiringCableObj)
                                            .subscribe(
                                                res => {
                                                    console.log('CreateLocationWiringCable resData', res.Data);
                                                },
                                                err => {
                                                    console.log(this.siteLocationsSrv.selectedLcoation?.id);
                                                    this.AlertSrv.show('error', 'Unable to save Wiring Entry. Please try again later!');
                                                }
                                            )
                                            .add(() => {
                                                this.newWiringCableObj = new WiringCableModel();
                                                this.LoaderSrv.hideFullScreenLoader();
                                                // this.$forceUpdate();
                                            });
                                    }
                                } else if (responseData && responseData.switchBoards) {
                                    const switchBoardsFound = responseData.switchBoards.find(
                                        cableFnd => cableFnd.connectWith === this.siteLocationsSrv!.selectedLcoation!.id
                                    );
                                    // const newObjToUpdate = {};
                                    console.log('wiringCableFound', switchBoardsFound);

                                    if (switchBoardsFound) {
                                        if (cable.connectDirection) {
                                            if (cable.connectDirection === 'IN') {
                                                switchBoardsFound.connectDirection = 'OUT';
                                            } else if (cable.connectDirection === 'OUT') {
                                                switchBoardsFound.connectDirection = 'IN';
                                            } else if (cable.connectDirection === 'N/A') {
                                                switchBoardsFound.connectDirection = 'N/A';
                                            }
                                        } else {
                                            switchBoardsFound.connectDirection = 'N/A';
                                        }
                                        switchBoardsFound.connectWith = this.siteLocationsSrv?.selectedLcoation?.id;
                                        console.log('switchBoardsFound', switchBoardsFound);
                                        new LocationApi()
                                            .UpdateSwitchBoard(switchBoardsFound)
                                            .subscribe(
                                                res => {
                                                    console.log('res.Data 2', res.Data);
                                                    // this.AlertSrv.show('success', 'Wiring Entry has been updated successfully!');
                                                    // this.setActive(cable.id);
                                                    // this.locationItemClick(this.siteLocationsSrv.selectedLcoation!);
                                                },
                                                err => {
                                                    console.log(this.siteLocationsSrv.selectedLcoation?.id);
                                                    this.AlertSrv.show(
                                                        'error',
                                                        'Unable to auto update Switch Board Entry for Connection. Please try again later!'
                                                    );
                                                }
                                            )
                                            .add(() => {
                                                this.LoaderSrv.hideFullScreenLoader();
                                            });
                                        // const newObjToUpdate = {
                                        //     connectWith: wiringCableFound.connectWith,
                                        //     connectDirection: cable.connectDirection === 'IN' ? 'OUT' : 'IN',
                                        // };
                                    } else {
                                        console.log('NOT FOUND, CREATE NEW newSwitchBoardObj');
                                        // console.log('this.newSwitchBoardObj.connectWith', this.newSwitchBoardObj);
                                        // console.log('this.newSwitchBoardObj.connectWith', this.newSeq);
                                        this.newSwitchBoardObj.locationId = responseData.id;
                                        // this.newSwitchBoardObj.sequence = responseData.wiringCables!.length + 1;

                                        if (cable.connectDirection) {
                                            if (cable.connectDirection === 'IN') {
                                                this.newSwitchBoardObj.connectDirection = 'OUT';
                                            } else if (cable.connectDirection === 'OUT') {
                                                this.newSwitchBoardObj.connectDirection = 'IN';
                                            } else if (cable.connectDirection === 'N/A') {
                                                this.newSwitchBoardObj.connectDirection = 'N/A';
                                            }
                                        } else {
                                            this.newSwitchBoardObj.connectDirection = 'N/A';
                                        }
                                        this.newSwitchBoardObj.connectWith = this.siteLocationsSrv?.selectedLcoation?.id;

                                        console.log('CreateLocationSwitchBoard newSwitchBoardObj', this.newSwitchBoardObj);

                                        new LocationApi()
                                            .CreateLocationSwitchBoard(this.newSwitchBoardObj)
                                            .subscribe(
                                                res => {
                                                    console.log('CreateLocationSwitchBoard resData', res.Data);
                                                },
                                                err => {
                                                    console.log(this.siteLocationsSrv.selectedLcoation?.id);
                                                    this.AlertSrv.show('error', 'Unable to save Wiring Entry. Please try again later!');
                                                }
                                            )
                                            .add(() => {
                                                this.newSwitchBoardObj = new SwitchBoardModel();
                                                this.LoaderSrv.hideFullScreenLoader();
                                                // this.$forceUpdate();
                                            });
                                    }
                                } else {
                                    if (connectedSite.type === 'equipment') {
                                        console.log('NOT FOUND, CREATE NEW equipment Wiring Core');
                                        // console.log('this.newWiringCableObj.connectWith', this.newWiringCableObj);
                                        // console.log('this.newWiringCableObj.connectWith', this.newSeq);
                                        this.newWiringCableObj.locationId = responseData.id;
                                        this.newWiringCableObj.sequence = 1;

                                        if (cable.connectDirection) {
                                            if (cable.connectDirection === 'IN') {
                                                this.newWiringCableObj.connectDirection = 'OUT';
                                            } else if (cable.connectDirection === 'OUT') {
                                                this.newWiringCableObj.connectDirection = 'IN';
                                            } else if (cable.connectDirection === 'N/A') {
                                                this.newWiringCableObj.connectDirection = 'N/A';
                                            }
                                        } else {
                                            this.newWiringCableObj.connectDirection = 'N/A';
                                        }
                                        this.newWiringCableObj.connectWith = this.siteLocationsSrv?.selectedLcoation?.id;

                                        console.log('CreateLocationWiringCable newWiringCableObj', this.newWiringCableObj);

                                        new LocationApi()
                                            .CreateLocationWiringCable(this.newWiringCableObj)
                                            .subscribe(
                                                res => {
                                                    console.log('CreateLocationWiringCable resData', res.Data);
                                                },
                                                err => {
                                                    console.log(this.siteLocationsSrv.selectedLcoation?.id);
                                                    this.AlertSrv.show('error', 'Unable to save Wiring Entry. Please try again later!');
                                                }
                                            )
                                            .add(() => {
                                                this.newWiringCableObj = new WiringCableModel();
                                                this.LoaderSrv.hideFullScreenLoader();
                                                // this.$forceUpdate();
                                            });
                                    } else if (connectedSite.type === 'distribution') {
                                        console.log('NOT FOUND, CREATE NEW distribution Switch Board');
                                        // console.log('this.newWiringCableObj.connectWith', this.newWiringCableObj);
                                        // console.log('this.newWiringCableObj.connectWith', this.newSeq);
                                        this.newSwitchBoardObj.locationId = responseData.id;

                                        if (cable.connectDirection) {
                                            if (cable.connectDirection === 'IN') {
                                                this.newSwitchBoardObj.connectDirection = 'OUT';
                                            } else if (cable.connectDirection === 'OUT') {
                                                this.newSwitchBoardObj.connectDirection = 'IN';
                                            } else if (cable.connectDirection === 'N/A') {
                                                this.newSwitchBoardObj.connectDirection = 'N/A';
                                            }
                                        } else {
                                            this.newSwitchBoardObj.connectDirection = 'N/A';
                                        }
                                        this.newSwitchBoardObj.connectWith = this.siteLocationsSrv?.selectedLcoation?.id;

                                        console.log('CreateLocationSwitchBoard newSwitchBoardObj', this.newSwitchBoardObj);

                                        new LocationApi()
                                            .CreateLocationSwitchBoard(this.newSwitchBoardObj)
                                            .subscribe(
                                                res => {
                                                    console.log('CreateLocationSwitchBoard resData', res.Data);
                                                },
                                                err => {
                                                    console.log(this.siteLocationsSrv.selectedLcoation?.id);
                                                    this.AlertSrv.show('error', 'Unable to save Wiring Entry. Please try again later!');
                                                }
                                            )
                                            .add(() => {
                                                this.newSwitchBoardObj = new SwitchBoardModel();
                                                this.LoaderSrv.hideFullScreenLoader();
                                                // this.$forceUpdate();
                                            });
                                    }
                                }
                                //
                            } else {
                                // this.siteLocationsSrv.selectedLcoation = new LocationModel({locationId: location.id});
                                this.siteLocationsSrv.selectedLcoation = null;
                            }
                        },
                        err => {
                            this.AlertSrv.show('warning', 'Unable to load data.');
                        }
                    )
                    .add(() => {
                        this.LoaderSrv.hideFullScreenLoader();
                    });
            }
        }
    }

    public deleteBoard(board: any) {
        console.log('Delete Board', board);

        if (board.id) {
            this.LoaderSrv.showFullScreenLoader();
            new LocationApi()
                .deleteSwitchBoards(board.id)
                .subscribe(
                    res => {
                        // console.log('res.Data', res.Data);
                        this.AlertSrv.show('success', 'Switch Board has been deleted successfully!');
                        this.locationItemClick(this.siteLocationsSrv.selectedLcoation!);
                    },
                    err => {
                        console.log(this.siteLocationsSrv.selectedLcoation?.id);
                        this.AlertSrv.show('error', 'Unable to delete switch board. Please try again later!');
                    }
                )
                .add(() => {
                    this.LoaderSrv.hideFullScreenLoader();
                });
        }
    }

    public async updateBoard(board: any) {
        console.log('updateBoard Board', board);
        delete board.finalMidArr;
        await this.updateSyncLocation(board);
        if (board.id) {
            this.LoaderSrv.showFullScreenLoader();
            new LocationApi()
                .UpdateSwitchBoard(board)
                .subscribe(
                    res => {
                        // console.log('res.Data', res.Data);
                        this.AlertSrv.show('success', 'Switch Board has been updated successfully!');
                        this.locationItemClick(this.siteLocationsSrv.selectedLcoation!);
                    },
                    err => {
                        console.log(this.siteLocationsSrv.selectedLcoation?.id);
                        this.AlertSrv.show('error', 'Unable to update switch board. Please try again later!');
                    }
                )
                .add(() => {
                    this.LoaderSrv.hideFullScreenLoader();
                });
        }
    }

    public locationItemClick(location: LocationModel, fromSearch?: boolean) {
        this.locationPath = [];
        let locationId = location.id;
        let foundLocation: LocationModel | undefined = location;

        let locationId1 = location.id;
        let foundLocation1: LocationModel | undefined | null = null;

        this.showSearchResults = false;

        while (locationId1 != null) {
            if (locationId1) {
                foundLocation1 = this.siteLocationsSrv.siteLocations.find(x => x.id === locationId1);
            }
            if (foundLocation1) {
                foundLocation1.expanded = true;
            }
            locationId1 = foundLocation1?.locationId;
        }

        while (locationId != null) {
            if (foundLocation) {
                this.locationPath.push({id: foundLocation.id!, title: foundLocation.title!, type: 'location'});
            }
            locationId = foundLocation?.locationId;
            if (locationId) {
                foundLocation = this.siteLocationsSrv.siteLocations.find(x => x.id === locationId);
            }
        }
        if (this.siteLocationsSrv.site) {
            this.locationPath.push({id: this.siteLocationsSrv.site.id!, title: this.siteLocationsSrv.site.siteName!, type: 'site'});
        }
        this.locationPath = this.locationPath.reverse();

        if (location.type === 'equipment' || location.type === 'distribution') {
            console.log('this.siteLocationsSrv.selectedLcoation', location.id!);
            this.siteLocationsSrv.getSiteLocation(location.id!);
        } else {
            this.LoaderSrv.showFullScreenLoader();
            new LocationApi()
                .getLocationById(location.id!, {
                    include: [
                        {
                            relation: 'locationDetail',
                        },
                        {
                            relation: 'documentations',
                        },
                        {
                            relation: 'switchBoards',
                        },
                    ],
                })
                .subscribe(
                    res => {
                        if (res.Data) {
                            this.siteLocationsSrv.selectedLcoation = new LocationModel(res.Data);

                            // if (
                            //     this.siteLocationsSrv.selectedLcoation &&
                            //     (!this.siteLocationsSrv.selectedLcoation?.approvalType ||
                            //         typeof this.siteLocationsSrv.selectedLcoation?.approvalType === 'string')
                            // ) {
                            //     this.siteLocationsSrv.selectedLcoation.approvalType = [];
                            // }

                            // if (
                            //     this.siteLocationsSrv.selectedLcoation &&
                            //     (!this.siteLocationsSrv.selectedLcoation?.environmentalCondition ||
                            //         typeof this.siteLocationsSrv.selectedLcoation?.environmentalCondition === 'string')
                            // ) {
                            //     this.siteLocationsSrv.selectedLcoation.environmentalCondition = [];
                            // }
                        } else {
                            // this.siteLocationsSrv.selectedLcoation = new LocationModel({locationId: location.id});
                            this.siteLocationsSrv.selectedLcoation = null;
                        }
                    },
                    err => {
                        this.AlertSrv.show('warning', 'Unable to load data.');
                    }
                )
                .add(() => {
                    this.LoaderSrv.hideFullScreenLoader();
                });
        }
    }

    // public readOnlyFlag: boolean | false = false;

    public validateNumber(evt: any) {
        evt = evt ? evt : window.event;
        const expect = evt.target.value.toString() + evt.key.toString();

        if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
            evt.preventDefault();
        } else {
            return true;
        }
    }

    public selectConnectDirection: Array<KeyValuePair> = [
        {
            key: 'IN',
            value: 'IN',
        },
        {
            key: 'OUT',
            value: 'OUT',
        },
        {
            key: 'N/A',
            value: 'N/A',
        },
    ];

    public finalList: Array<any> = [];
    public finalListNew: Array<any> = [];
    public loadingConnectLists: Boolean | false = false;
    public isLoadedMidList: Boolean | false = false;

    public allEquipmentDistributionLocations: Array<any> = [];
    public allEquipmentCables: Array<WiringCableModel> = [];
    public allDistributionSwitchBoards: any[] = [];

    public get getEquipmentsList(): Array<any> {
        const siteLocations = this.siteLocationsSrv.siteLocations;
        if (siteLocations) {
            // console.log('this.siteLocationsSrv.selectedLcoation', siteLocations);
            this.allEquipmentDistributionLocations = Object.assign(
                [],
                siteLocations.filter(site => site.type === 'equipment' || site.type === 'distribution')
            );
            // console.log('allEquipmentDistributionLocations', this.allEquipmentDistributionLocations);

            return this.allEquipmentDistributionLocations;
        }
        return [];
    }

    public allowDrop(ev: any) {
        ev.preventDefault();
    }

    public dropTargetElementIndex: any | null = null;

    public onDragEnter(index: any) {
        console.log('onDragEnter', index);
        this.dropTargetElementIndex = index;
    }

    public async drop(event: any) {
        const locationId = event.dataTransfer.getData('id');
        console.log(' this.dropTargetElementIndex', this.dropTargetElementIndex);

        const locationSite = this.siteLocationsSrv.siteLocations.find(d => d.id === locationId);
        console.log('locationSite', locationSite);
        if (locationSite?.type === 'equipment' || locationSite?.type === 'distribution') {
            console.log('locationId2222', locationId);
            this.siteLocationsSrv.selectedLcoation!.switchBoards![this.dropTargetElementIndex].connectWith = locationId;
            this.makeConnectWithFields(this.siteLocationsSrv.selectedLcoation!.switchBoards![this.dropTargetElementIndex], this.dropTargetElementIndex);
        }
    }

    public async dropNew(event: any) {
        const locationId = event.dataTransfer.getData('id');

        const locationSite = this.siteLocationsSrv.siteLocations.find(d => d.id === locationId);
        if (locationSite?.type === 'equipment' || locationSite?.type === 'distribution') {
            this.newBoard.connectWith = locationId;
            this.makeConnectWithFieldsNew(this.newBoard);
        }
    }

    public watchFinalList(index: any) {
        console.log(
            'this.siteLocationsSrv?.selectedLcoation?.switchBoards![index].finalMidArr',
            this.siteLocationsSrv?.selectedLcoation?.switchBoards![index].finalMidArr
        );
        console.log('this.siteLocationsSrv?.selectedLcoation?.switchBoards![index].finalMidArr', this.siteLocationsSrv?.selectedLcoation);
        return this.finalList[index] || this.siteLocationsSrv?.selectedLcoation?.switchBoards![index].finalMidArr;
    }

    public async makeConnectWithFields(entry: any, index: any = 0) {
        // this.LoaderSrv.showFullScreenLoader();
        this.loadingConnectLists = true;
        // this.finalList[index] = [];
        const tempList: Array<any> = [];
        console.log('entry', entry);
        console.log('index', index);
        console.log('finalList', this.finalList);
        if (entry && entry.connectWith) {
            await new LocationApi()
                .getLocationById(entry.connectWith, {
                    include: [
                        {
                            relation: 'switchBoards',
                        },
                        {
                            relation: 'wiringCables',
                            order: ['sequence'],
                        },
                    ],
                })
                .subscribe(
                    res => {
                        if (res.Data) {
                            // console.log('res.Data', res.Data);
                            if (res.Data.type === 'equipment') {
                                this.allEquipmentCables = res.Data.wiringCables! || [];
                                for (let j = 0; j < this.allEquipmentCables.length; j++) {
                                    const element2 = this.allEquipmentCables[j];
                                    tempList.push({id: element2.id, title: `Wire ${j + 1} (${element2.id})`});
                                    // this.finalList.push({id: element2.id, title: `Core Entry ${j + 1} -> ${element2.name} - (${element2.id})`});
                                    // console.log(`Entry ${j + 1} -> ${element2.name} - (${element2.id})`);
                                }
                            } else if (res.Data.type === 'distribution') {
                                this.allDistributionSwitchBoards = res.Data.switchBoards! || [];
                                for (let j = 0; j < this.allDistributionSwitchBoards.length; j++) {
                                    const element3 = this.allDistributionSwitchBoards[j];
                                    tempList.push({
                                        id: element3.id,
                                        title: `Output ${j + 1} (${element3.id})`,
                                    });
                                    // console.log(`Switch Board ${j + 1} -> ${element3.outpulPhase} - (${element3.id})`);
                                }
                            }
                        }
                    },
                    err => {
                        this.AlertSrv.show('warning', 'ID not found or deleted for Connecting Select Equip / DP .');
                        console.error('warning', 'ID not found.');
                    }
                )
                .add(() => {
                    // this.LoaderSrv.hideFullScreenLoader();
                    // console.log('this.finalList[index]', this.finalList[index]);
                    // console.log('index', index);
                    console.log('tempList', tempList);
                    this.finalList[index] = tempList;
                    this.loadingConnectLists = false;
                });
            console.log('this.finalList', this.finalList[index]);
            this.isLoadedMidList = true;
        }
    }

    public makeConnectWithFieldsNew(entry: any) {
        // this.LoaderSrv.showFullScreenLoader();
        this.loadingConnectLists = true;
        this.finalListNew = [];
        if (entry) {
            new LocationApi()
                .getLocationById(entry, {
                    include: [
                        {
                            relation: 'switchBoards',
                        },
                        {
                            relation: 'wiringCables',
                            order: ['sequence'],
                        },
                    ],
                })
                .subscribe(
                    res => {
                        if (res.Data) {
                            // console.log('res.Data', res.Data);
                            if (res.Data.type === 'equipment') {
                                this.allEquipmentCables = res.Data.wiringCables! || [];
                                for (let j = 0; j < this.allEquipmentCables.length; j++) {
                                    const element2 = this.allEquipmentCables[j];
                                    this.finalListNew.push({id: element2.id, title: `Wire ${j + 1} (${element2.id})`});
                                    // this.finalList.push({id: element2.id, title: `Core Entry ${j + 1} -> ${element2.name} - (${element2.id})`});
                                    // console.log(`Entry ${j + 1} -> ${element2.name} - (${element2.id})`);
                                }
                            } else if (res.Data.type === 'distribution') {
                                this.allDistributionSwitchBoards = res.Data.switchBoards! || [];
                                for (let j = 0; j < this.allDistributionSwitchBoards.length; j++) {
                                    const element3 = this.allDistributionSwitchBoards[j];
                                    this.finalListNew.push({
                                        id: element3.id,
                                        title: `Output ${j + 1} (${element3.id})`,
                                    });
                                }
                            }
                        }
                    },
                    err => {
                        this.AlertSrv.show('warning', 'ID not found or deleted for Connecting Select Equip / DP .');
                        console.error('warning', 'ID not found.');
                    }
                )
                .add(() => {
                    this.loadingConnectLists = false;
                });
        }
    }

    public mounted() {}
}
