import {ServiceClass} from '@/decorators';
import {BaseApi} from './base.api';
import {CertificationSchemeModel} from '../models';

@ServiceClass()
export class CertificationSchemesApi extends BaseApi {
    public async getCertificationSchemesAsync() {
        return await this.GET_RequestAsync<Array<CertificationSchemeModel>>(`${this.ApiUrl}/certification-schemes`);
    }

    public async getCertificationSchemeByIdAsync(id: string) {
        return await this.GET_RequestAsync<CertificationSchemeModel>(`${this.ApiUrl}/certification-schemes/${id}`);
    }
}
