import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {Component} from 'vue-property-decorator';
import {TeamApi} from '@/sdk/api-services/team.api';
import {SiteModel, CreateUserViewModel} from '@/sdk';
import {RoleApi} from '@/sdk/api-services/role.api';
// import SiteSetupAreaBrowserComponent from '../../site-setup-area-browser/site-setup-area-browser.component';
// import SiteSetupHeaderComponent from '../../site-setup-header/site-setup-header.component';
// import SiteSetupButtonBarComponent from '../../site-setup-button-bar/site-setup-button-bar.component';
import ClientAppBarComponent from '@/components/vuetify/client-app-bar/client-app-bar.component';
import SiteSetupButtonBarComponent from '@/views/client/site-setup/site-setup-button-bar/site-setup-button-bar.component';
// import JobSetupMainComponent from '../job-setup-main/job-setup-main.component';

@Component({
    components: {
        ClientAppBarComponent,
        // JobSetupMainComponent,
        SiteSetupButtonBarComponent,
    },
})
export default class InspectionHistoryCompoent extends VueWrapper {
    public team: any = [];
    public name: string = 'add-member-dialog';
    public teamApi: TeamApi = new TeamApi();
    public roleApi: any = new RoleApi();
    public newMember: CreateUserViewModel = new CreateUserViewModel();
    public roles: Array<any> = [];
    public siteId: string = '';

    public getRoles() {
        this.roleApi.getAll().subscribe((res: any) => {
            this.roles = res ?? [];
            // console.log('ROLES:');
            // console.log(this.roles);
            this.roles.sort((roleA, roleB) => (roleA.name > roleB.name ? 1 : -1));
        });
    }
    public created() {
        this.siteId = this.$route.params['siteId'];
    }
    public mounted() {
        this.getRoles();
    }
    public addNewMemberClick() {
        // this.newSite = new SiteModel();
        this.newMember = new CreateUserViewModel();
        this.CoreSrv.OpenModal(this.name);
    }

    public clearForm(id: string) {
        this.ConfirmSrv.open('Cancel Data Entry', 'You will lose all unsaved progress if you click Yes', {ConfirmText: 'Yes', CancelText: 'No'}).then(yes => {
            if (yes) {
                //close modal
                this.CoreSrv.CloseModal(this.name);
            }
            // this.sitesApi.deleteSite(id).subscribe(
            //     res => {
            //         if (res.Status) {
            //             this.AlertSrv.show('success', 'Site has been deleted successfully!');
            //             this.loadAllSites();
            //         }
            //     },
            //     err => {
            //         this.AlertSrv.show('error', 'Unable to delete site. Please try again later.');
            //     }
            // );
        });
    }
}
