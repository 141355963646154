import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {AreaSections, EquipmentSections, KeyValuePair} from '@/globals';
import {ConfirmService, DataFieldModel, FieldOptions} from '@/sdk';
import SaveFieldOptionDialogComponent from '@/views/shared/save-field-option-dialog/save-field-option-dialog.component';
import {AdminDataFieldService} from '@/views/shared/services';
import {Component} from 'vue-property-decorator';
import Papa from 'papaparse';
import ImportFieldsDbCsvDialogComponent from './import-fields-db-csv-dialog/import-fields-db-csv-dialog.component';
import AreaFieldsDbComponent from './area-fields-db/area-fields-db.component';
import EquipmentFieldsDbComponent from './equipment-fields-db/equipment-fields-db.component';

@Component({
    components: {
        SaveFieldOptionDialogComponent,
        ImportFieldsDbCsvDialogComponent,
        AreaFieldsDbComponent,
        EquipmentFieldsDbComponent,
    },
})
export default class FieldsDbComponent extends VueWrapper {
    public $refs!: {
        addFieldOptionDialogRef: SaveFieldOptionDialogComponent;
        importFieldsDialogRef: ImportFieldsDbCsvDialogComponent;
    };

    public fieldsDbTab: any = 0;

    public adminDataFieldsSrv: AdminDataFieldService = new AdminDataFieldService();

    public mounted() {
        this.adminDataFieldsSrv.loadAreaFields();
    }

    public toggleRequire(field: DataFieldModel) {
        if (field.required) {
            this.ConfirmSrv.open('Mark Not Required', 'Are you sure you want to make this field not required?').then(yes => {
                if (yes) {
                    this.LoaderSrv.showFullScreenLoader();
                    this.adminDataFieldsSrv.dataFieldApi
                        .markFieldNotRequired(field.id!)
                        .subscribe(
                            res => {
                                this.AlertSrv.show('success', 'Field has been marked not required.');

                                this.loadFields();
                            },
                            err => {
                                this.AlertSrv.show('error', 'Unalbe to change field status.');
                            }
                        )
                        .add(() => {
                            this.LoaderSrv.hideFullScreenLoader();
                        });
                }
            });
        } else {
            this.ConfirmSrv.open('Mark Required', 'Are you sure you want to make this field required?').then(yes => {
                if (yes) {
                    this.LoaderSrv.showFullScreenLoader();
                    this.adminDataFieldsSrv.dataFieldApi
                        .markFieldRequired(field.id!)
                        .subscribe(
                            res => {
                                this.AlertSrv.show('success', 'Field has been marked required.');

                                this.loadFields();
                            },
                            err => {
                                this.AlertSrv.show('error', 'Unalbe to change field status.');
                            }
                        )
                        .add(() => {
                            this.LoaderSrv.hideFullScreenLoader();
                        });
                }
            });
        }
    }

    public onTabChange() {
        this.adminDataFieldsSrv.selectedField = null;
        // this.adminDataFieldsSrv.selectedFieldOptions = {};

        this.loadFields();
    }

    public loadFields() {
        if (this.fieldsDbTab === 0) {
            this.adminDataFieldsSrv.loadAreaFields();
        } else {
            this.adminDataFieldsSrv.loadEquipmentFields();
        }
    }

    // public get getFieldOptions() {
    //     return this.adminDataFieldsSrv.selectedField ? this.adminDataFieldsSrv.selectedFieldOptions[this.adminDataFieldsSrv.selectedField.id!] : [];
    // }

    // public get fieldsList() {
    //     if (this.fieldsDbTab === 0) {
    //         return this.adminDataFieldsSrv.areaFields;
    //     }
    //     return this.adminDataFieldsSrv.equipmentFields;
    // }

    public onFieldClick(field: DataFieldModel) {
        this.adminDataFieldsSrv.selectedField = field;
        this.adminDataFieldsSrv.getFieldsOptions(field.id!);

        if (field.dependOnFieldId) {
            this.adminDataFieldsSrv.getFieldsOptions(field.dependOnFieldId);
        }
    }

    public editOption(type: string, opt: FieldOptions) {
        this.adminDataFieldsSrv.isDependent = false;

        this.adminDataFieldsSrv.newOption = opt;
        if (opt.dependentOnFieldId) {
            this.adminDataFieldsSrv.isDependent = true;
        }
        this.$refs.addFieldOptionDialogRef.open();
    }

    public addFieldOption(type: string) {
        this.adminDataFieldsSrv.isDependent = false;
        this.adminDataFieldsSrv.newOption = new FieldOptions({
            dataFieldId: this.adminDataFieldsSrv.selectedField?.id,
        });
        this.$refs.addFieldOptionDialogRef.open();
    }

    public deleteOption(type: string, optId: string) {
        this.ConfirmSrv.open('Delete Field Option', 'Are you sure you want to delete this field option?').then(yes => {
            if (yes) {
                this.LoaderSrv.showFullScreenLoader();
                this.adminDataFieldsSrv.dataFieldApi
                    .deleteDataFieldOption(this.adminDataFieldsSrv.selectedField!.id!, optId)
                    .subscribe(
                        res => {
                            if (res.Data) {
                                this.adminDataFieldsSrv.getFieldsOptions(this.adminDataFieldsSrv.selectedField!.id!);
                                this.AlertSrv.show('success', 'Field Option has been deleted successfully!');
                            }
                        },
                        err => {
                            this.AlertSrv.show('error', 'Unable to delete field option.');
                        }
                    )
                    .add(() => {
                        this.LoaderSrv.hideFullScreenLoader();
                    });
            }
        });
    }

    public exportCSV() {
        this.adminDataFieldsSrv.dataFieldApi.getDropdownsWithOptions().subscribe(
            res => {
                if (res.Data?.length) {
                    const csvData = [];

                    for (const f of res.Data) {
                        csvData.push(
                            ...[
                                ['Field Unique ID', f.id],
                                ['Field For', f.for],
                                ['Section', f.section],
                                ['Field Name', f.title],
                                ['Dropdown Options'],
                                f.fieldOptions?.map(x => x.value) ?? [],
                                [],
                            ]
                        );
                    }

                    this.downloadCSV({
                        data: csvData,
                    });
                }
            },
            err => {
                this.AlertSrv.show('error', err.message || 'Unable to load data.');
            }
        );
    }

    public importCsv() {
        this.$refs.importFieldsDialogRef.open();
    }

    public downloadCSV(data: any) {
        const filename = data.filename || 'dropdowns-options-data.csv';
        const columns = data.columns || null;

        const csv = Papa.unparse({data: data.data, fields: columns});
        if (csv == null) return;

        const blob = new Blob([csv]);
        if ((window.navigator as any).msSaveOrOpenBlob) {
            (window.navigator as any).msSaveBlob(blob, data.filename);
        } else {
            const a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = filename;
            document.body.appendChild(a);
            a.click();

            document.body.removeChild(a);
        }
    }
}
