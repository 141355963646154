import { render, staticRenderFns } from "./save-field-option-dialog.component.vue?vue&type=template&id=705e2024&scoped=true"
import script from "./save-field-option-dialog.component.ts?vue&type=script&lang=ts&external"
export * from "./save-field-option-dialog.component.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "705e2024",
  null
  
)

export default component.exports