import {ServiceClass} from '@/decorators';
import {AnyObject} from '@/globals';
import {AlertService, DocumentationModel, LoaderService, LocationModel} from '@/sdk';
import {DocumentationApi} from '@/sdk/api-services';
import {CoreService} from '@/services/core.service';
import {SiteLocationService} from './site-location.service';

@ServiceClass()
export class LocationDcoumentationService {
    public folder: DocumentationModel = new DocumentationModel();
    public files: Array<File> = [];

    public openAddFolderDialog(location: LocationModel, parentDocumentation?: DocumentationModel, conf: AnyObject = {level: 0}) {
        this.folder = new DocumentationModel({
            locationId: location.id!,
            type: 'folder',
            documentationId: parentDocumentation?.id,
        });
        new CoreService().OpenModal('save-documentation-folder');
    }

    public openAddFileDialog(location: LocationModel, parentDocumentation?: DocumentationModel, conf: AnyObject = {level: 0}) {
        this.folder = new DocumentationModel({
            locationId: location.id!,
            type: 'file',
            documentationId: parentDocumentation?.id,
        });
        this.files = [];
        new CoreService().OpenModal('save-documentation-file');
    }

    public SaveDocumantation() {
        new LoaderService().showFullScreenLoader();
        new DocumentationApi()
            .Create(this.folder)
            .subscribe(
                res => {
                    if (res.Status) {
                        new AlertService().show('success', 'Folder has been created successfully!');
                        new CoreService().CloseModal('save-documentation-folder');
                        new SiteLocationService().getSiteLocation(this.folder.locationId!);
                    }
                },
                err => {
                    new AlertService().show('error', 'Unable to create a folder. Pleasse try again later!');
                }
            )
            .add(() => {
                new LoaderService().hideFullScreenLoader();
            });
    }

    public SaveDocumantationFile() {
        new LoaderService().showFullScreenLoader();
        new DocumentationApi()
            .CreateFiles(this.folder.locationId!, this.files, this.folder.documentationId!)
            .subscribe(
                res => {
                    if (res.Status) {
                        new AlertService().show('success', `File(s) has been added successfully!`);
                        new CoreService().CloseModal('save-documentation-file');
                        new SiteLocationService().getSiteLocation(this.folder.locationId!);
                    }
                },
                err => {
                    new AlertService().show('error', 'Unable to save file(s). Pleasse try again later!');
                }
            )
            .add(() => {
                new LoaderService().hideFullScreenLoader();
            });
    }
}
