import {ServiceClass} from '@/decorators';
import {BaseApi} from './base.api';
import {InspepctionFormModel} from '../models';

@ServiceClass()
export class InspectionFormsApi extends BaseApi {
    public async getInspectionFormsAsync() {
        return await this.GET_RequestAsync<Array<InspepctionFormModel>>(`${this.ApiUrl}/inspection-forms`);
    }

    public async getInspectionFormByIdAsync(id: string) {
        return await this.GET_RequestAsync<InspepctionFormModel>(`${this.ApiUrl}/inspection-forms/${id}`);
    }

    public async cloneInspectionForm(data: {inspectionFormId: string; title: string}) {
        return await this.POST_RequestAsync<InspepctionFormModel>(`${this.ApiUrl}/inspection-forms/clone`, data);
    }

    public async inspectionFormSettings(id: string, data: {protectionTechniquesIds: Array<string>; title: string}) {
        return await this.POST_RequestAsync<InspepctionFormModel>(`${this.ApiUrl}/inspection-forms/${id}/settings`, data);
    }
}
