export class InspectionQuestionCommentsModel {
    public id?: string | null = null;
    public inspectionFormQuestionId?: string | null = null;
    public comment: string | null = null;
    public type: string | null = null;

    public constructor(init?: Partial<InspectionQuestionCommentsModel>) {
        Object.assign(this, init);
    }
}
