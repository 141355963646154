import {ServiceClass} from '@/decorators';
import {SIGNS_TYPES, WORK_PERMIT_TYPES} from '@/globals';
import {helpers} from '@/plugins/helpers/helpers';
import {
    AddJobNormalWorkPermit,
    AlertService,
    IndividualProtectionEquipmentViewModel,
    JobNormalWorkPermit,
    JobNormalWpToBeFilledByContractorViewModel,
    JobSubmitedWorkPermitModel,
    LoaderService,
    SignJobNormalWorkPermitViewwModel,
} from '@/sdk';
import {JobSubmitedWorkPermitsApi} from '@/sdk/api-services';
import {JobApi} from '@/sdk/api-services/job.api';
import {map} from 'rxjs/operators';
import {WorkPermitsService} from './work-permits.service';

@ServiceClass()
export class JobSubmittedWorkPermitsService {
    public jobId: string | null = null;

    public jobSubmittedNormalWorkPermits: Array<JobSubmitedWorkPermitModel> = [];
    public jobSubmittedHotWorkPermits: Array<JobSubmitedWorkPermitModel> = [];
    public jobSubmittedConfinedSpaceWorkPermits: Array<JobSubmitedWorkPermitModel> = [];
    public jobSubmittedPowerOutageWorkPermits: Array<JobSubmitedWorkPermitModel> = [];
    public jobSubmittedRadationWorkPermits: Array<JobSubmitedWorkPermitModel> = [];

    public jobSubbmittedWorkPermitsApi: JobSubmitedWorkPermitsApi = new JobSubmitedWorkPermitsApi();

    public workPermitsSrv: WorkPermitsService = new WorkPermitsService();

    public workPermits(type: string) {
        switch (type) {
            case WORK_PERMIT_TYPES.normalWorkPermit:
                return this.jobSubmittedNormalWorkPermits;
            case WORK_PERMIT_TYPES.hotWorkPermit:
                return this.jobSubmittedHotWorkPermits;
            case WORK_PERMIT_TYPES.confinedSpaceWorkPermit:
                return this.jobSubmittedConfinedSpaceWorkPermits;
            case WORK_PERMIT_TYPES.powerOutageWorkPermit:
                return this.jobSubmittedPowerOutageWorkPermits;
            case WORK_PERMIT_TYPES.radiationWorkPermit:
                return this.jobSubmittedRadationWorkPermits;
        }

        return [];
    }

    public async loadWorkPermits(jobId: string, type: WORK_PERMIT_TYPES) {
        return this.jobSubbmittedWorkPermitsApi
            .getSubmitedWorkPermits(jobId, type)
            .then(res => {
                switch (type) {
                    case WORK_PERMIT_TYPES.normalWorkPermit:
                        this.jobSubmittedNormalWorkPermits = res ?? [];
                        break;
                    case WORK_PERMIT_TYPES.hotWorkPermit:
                        this.jobSubmittedHotWorkPermits = res ?? [];
                        break;
                    case WORK_PERMIT_TYPES.confinedSpaceWorkPermit:
                        this.jobSubmittedConfinedSpaceWorkPermits = res ?? [];
                        break;
                    case WORK_PERMIT_TYPES.powerOutageWorkPermit:
                        this.jobSubmittedPowerOutageWorkPermits = res ?? [];
                        break;
                    case WORK_PERMIT_TYPES.radiationWorkPermit:
                        this.jobSubmittedRadationWorkPermits = res ?? [];
                        break;
                }

                return res;
            })
            .catch(err => {
                console.log(err);
                return err;
            });
    }

    public async deleteSubmittedWorkPermit(jobId: string, id: string) {
        return this.jobSubbmittedWorkPermitsApi
            .deleteSubmitedWorkPermit(jobId, id)
            .then(res => {
                return res;
            })
            .catch(err => {
                console.log(err);
                return err;
            });
    }

    public reset() {
        // this.newJobNormalWorkPermit = new AddJobNormalWorkPermit();
    }
}
