import {ServiceClass} from '@/decorators';
import {AnyObject} from '@/globals';
import {helpers} from '@/plugins/helpers/helpers';
import {LocationModel, ProjectModel, SiteModel} from '../models';
import {BaseApi} from './base.api';

@ServiceClass()
export class SiteProjectsApi extends BaseApi {
    public getSiteProjects(siteId: string, filter: AnyObject = {}) {
        return this.GET_RequestAsync<Array<ProjectModel>>(`${this.ApiUrl}/sites/${siteId}/projects?filter=${encodeURIComponent(JSON.stringify(filter))}`);
    }

    public addProject(siteId: string, project: ProjectModel) {
        return this.POST_Request<ProjectModel>(`${this.ApiUrl}/sites/${siteId}/projects`, {
            title: project.title,
            startDate: project.startDate ? new Date(project.startDate).toISOString() : null,
            endDate: project.endDate ? new Date(project.endDate).toISOString() : null,
        });
    }

    public updateProject(projectId: string, project: ProjectModel) {
        return this.PATCH_Request<void>(`${this.ApiUrl}/projects/${projectId}`, {
            title: project.title,
            startDate: project.startDate ? new Date(project.startDate).toISOString() : null,
            endDate: project.endDate ? new Date(project.endDate).toISOString() : null,
        });
    }
}
