import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {SiteModel} from '@/sdk';
import {SitesApi} from '@/sdk/api-services';
import {Component} from 'vue-property-decorator';
import SiteSetupButtonBarComponent from '@/views/client/site-setup/site-setup-button-bar/site-setup-button-bar.component';

@Component({
    components: {
        // ClientDrawerComponent,
        // LoginSignUpComponent
        SiteSetupButtonBarComponent,
    },
})
export default class SitesListComponent extends VueWrapper {
    public name: string = 'add-site-dialog';

    public stepper: number = 1;

    public sitesApi: SitesApi = new SitesApi();

    public newSite: SiteModel = new SiteModel();

    public sites: Array<SiteModel> = [];

    public search: string = '';

    public mouseDrag: boolean = false;

    public paymentMethods: Array<string> = ['Credit Card', 'Bank transfer/ACH', 'Paypal'];
    public roles: Array<string> = ['System Admin', 'Account Admin', 'Safety Manager', 'Safety Inspector'];
    public responseAgencyContacts: Array<string> = ['Fire/Explosion', 'Security', 'IT/Communication'];

    public goToAddPlantPage() {
        console.log('go to add plant page clicked');
        this.$router.push({name: 'Site Setup'});
    }
    public headers: Array<any> = [
        {text: 'Organization Code', align: 'start', sortable: false, value: 'title', class: 'grey lighten-4 red--text'},
        {text: 'Organization Name', align: 'start', sortable: false, value: 'title'},
        {text: 'Site Code', value: 'description', sortable: false},
        {text: 'Site Name', value: 'status', sortable: false},
        {text: 'Job Order Code', value: 'actions', sortable: false},
        {text: 'Job Order Name', align: 'start', sortable: false, value: 'title'},
        {text: 'Role', value: 'description', sortable: false},
        {text: 'Job Order Status', value: 'status', sortable: false},
        {text: 'Job Order Start Date', value: 'actions', sortable: false},
        {text: 'Job Order End Date', align: 'start', sortable: false, value: 'title'},
        {text: 'Account Status', value: 'description', sortable: false},
        {text: 'License Type', value: 'status', sortable: false},
        {text: 'Account Start Date', value: 'actions', sortable: false},
        {text: 'Account End Date', align: 'start', sortable: false, value: 'title'},
    ];

    public mounted() {
        this.loadAllSites();
        let thElm: any = null;
        let startOffset: any = 0;
        Array.prototype.forEach.call(document.querySelectorAll('table th'), function (th) {
            th.style.position = 'relative';

            const grip: any = document.createElement('div');
            grip.innerHTML = '&nbsp;';
            grip.style.top = 0;
            grip.style.right = 0;
            grip.style.bottom = 0;
            grip.style.width = '5px';
            grip.style.position = 'absolute';
            grip.style.cursor = 'col-resize';
            grip.addEventListener('mousedown', function (e: any) {
                thElm = th;
                startOffset = th.offsetWidth - e.pageX;
            });

            th.appendChild(grip);
        });
        document.addEventListener('mousemove', e => {
            if (thElm) {
                console.log(startOffset + e.pageX + 'px');
                thElm.style.width = startOffset + e.pageX + 'px';
                //disable text selection of th
                this.mouseDrag = true;
            }
        });
        document.addEventListener('mouseup', () => {
            thElm = undefined;
            this.mouseDrag = false;
        });
    }

    public loadAllSites() {
        this.LoaderSrv.showFullScreenLoader('Loading Sites...');

        let filter = {};
        if (this.search) {
            filter = {
                where: {
                    or: [
                        {siteName: {like: `%${this.search}%`}},
                        {siteCode: {like: `%${this.search}%`}},
                        {organizationName: {like: `%${this.search}%`}},
                        {role: {like: `%${this.search}%`}},
                        {accountStatus: {like: `%${this.search}%`}},
                        {organizationCode: {like: `%${this.search}%`}},
                        {userRole: {like: `%${this.search}%`}},
                        {licenseType: {like: `%${this.search}%`}},
                        // {accountStartDate: {like: `%${this.search}%`}},
                        // {accountEndDate: {like: `%${this.search}%`}},
                    ],
                },
            };
        } else {
            filter = {
                order: ['siteName'],
            };
        }

        this.sitesApi
            .getAccountSites(this.UserSessionSrv.accountId!, filter)
            .then(res => {
                this.sites = res ?? [];
            })
            .catch(err => {
                //
            })
            .finally(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    public clearSearch() {
        this.search = '';
        this.loadAllSites();
    }

    public addNewSiteClick() {
        this.newSite = new SiteModel();
        this.CoreSrv.OpenModal(this.name);
    }

    public editSite(site: SiteModel) {
        this.newSite = new SiteModel({
            ...site,
            jobOrderStartDate: this.$helpers.formatDate(new Date(site.jobOrderStartDate!).toISOString(), 'yyyy-MM-dd'),
            jobOrderEndDate: this.$helpers.formatDate(new Date(site.jobOrderEndDate!).toISOString(), 'yyyy-MM-dd'),
            // accountStartDate: this.$helpers.formatDate(new Date(site.accountStartDate!).toISOString(), 'yyyy-MM-dd'),
            // accountEndDate: this.$helpers.formatDate(new Date(site.accountEndDate!).toISOString(), 'yyyy-MM-dd'),
            // licenseStartDate: this.$helpers.formatDate(new Date(site.licenseStartDate!).toISOString(), 'yyyy-MM-dd'),
            // licenseEndDate: this.$helpers.formatDate(new Date(site.licenseEndDate!).toISOString(), 'yyyy-MM-dd'),
        });
        this.CoreSrv.OpenModal(this.name);
    }

    public saveNewSite() {
        // alert('asdasd');
        // console.log(this.newSite.accountType);
        // let validForm = true;
        // for (const [key, value] of Object.entries(this.newSite)) {
        //     console.log(`${key}: ${value}`);
        //     if (!['created', 'updated', 'id'].includes(key)) {
        //         if (!value) {
        //             validForm = false;
        //             break;
        //         }
        //     }
        // }
        // console.log('aaaaa', validForm);
        if (this.newSite.id) {
            this.sitesApi
                .UpdateSite(
                    this.newSite.id,
                    new SiteModel({
                        ...this.newSite,
                        jobOrderStartDate: new Date(this.newSite.jobOrderStartDate!),
                        jobOrderEndDate: new Date(this.newSite.jobOrderEndDate!),
                        // accountStartDate: new Date(this.newSite.accountStartDate!),
                        // accountEndDate: new Date(this.newSite.accountEndDate!),
                        // licenseEndDate: new Date(this.newSite.licenseEndDate!),
                        // licenseStartDate: new Date(this.newSite.licenseStartDate!),
                    })
                )
                .subscribe(
                    res => {
                        this.AlertSrv.show('success', 'Site has been saved successfully!');
                        this.newSite = new SiteModel();
                        this.CoreSrv.CloseModal(this.name);
                        this.loadAllSites();
                    },
                    err => {
                        this.AlertSrv.show('error', 'Unable to save site. Please try again later.');
                    }
                );
        } else {
            this.sitesApi
                .Create(
                    new SiteModel({
                        ...this.newSite,
                        jobOrderStartDate: new Date(this.newSite.jobOrderStartDate!),
                        jobOrderEndDate: new Date(this.newSite.jobOrderEndDate!),
                        accountId: this.UserSessionSrv.accountId!,
                        // accountStartDate: new Date(this.newSite.accountStartDate!),
                        // accountEndDate: new Date(this.newSite.accountEndDate!),
                        // licenseEndDate: new Date(this.newSite.licenseEndDate!),
                        // licenseStartDate: new Date(this.newSite.licenseStartDate!),
                    })
                )
                .subscribe(
                    res => {
                        if (res.Data?.id) {
                            this.AlertSrv.show('success', 'Site has been saved successfully!');
                            this.newSite = new SiteModel();
                            this.CoreSrv.CloseModal(this.name);
                            this.loadAllSites();
                        }
                    },
                    err => {
                        this.AlertSrv.show('error', 'Unable to save site. Please try again later.');
                    }
                );
        }
    }

    public delteSite(site: SiteModel) {
        this.ConfirmSrv.open('Delete Site', 'Are you sure you want to delete this site?').then(yes => {
            if (yes) {
                this.LoaderSrv.showFullScreenLoader();
                this.sitesApi
                    .deleteSite(site.id!)
                    .subscribe(
                        res => {
                            if (res.Status) {
                                this.AlertSrv.show('success', 'Site has been deleted successfully!');
                                this.loadAllSites();
                            }
                        },
                        err => {
                            this.AlertSrv.show('error', 'Unable to delete site. Please try again later.');
                        }
                    )
                    .add(() => {
                        this.LoaderSrv.hideFullScreenLoader();
                    });
            }
        });
    }

    public clearForm(id: string) {
        this.ConfirmSrv.open('Cancel Data Entry', 'You will lose all unsaved progress if you click Yes', {ConfirmText: 'Yes', CancelText: 'No'}).then(yes => {
            if (yes) {
                //close modal
                this.CoreSrv.CloseModal(this.name);
            }
            // this.sitesApi.deleteSite(id).subscribe(
            //     res => {
            //         if (res.Status) {
            //             this.AlertSrv.show('success', 'Site has been deleted successfully!');
            //             this.loadAllSites();
            //         }
            //     },
            //     err => {
            //         this.AlertSrv.show('error', 'Unable to delete site. Please try again later.');
            //     }
            // );
        });
    }
}
