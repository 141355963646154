import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {DataFieldStatusModel} from '@/sdk';
import {SitesApi} from '@/sdk/api-services';
import {Component, Prop} from 'vue-property-decorator';

@Component
export default class LocationRulesWarningsDialogComponent extends VueWrapper {
    public readonly name: string = 'location-rules-warnings';

    public siteDataFieldStatuses: DataFieldStatusModel[] = [];

    public siteApi: SitesApi = new SitesApi();

    @Prop({type: String, required: true})
    public siteId!: string;

    public getSiteDataFieldStatuses(siteId: string) {
        this.LoaderSrv.showFullScreenLoader('Loading Warnings....');
        this.siteApi
            .getLocationRulesWarnings(siteId)
            .then(res => {
                this.siteDataFieldStatuses = res;
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    public open() {
        this.getSiteDataFieldStatuses(this.siteId);
        this.CoreSrv.OpenModal(this.name);
    }

    public close() {
        this.siteDataFieldStatuses = [];
        this.CoreSrv.CloseModal(this.name);
    }
}
