import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {Component} from 'vue-property-decorator';

@Component
export default class searchSiteComponent extends VueWrapper {
    public goToAddPlantPage() {
        console.log('go to add plant page clicked');
        this.$router.push({name: 'AddPlant'});
    }

    public areas: String[] = ['hello', 'bye bye']; // null = null;
    search: string = '';
    headers: Array<any> = [
        {
            text: 'Location Name',
            align: 'center',
            filterable: false,
            value: 'name',
            width: '90px',
        },
        {
            text: 'Area Type',
            align: 'center',
            value: 'area-type',
            width: '90px',
        },
        {
            text: 'Flammable Material',
            align: 'center',
            value: 'flammable-material',
            width: '90px',
        },
        {
            text: 'IP Req',
            align: 'center',
            value: 'ip-req',
            width: '90px',
        },
        {
            text: 'Explosion Risk',
            align: 'center',
            value: 'exp-risk',
            width: '90px',
        },
        {
            text: 'Gas Zone',
            align: 'center',
            value: 'gas-zone',
            width: '90px',
        },
        {
            text: 'Gas Group',
            align: 'center',
            value: 'gas-group',
            width: '90px',
        },
        {
            text: 'Gas T-Class',
            align: 'center',
            value: 'gas-tclass',
            width: '90px',
        },
        {
            text: 'Dust Zone',
            value: 'dust-zone',
            align: 'center',
            width: '90px',
        },
        {
            text: 'Dust T-Class',
            value: 'dust-tclass',
            align: 'center',
            width: '90px',
        },
        {
            text: 'Equipment Actions',
            value: 'equip-actions',
            align: 'center',
            width: '90px',
        },
    ];
}
