import {AnyObject} from '@/globals';
import Vue from 'vue';
import {equipmentTypesList} from './equipments-list';

const gradeofInspection: Array<String> = ['D', 'C', 'V'];
const equipType: Array<String> = ['Fixed', 'Transportable', 'Portable', 'Stored'];
const jobTypes: Array<String> = ['Ex Inspection', 'Corrective Action'];
// types of inspections Initial Inspection/Periodic Inspection/Sample Inspection/Continious Supervision 중 선택
const typesOfInspection: Array<String> = ['Initial Inspection', 'Periodic Inspection', 'Sample Inspection', 'Continious Supervision'];
const actionTypes: Array<String> = [
    'Removal',
    'Isolation',
    'Repair',
    'Monitor',
    'Replacement',
    'Risk Assessment',
    'Shutdown',
    'New Addition',
    'Environment Condition',
    'Others',
];
const faultLevels: Array<string> = ['Very Heigh', 'Heigh', 'Medium', 'Low', 'Very Low'];
const actionPriorities: Array<number> = [1, 2, 3, 4, 5];

//DYD = David Yun Dependent List
const protectionTechniquesDYDList: AnyObject = {
    'Ex d': [
        {
            key: 'da',
            value: 'da',
        },
        {
            key: 'db',
            value: 'db',
        },
        {
            key: 'dc',
            value: 'dc',
        },
    ],
    'Ex e': [
        {
            key: 'eb',
            value: 'eb',
        },
        {
            key: 'ec',
            value: 'ec',
        },
    ],
    'Ex i': [
        {
            key: 'ia',
            value: 'ia',
        },
        {
            key: 'ib',
            value: 'ib',
        },
        {
            key: 'ic',
            value: 'ic',
        },
    ],
    'Ex m': [
        {
            key: 'ma',
            value: 'ma',
        },
        {
            key: 'mb',
            value: 'mb',
        },
        {
            key: 'mc',
            value: 'mc',
        },
    ],
    'Ex o': [
        {
            key: 'ob',
            value: 'ob',
        },
        {
            key: 'oc',
            value: 'oc',
        },
    ],
    'Ex p': [
        {
            key: 'pxb',
            value: 'pxb',
        },
        {
            key: 'pyb',
            value: 'pyb',
        },
        {
            key: 'pzc',
            value: 'pzc',
        },
    ],
    'Ex q': [
        {
            key: 'qb',
            value: 'qb',
        },
        {
            key: 'qc',
            value: 'qc',
        },
    ],
    'Ex t': [
        {
            key: 'ta',
            value: 'ta',
        },
        {
            key: 'tb',
            value: 'tb',
        },
        {
            key: 'tc',
            value: 'tc',
        },
    ],
    'Ex s': [
        {
            key: 'sa',
            value: 'sa',
        },
        {
            key: 'sb',
            value: 'sb',
        },
        {
            key: 'sc',
            value: 'sc',
        },
    ],
};

declare module 'vue/types/vue' {
    interface Vue {
        $formOptions: {
            gradeofInspection: Array<String>;
            equipmentTypesList: AnyObject;
            protectionTechniquesDYDList: AnyObject;
            equipType: Array<String>;
            jobTypes: Array<String>;
            typesOfInspection: Array<String>;
            actionTypes: Array<String>;
            faultLevels: Array<string>;
            actionPriorities: Array<number>;
        };
    }
}

const formOptions = {
    install() {
        Vue.prototype.$formOptions = {
            gradeofInspection,
            equipmentTypesList: equipmentTypesList,
            protectionTechniquesDYDList,
            equipType,
            jobTypes,
            typesOfInspection,
            actionTypes,
            faultLevels,
            actionPriorities,
        };
    },
};

Vue.use(formOptions);
