import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {JobApi} from '@/sdk/api-services/job.api';
import {Component} from 'vue-property-decorator';
import {SiteTeamMembersService} from '../../../shared/services';
import {JobService} from '../../../shared/services/job.service';

@Component
export default class JobLinkJobManagerComponent extends VueWrapper {
    public jobsSrv: JobService = new JobService();
    public siteTeamMembersSrv: SiteTeamMembersService = new SiteTeamMembersService();

    public jobsApi: JobApi = new JobApi();

    public isJobManagerLinked(managerId: string) {
        return !!this.jobsSrv.jobJobManagers.find(x => x.id === managerId);
    }

    public unlinkJobManager(jobId: string, managerId: string) {
        this.ConfirmSrv.open('Unlink Job Manager', 'Are you sure you want to unlink job manager from this job?').then(yes => {
            if (yes) {
                this.jobsApi.unlinkJobJobManager(jobId, managerId).subscribe(
                    res => {
                        if (res.Status) {
                            this.AlertSrv.show('success', 'Job Manager has been unlinked successfully!');
                            this.jobsSrv.getJobJobManagers(jobId);
                        }
                    },
                    err => {
                        this.AlertSrv.show('error', err.message || 'Unable to unlink job manager.');
                    }
                );
            }
        });
    }

    public linkJobManager(jobId: string, managerId: string) {
        this.ConfirmSrv.open('Link Job Manager', 'Are you sure you want to link job manager to this job?').then(yes => {
            if (yes) {
                this.jobsApi.linkJobJobManager(jobId, managerId).subscribe(
                    res => {
                        if (res.Status) {
                            this.AlertSrv.show('success', 'Job Manager has been linked successfully!');
                            this.jobsSrv.getJobJobManagers(jobId);
                        }
                    },
                    err => {
                        this.AlertSrv.show('error', err.message || 'Unable to link job manager');
                    }
                );
            }
        });
    }
}
