import MainLayoutComponent from '@/components/layouts/main-layout/main-layout.component';
import {RouteConfig} from 'vue-router';

export const AccountRoutes: Array<RouteConfig> = [
    {
        path: '/account',
        name: 'Account',
        component: MainLayoutComponent,
        redirect: {name: 'User Setup'},
        children: [
            {
                path: 'user-setup',
                name: 'User Setup',
                component: () => import('../user-setup/user-setup.component.vue'),
            },
            {
                path: 'access-management',
                name: 'Access Management',
                redirect: {name: 'By Users Access'},
                component: () => import('./access-management/access-management.component.vue'),
                children: [
                    {
                        path: '',
                        redirect: {name: 'By Users Access'},
                    },
                    {
                        path: 'by-users-access',
                        name: 'By Users Access',
                        component: () => import('./by-users-access/by-users-access.component.vue'),
                    },
                    {
                        path: 'by-users-access/:userId?',
                        name: 'by-user-access-sites',
                        component: () => import('./by-users-access/by-users-access.component.vue'),
                    },
                    {
                        path: 'by-sites-access',
                        name: 'By Sites Access',
                        component: () => import('./by-sites-access/by-sites-access.component.vue'),
                    },
                    {
                        path: 'by-sites-access/:siteId?',
                        name: 'by-sites-access-users',
                        component: () => import('./by-sites-access/by-sites-access.component.vue'),
                    },
                ],
            },
        ],
    },
];
