import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {AnyObject, KeyValuePair, REPORT_PERIODS} from '@/globals';
import {addDays, endOfWeek, format, isAfter, isBefore, isWithinInterval, startOfWeek, subDays, subMonths, subWeeks} from 'date-fns';
import {Component} from 'vue-property-decorator';

@Component
export default class JobReportFilterComponent extends VueWrapper {
    selectedItem: string | string[] | null = null;
    selectedYear: number | null = null;
    selectedMonth: number | null = null;
    selectedQuarter: number | null = null;

    date: string | Array<string> | null = format(new Date(), 'yyyy-MM-dd');

    maxAllowed: string | null = null;
    minAllowed: string | null = null;
    alloedDates: Array<string> = [];

    public reportPeriodsObj: AnyObject = {
        [REPORT_PERIODS.Date]: {
            title: 'Date',
            selected: false,
        },
        [REPORT_PERIODS.Yesterday]: {
            title: 'Yesterday',
            selected: false,
        },
        [REPORT_PERIODS.Monthly]: {
            title: 'Monthly',
            selected: false,
        },
        [REPORT_PERIODS.Quarterly]: {
            title: 'Quarterly',
            selected: false,
        },
        [REPORT_PERIODS.Yearly]: {
            title: 'Yearly',
            selected: false,
        },
        [REPORT_PERIODS.CustomRange]: {
            title: 'Custom Range',
            selected: false,
        },
    };

    public reportPeriods: Array<KeyValuePair> = [
        {
            key: REPORT_PERIODS.Date,
            value: 'Date',
        },
        // {
        //     key: REPORT_PERIODS.Today,
        //     value: 'Today',
        // },
        {
            key: REPORT_PERIODS.Yesterday,
            value: 'Yesterday',
        },
        // {
        //     key: REPORT_PERIODS.ThisWeek,
        //     value: 'This Week',
        // },
        // {
        //     key: REPORT_PERIODS.LastWeek,
        //     value: 'Last Week',
        // },
        // {
        //     key: REPORT_PERIODS.ThisMonth,
        //     value: 'This Month',
        // },
        // {
        //     key: REPORT_PERIODS.LastMonth,
        //     value: 'Last Month',
        // },
        // {
        //     key: REPORT_PERIODS.CustomMonth,
        //     value: 'Custom Month',
        // },
        {
            key: REPORT_PERIODS.Monthly,
            value: 'Monthly',
        },
        {
            key: REPORT_PERIODS.Quarterly,
            value: 'Quarterly',
        },
        {
            key: REPORT_PERIODS.Yearly,
            value: 'Yearly',
        },
        {
            key: REPORT_PERIODS.CustomRange,
            value: 'Custom Range',
        },
    ];

    public get months() {
        return Array.from({length: 12}, (v, k) => k + 1).map(m => {
            return {
                month: m,
                name: format(new Date(new Date().getFullYear(), m - 1), 'MMMM'),
            };
        });
    }

    public get years() {
        const start = 2000;
        const end = new Date().getFullYear();

        return Array.from({length: end - start + 1}, (v, k) => k + start);
    }

    public get quarters() {
        return Array.from({length: 4}, (v, k) => k + 1).map(q => {
            return {
                quarter: q,
                name: `${format(new Date(2000, (q - 1) * 3), 'MMMM')} - ${format(new Date(2000, q * 3 - 1), 'MMMM')}`,
            };
        });
    }

    public get REPORT_PERIODS() {
        return REPORT_PERIODS;
    }

    public mounted() {
        console.log('JobReportFilterComponent mounted');
    }

    public get isShowDatePicker() {
        return true;
    }

    public get isRangePicker() {
        return this.selectedItem === REPORT_PERIODS.CustomRange;
    }

    public get pickerType() {
        if (this.selectedItem === REPORT_PERIODS.Monthly || this.selectedItem === REPORT_PERIODS.Quarterly) {
            return 'month';
        }
        return 'date';
    }

    public onItemClick() {
        this.date = format(new Date(), 'yyyy-MM-dd');
    }

    public isAllowedDate(val: string) {
        if (!this.minAllowed || !this.maxAllowed) {
            // if (this.selectedItem !== REPORT_PERIODS.ThisWeek && isAfter(new Date(val), addDays(new Date(), 1))) {
            //     return false;
            // }
            return true;
        }

        return isWithinInterval(new Date(val), {start: new Date(this.minAllowed), end: new Date(this.maxAllowed)});
    }

    public onDateFilterChange() {
        this.alloedDates = [];
        this.minAllowed = this.maxAllowed = null;

        // if (this.selectedItem === REPORT_PERIODS.Today) {
        //     this.date = format(new Date(), 'yyyy-MM-dd');

        //     this.minAllowed = this.maxAllowed = this.date;
        // } else
        if (this.selectedItem === REPORT_PERIODS.Yesterday) {
            this.date = format(subDays(new Date(), 1), 'yyyy-MM-dd');

            this.minAllowed = this.maxAllowed = this.date;
        }
        // else if (this.selectedItem === REPORT_PERIODS.ThisWeek) {
        //     this.date = [format(startOfWeek(new Date(), {weekStartsOn: 1}), 'yyyy-MM-dd'), format(endOfWeek(new Date(), {weekStartsOn: 1}), 'yyyy-MM-dd')];

        //     this.minAllowed = this.date[0];
        //     this.maxAllowed = this.date[1];
        // }
        // else if (this.selectedItem === REPORT_PERIODS.LastWeek) {
        //     this.date = [
        //         format(subWeeks(startOfWeek(new Date(), {weekStartsOn: 1}), 1), 'yyyy-MM-dd'),
        //         format(subWeeks(endOfWeek(new Date(), {weekStartsOn: 1}), 1), 'yyyy-MM-dd'),
        //     ];

        //     this.minAllowed = this.date[0];
        //     this.maxAllowed = this.date[1];
        // }
        // else if (this.selectedItem === REPORT_PERIODS.ThisMonth) {
        //     this.date = format(new Date(), 'yyyy-MM');

        //     this.minAllowed = this.maxAllowed = this.date;
        // }
        // else if (this.selectedItem === REPORT_PERIODS.LastMonth) {
        //     this.date = format(subMonths(new Date(), 1), 'yyyy-MM');

        //     this.minAllowed = this.maxAllowed = this.date;
        // }
        else if (this.selectedItem === REPORT_PERIODS.CustomRange) {
            this.date = [];
        } else {
            this.date = format(new Date(), 'yyyy-MM-dd');
        }
    }
}
