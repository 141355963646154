import {ServiceClass} from '@/decorators';
import {AnyObject, areaFieldsList, equipmentFieldsList} from '@/globals';
import {AlertService, DataFieldModel, FieldOptions, LoaderService} from '@/sdk';
import {DataFieldApi} from '@/sdk/api-services/data-field.api';
import {SiteLocationService} from './site-location.service';

@ServiceClass()
export class AdminDataFieldService {
    public dataFieldApi: DataFieldApi = new DataFieldApi();

    public areaFieldOptions: AnyObject = {};

    public equipmentFieldOptions: {[key: string]: Array<FieldOptions>} = {};

    public areaFields: Array<DataFieldModel> = [];
    public equipmentFields: Array<DataFieldModel> = [];

    public selected: number | null = null;
    public selected1: number | null = null;

    public selectedField: DataFieldModel | null = null;

    public selectedFieldOptions: {[key: string]: Array<FieldOptions>} = {};

    public dependentFieldOption: string | null = null;

    public isDependent: boolean = false;
    public newOption: FieldOptions | null = new FieldOptions();

    public siteLocationsSrv: SiteLocationService = new SiteLocationService();

    constructor() {
        for (const field of Object.values(areaFieldsList)) {
            this.areaFieldOptions[field] = [];
        }
        // this.loadFieldOptions();
    }

    // public getDependentOptions(fieldKey: string, dependentOnValue: string | Array<string>) {
    //     const tmp = this.equipmentFieldOptions[fieldKey] ?? [];

    //     if (typeof dependentOnValue === 'string') {
    //         return tmp.filter(x => x.dependentOnFieldOptionId === dependentOnValue);
    //     } else if (dependentOnValue instanceof Array) {
    //         return tmp.filter(x => dependentOnValue.includes(x.dependentOnFieldOptionId!));
    //     }

    //     return [];
    // }

    public getDependentItemsOf(fieldId: string, dependentFieldId: string) {
        let options: Array<string> = [];
        if (dependentFieldId === equipmentFieldsList.equipmentProtectionTechnique) {
            options = this.siteLocationsSrv.selectedLcoation?.equipmentDetail?.protectionTechnique ?? [];
        }

        const fieldOptions = this.equipmentFieldOptions[fieldId] ?? [];
        return fieldOptions.filter(x => x.dependentOnFieldId === dependentFieldId && options.includes(x.dependentOnFieldOptionId!));
    }

    public reset() {
        this.newOption = new FieldOptions();
    }

    public fieldType(field: DataFieldModel) {
        if (field.type === 'select') {
            return 'Single Select';
        } else if (field.type === 'multi-select') {
            return 'Multi Select';
        }

        return '';
    }

    public loadAreaFields() {
        new LoaderService().showFullScreenLoader();
        this.dataFieldApi
            .areaFields()
            .subscribe(res => {
                this.areaFields = res.Data ?? [];

                this.areaFields.forEach(x => {
                    this.selectedFieldOptions[x.id!] = [];

                    this.selectedFieldOptions = {...this.selectedFieldOptions};
                });
            })
            .add(() => {
                new LoaderService().hideFullScreenLoader();
            });
    }

    public loadEquipmentFields() {
        new LoaderService().showFullScreenLoader();
        this.dataFieldApi
            .equipmentFields()
            .subscribe(res => {
                this.equipmentFields = res.Data ?? [];

                this.equipmentFields.forEach(x => {
                    this.selectedFieldOptions[x.id!] = [];
                });

                this.selectedFieldOptions = {...this.selectedFieldOptions};
            })
            .add(() => {
                new LoaderService().hideFullScreenLoader();
            });
    }

    public getFieldsOptions(id: string) {
        // this.selectedFieldOptions[id] = [];

        new LoaderService().showFullScreenLoader();
        this.dataFieldApi
            .getDataFieldOptions(id, {
                include: [
                    {
                        relation: 'dependentOnField',
                    },
                ],
            })
            .subscribe(res => {
                this.selectedFieldOptions[id] = res.Data ?? [];
            })
            .add(() => {
                new LoaderService().hideFullScreenLoader();
            });
    }

    public getEquipmentFieldOptionValue(fieldId: string, optionId: string) {
        return this.equipmentFieldOptions[fieldId]?.find(x => x.id === optionId)?.value;
    }

    public loadAreaFieldOptions() {
        new LoaderService().showFullScreenLoader(`Loading Area field's options.`);
        return this.dataFieldApi
            .areaFieldOptions()
            .then(res => {
                this.areaFieldOptions = res;

                return res;
            })
            .catch(err => {
                //
            })
            .finally(() => {
                new LoaderService().hideFullScreenLoader();
            });
    }

    public loadEquipmentFieldOptions() {
        new LoaderService().showFullScreenLoader(`Loading Equipment's field options.`);
        return this.dataFieldApi
            .equipmentFieldOptions()
            .then(res => {
                this.equipmentFieldOptions = res as any;

                return res;
            })
            .catch(err => {
                //
            })
            .finally(() => {
                new LoaderService().hideFullScreenLoader();
            });
    }
}
