import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {Component} from 'vue-property-decorator';
import {
    mdiNumeric1Circle,
    mdiNumeric2Circle,
    mdiNumeric3Circle,
    mdiNumeric4Circle,
    mdiNumeric5Circle,
    mdiNumeric6Circle,
    mdiArrowLeftBoldOutline,
    mdiArrowRightBoldOutline,
} from '@mdi/js';

import {SelectedSiteService} from '@/views/shared/services';

@Component({
    name: 'SiteSetupButtonBarComponent',
})
export default class SiteSetupButtonBarComponent extends VueWrapper {
    public goToAddPlantPage() {
        console.log('go to add plant page clicked');
        this.$router.push({name: 'AddPlant'});
    }

    public areas: String[] = ['hello', 'bye bye']; // null = null;
    search: string = '';
    imgsource: string = '../../../../assets/nofitification_bell.svg';

    public icons: any = {
        mdiNumeric1Circle,
        mdiNumeric2Circle,
        mdiNumeric3Circle,
        mdiNumeric4Circle,
        mdiNumeric5Circle,
        mdiNumeric6Circle,
        mdiArrowLeftBoldOutline,
        mdiArrowRightBoldOutline,
    };

    public selectedService = new SelectedSiteService();

    public stepObj: any = {
        1: {name: 'sites-list', params: {siteId: this.selectedService.selectedSite}},
        2: {name: 'Main', params: {siteId: this.selectedService.selectedSite}},
        3: {name: 'Team Main', params: {siteId: this.selectedService.selectedSite}},
        4: {name: 'Job Main', params: {siteId: this.selectedService.selectedSite}},
    };
    public mounted() {
        console.log('-----mounted', this.$route.name);
        if (this.$route.name === 'sites-list') {
            this.selectedService.selectedSite = null;
            this.selectedService.stepNumber = 2;
        } else if (this.$route.params['siteId']) {
            this.selectedService.selectedSite = this.$route.params['siteId'];
        }
        console.log('mounted----------->', this.selectedService.selectedSite);
    }
    public moveBack() {
        console.log('before', this.selectedService.stepNumber);
        if (this.selectedService.selectedSite !== '') {
            const step = this.selectedService.stepNumber - 1;
            if (this.stepObj[step]) {
                const obj = this.stepObj[step];
                obj.params.siteId = this.selectedService.selectedSite;
                this.$router.push(obj);
                this.selectedService.stepNumber = this.selectedService.stepNumber - 1;
                console.log('after', this.selectedService.stepNumber);
            }
        }
    }
    public async moveForward() {
        console.log('before', this.selectedService.stepNumber);
        console.log('siteID', this.selectedService.selectedSite);
        if (this.selectedService.selectedSite !== '') {
            const step = this.selectedService.stepNumber + 1;
            console.log('before if', step);
            if (this.stepObj[step]) {
                const obj = this.stepObj[step];
                console.log('before', obj);
                obj.params.siteId = this.selectedService.selectedSite;
                console.log('after', obj);
                await this.$router.push(obj);
                this.selectedService.stepNumber = this.selectedService.stepNumber + 1;
                console.log('after', this.selectedService.stepNumber);
            }
        }
    }
}
