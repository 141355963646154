import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {Component} from 'vue-property-decorator';
import papaparse from 'papaparse';
import {AnyObject} from '@/globals';
import Papa from 'papaparse';
import {DataFieldModel, FieldOptions} from '@/sdk';

@Component
export default class ImportFieldsDbCsvDialogComponent extends VueWrapper {
    public readonly name: string = 'import-fields-db-csv-dialog';

    public file: File | null = null;

    public parsedCsv: Array<DataFieldModel> = [];

    public config: AnyObject = {
        delimiter: '', // auto-detect
        // newline: '\r\n',
        // quoteChar: '"',
        // escapeChar: '"',
        // header: false,
        // transformHeader: undefined,
        // dynamicTyping: false,
        preview: 0,
        encoding: '',
        // worker: false,
        // comments: false,
        // step: undefined,
        // complete: undefined,
        // error: undefined,
        // download: false,
        // downloadRequestHeaders: undefined,
        // downloadRequestBody: undefined,
        // skipEmptyLines: false,
        // chunk: undefined,
        // chunkSize: undefined,
        // fastMode: undefined,
        // beforeFirstChunk: undefined,
        // withCredentials: undefined,
        // transform: undefined,
        // delimitersToGuess: [',', '\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP],
    };

    public filesSelected(event: FileList) {
        if (event.length > 0) {
            this.file = event.item(0);

            papaparse.parse(this.file as any, {
                complete: d => {
                    if (d.data?.length) {
                        console.log(d.data);
                        for (let i = 0; i + 7 <= d.data.length; i += 7) {
                            this.parsedCsv.push(
                                new DataFieldModel({
                                    id: (d.data as any)[i + 0]?.length >= 2 ? (d.data as any)[i + 0][1] : null,
                                    title: (d.data as any)[i + 3]?.length >= 2 ? (d.data as any)[i + 3][1] : null,
                                    fieldOptions: (d.data as any)[i + 5]?.length
                                        ? (d.data as any)[i + 5].map((x: any) => {
                                              return new FieldOptions({
                                                  dataFieldId: (d.data as any)[i + 0]?.length >= 2 ? (d.data as any)[i + 0][1] : null,
                                                  value: x,
                                              });
                                          })
                                        : [],
                                })
                            );
                        }
                    }
                },
            });
        }
    }

    public save() {
        this.close();
    }

    public open() {
        this.CoreSrv.OpenModal(this.name);
    }

    public close() {
        this.file = null;
        this.parsedCsv = [];
        this.CoreSrv.CloseModal(this.name);
    }
}
