import ThemeWrapper from '../Theme/theme.wrapper';
import {CoreService} from '@/services/core.service';
import {AlertService, ComponentService, ConfirmService, LoaderService, UserSession as UserSessionService} from '@/sdk';
import {SdkConfig} from '@/sdk/sdk.config';
import {LibrariesService} from '@/services/libraries.service';
import {FilterModel} from '@/sdk/models/common/filter.model';
import {AppVersion} from '@/app.config';
import {Subscription} from 'rxjs';
import {PERMISSIONS, ROLES, areaFieldsList, equipmentFieldsList} from '@/globals';

export default abstract class VueWrapper extends ThemeWrapper {
    public readonly True: string = 'True';
    public readonly False: string = 'False';
    // Properties
    private Subscriptions: Array<Subscription> = [];

    // Getters and Setters
    set AddSubscription$(subscription: Subscription) {
        this.Subscriptions.push(subscription);
    }

    // Services
    public CoreSrv = new CoreService();
    public ConfirmSrv = new ConfirmService();
    public AlertSrv = new AlertService();
    public ComponentSrv = new ComponentService();
    public LoaderSrv = new LoaderService();
    public UserSessionSrv = new UserSessionService();
    public LibrariesServie = new LibrariesService();
    public FilterModel = new FilterModel();

    public get ApiUrl() {
        return SdkConfig.ApiPath;
    }
    public get ApiBaseUrl() {
        return SdkConfig.ApiBaseUrl;
    }

    get AppVersion() {
        return AppVersion;
    }

    public get $ROLES() {
        return ROLES;
    }

    public rolePermissions: Array<string> = [];

    public mounted() {
        this.AddSubscription$ = new UserSessionService().rolePermissions.subscribe(permissions => {
            this.rolePermissions = permissions ?? [];
        });
    }

    public get PERMISSIONS() {
        return PERMISSIONS;
    }

    public get areaFieldsList() {
        return areaFieldsList;
    }

    public get equipmentFieldsList() {
        return equipmentFieldsList;
    }

    /**
     * Unsubscribes from all the subscriptions
     * (*used in `beforeDestroy` hook of Vue Components)*.
     */
    public unsubscribeSubscriptions() {
        for (const subscription of this.Subscriptions) {
            if (subscription) {
                try {
                    subscription.unsubscribe();
                } catch (err) {
                    alert();
                    console.log(new Error(err as any));
                }
            }
        }
        if (this.Subscriptions.length > 0) {
            this.Subscriptions.splice(0, this.Subscriptions.length);
        }
    }

    public beforeDestroy() {
        this.unsubscribeSubscriptions();
    }
}
