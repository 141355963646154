import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {JobNonConformityRecordsModel} from '@/sdk';
import {JobApi} from '@/sdk/api-services/job.api';
import {JobService} from '@/views/shared/services';
import {Component, Prop} from 'vue-property-decorator';
import JobNonConformityRecordFilesDialogComponent from '../job-non-conformity-record-files-dialog/job-non-conformity-record-files-dialog.component';

@Component({
    components: {
        JobNonConformityRecordFilesDialogComponent,
    },
})
export default class JobDetailsFormComponent extends VueWrapper {
    public $refs!: {
        jobNonConformityRecordFilesDialog: JobNonConformityRecordFilesDialogComponent;
    };

    public jobsSrv: JobService = new JobService();
    public jobsApi: JobApi = new JobApi();

    @Prop({type: Boolean, default: false})
    public readonly isReadOnly!: boolean;

    public saveJobDetails() {
        if (this.jobsSrv.selectedJob) {
            this.LoaderSrv.showFullScreenLoader();
            this.jobsApi
                .updateJobs([this.jobsSrv.selectedJob])
                .then(res => {
                    if (this.jobsSrv.selectedJob) {
                        // this.loadInspectionFormQuestions();
                        // this.$emit('jobSelected', this.jobsSrv.selectedJob);
                        this.AlertSrv.show('success', 'Inspection Forms has been assigned successfully!');
                    }
                })
                .catch(err => {
                    this.AlertSrv.show('error', 'Unalbe to assign Inspection Forms to job.');
                })
                .finally(() => {
                    this.LoaderSrv.hideFullScreenLoader();
                });
        }
    }

    public addNewNonConformityRecord() {
        this.jobsSrv.jobNonConformityRecords.unshift(new JobNonConformityRecordsModel({editable: true}));
    }

    public editJobNonConformityRecord(record: JobNonConformityRecordsModel) {
        record.editable = true;
    }

    public cancelNonConformityRecordEdit(record: JobNonConformityRecordsModel) {
        if (record.id) {
            record.editable = false;
        } else {
            this.jobsSrv.jobNonConformityRecords = this.jobsSrv.jobNonConformityRecords.filter(x => !!x.id);
        }
    }

    public uploadNonConformityRecordFile(record: JobNonConformityRecordsModel) {
        this.$refs.jobNonConformityRecordFilesDialog.openDialog(record.id!);
    }

    public saveNonConformityRecord(record: JobNonConformityRecordsModel) {
        delete record.editable;
        if (this.jobsSrv.selectedJob?.id) {
            if (record.id) {
                this.LoaderSrv.showFullScreenLoader();
                this.jobsApi
                    .updateJobNonConformityRecord(this.jobsSrv.selectedJob.id!, record)
                    .then(res => {
                        record.editable = false;
                        this.jobsSrv.getJobNonConformityRecords(this.jobsSrv.selectedJob!.id!);
                        this.AlertSrv.show('success', 'Non conformity record saved successfully.');
                    })
                    .catch(err => {
                        this.AlertSrv.show('error', 'Unable to save non conformity record.');
                    })
                    .finally(() => {
                        this.LoaderSrv.hideFullScreenLoader();
                    });
            } else {
                delete record.created;
                delete record.updated;

                this.LoaderSrv.showFullScreenLoader();
                this.jobsApi
                    .saveJobNonConformityRecord(this.jobsSrv.selectedJob.id!, record)
                    .then(res => {
                        this.jobsSrv.getJobNonConformityRecords(this.jobsSrv.selectedJob!.id!);
                        this.AlertSrv.show('success', 'Non conformity record saved successfully.');
                    })
                    .catch(err => {
                        this.AlertSrv.show('error', 'Unable to save non conformity record.');
                    })
                    .finally(() => {
                        this.LoaderSrv.hideFullScreenLoader();
                    });
            }
        }
    }

    public deleteNonConformityRecord(record: JobNonConformityRecordsModel) {
        if (this.jobsSrv.selectedJob?.id) {
            this.ConfirmSrv.open('Delete Non Conformity Record', 'Are you sure you want to delete this non conformity record?').then(yes => {
                if (yes) {
                    this.LoaderSrv.showFullScreenLoader();
                    this.jobsApi
                        .deleteJobNonConformityRecord(this.jobsSrv.selectedJob!.id!, record.id!)
                        .then(res => {
                            this.jobsSrv.getJobNonConformityRecords(this.jobsSrv.selectedJob!.id!);
                            this.AlertSrv.show('success', 'Non conformity record deleted successfully.');
                        })
                        .catch(err => {
                            this.AlertSrv.show('error', 'Unable to delete non conformity record.');
                        })
                        .finally(() => {
                            this.LoaderSrv.hideFullScreenLoader();
                        });
                }
            });
        }
    }
}
