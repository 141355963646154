import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {Component} from 'vue-property-decorator';
import {TeamApi} from '@/sdk/api-services/team.api';
import {SiteModel, CreateUserViewModel, UserViewModel, UserSession} from '@/sdk';
import {RoleApi} from '@/sdk/api-services/role.api';
// import SiteSetupAreaBrowserComponent from '../../site-setup-area-browser/site-setup-area-browser.component';
// import SiteSetupHeaderComponent from '../../site-setup-header/site-setup-header.component';
// import SiteSetupButtonBarComponent from '../../site-setup-button-bar/site-setup-button-bar.component';
import ClientAppBarComponent from '@/components/vuetify/client-app-bar/client-app-bar.component';
import {RolesService, UserSetupService} from '../shared/services';
import {SiteTeamMembersApi, UsersApi} from '@/sdk/api-services';
import SaveUserDialogComponent from '../user-setup/save-user-dialog/save-user-dialog.component';
import SiteSetupButtonBarComponent from '../client/site-setup/site-setup-button-bar/site-setup-button-bar.component';
// import JobSetupMainComponent from '../job-setup-main/job-setup-main.component';

@Component({
    components: {
        ClientAppBarComponent,
        // JobSetupMainComponent,
        SiteSetupButtonBarComponent,
        SaveUserDialogComponent,
    },
})
export default class TeamSetupComponent extends VueWrapper {
    public $refs!: {
        saveUserDialogRef: SaveUserDialogComponent;
    };

    public teamMembers: Array<UserViewModel> = [];
    public teamApi: TeamApi = new TeamApi();
    public siteTeamMemberApi: SiteTeamMembersApi = new SiteTeamMembersApi();
    public usersApi: UsersApi = new UsersApi();
    public roleApi: any = new RoleApi();
    public newMember: CreateUserViewModel = new CreateUserViewModel();
    public siteId: string = '';

    public readonly rolesSrv: RolesService = new RolesService();

    public userSession = new UserSession();

    public getMembers() {
        this.siteTeamMemberApi.getSiteTeamMembers(this.siteId).then(res => {
            this.teamMembers = res ?? [];

            // once the team is retrieved, get each member's role
            // for (const member of this.team) {
            //     let roleId = '';
            //     let role: any;
            //     this.usersApi
            //         .getById(member.id, {
            //             include: [
            //                 {
            //                     relation: 'roles',
            //                 },
            //             ],
            //         })
            //         .subscribe((res: any) => {
            //             roleId = res.roles ? res.roles[0].id : '';
            //             this.newMember = new CreateUserViewModel({
            //                 roleId: roleId,
            //                 firstName: res.firstName,
            //                 middleName: res.middleName,
            //                 lastName: res.lastName,
            //                 mobilePhone: res.mobilePhone,
            //                 workPhone: res.workEmail,
            //                 email: res.email,
            //                 selectedSiteIds: res.selectedSiteIds,
            //                 password: '',
            //                 id: res.id,
            //             });
            //             console.log('roleID:');
            //             console.log(this.newMember.roleId);
            //             for (role of this.rolesSrv.roles) {
            //                 if (role.id == roleId) {
            //                     member.role = role.name;
            //                 }
            //             }
            //         });
            // }
        });
    }

    public created() {
        this.siteId = this.$route.params['siteId'];
    }
    public mounted() {
        this.getMembers();
        this.rolesSrv.getRoles();
    }
    // public addNewMemberClick() {
    //     // this.newSite = new SiteModel();
    //     this.newMember = new CreateUserViewModel();
    //     this.CoreSrv.OpenModal(this.name);
    // }

    public editUserClick(userId: string) {
        new UserSetupService().getUserToEdit(userId, false).then(() => {
            this.$refs.saveUserDialogRef.open();
        });
    }

    public saveMember() {
        //
    }

    // public deleteTeamMember(member: CreateUserViewModel) {
    //     this.ConfirmSrv.open(
    //         'Delete Member',
    //         `If this member is linked to any job, all those linkings will be delete too. Are you sure you want to delete this member?`
    //     ).then(yes => {
    //         if (yes) {
    //             this.LoaderSrv.showFullScreenLoader();
    //             this.teamApi
    //                 .deleteSiteMember(member.id!, this.siteId)
    //                 .subscribe(
    //                     res => {
    //                         this.AlertSrv.show('success', 'Member has been deleted successfully!');
    //                         this.getMembers();
    //                     },
    //                     err => {
    //                         this.AlertSrv.show('error', err.message);
    //                     }
    //                 )
    //                 .add(() => {
    //                     this.LoaderSrv.hideFullScreenLoader();
    //                 });
    //         }
    //     });
    // }
}
