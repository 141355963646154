import {ServiceClass} from '@/decorators';
import {TestPointModel} from '../models/common/testpoint.model';
import {BaseApi} from './base.api';

@ServiceClass()
export class CreatePointApi extends BaseApi {
    public CreatePoint(data: any) {
        return this.POST_Request(`${this.ApiUrl}/test-points`, data);
    }
    public GetPoints() {
        return this.GET_Request(`${this.ApiUrl}/test-points`);
    }
}
