import VueWrapper from '@/components/core/Vue/vue.wrapper';
import SaveWrapperComponent from '@/components/shared/save-wrapper/save-wrapper.component';
import {ACCOUNT_STATUS, PaymentMethodsList} from '@/globals';
import {SiteModel} from '@/sdk';
import {SitesApi} from '@/sdk/api-services';
import {AccountSetupService, RolesService, UserSetupService} from '@/views/shared/services';
import SetPasswordDialogComponent from '@/views/user-setup/set-password-dialog/set-password-dialog.component';
import {Component, Prop} from 'vue-property-decorator';

@Component({
    components: {
        SetPasswordDialogComponent,
    },
})
export default class SaveAccountDialogComponent extends VueWrapper {
    public $refs!: {
        setPasswordRef: SetPasswordDialogComponent;
        saveWrapperRef: SaveWrapperComponent;
    };

    @Prop({
        type: String,
        default: () => {
            return 'save-account-dialog';
        },
    })
    public readonly name!: string;

    @Prop({
        type: String,
        default: () => {
            return 'save';
        },
    })
    public readonly type!: string;

    public accountSetupSrv: AccountSetupService = new AccountSetupService();

    public rolesSrv: RolesService = new RolesService();

    public paymentMethods = PaymentMethodsList;

    public get accountStatuses() {
        return Object.keys(ACCOUNT_STATUS);
    }

    public sites: Array<SiteModel> = [];

    public mounted() {
        //
    }

    public get isProfile() {
        return this.type === 'profile';
    }

    public async save(validate: Function) {
        console.log('save click');
        // const valid = await validate();

        // if (!valid) {
        // this.AlertSrv.show('warning', 'Please fill all the required fields in all tabs of the form to save.');
        // return;
        // }

        if (await this.accountSetupSrv.save()) {
            this.close();
        }
    }

    public open() {
        this.clearForm();
        if (this.CoreSrv.isModalOpened(this.name)) {
            this.close();
        }
        this.CoreSrv.OpenModal(this.name);
    }
    public close() {
        this.CoreSrv.CloseModal(this.name);
    }

    public clearForm() {
        this.$refs.saveWrapperRef?.reset();
        this.close();
    }
}
