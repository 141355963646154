import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {AccountViewModel} from '@/sdk';
import {AccountsApi} from '@/sdk/api-services';
import UserSetupComponent from '@/views/user-setup/user-setup.component';
import {Component} from 'vue-property-decorator';

@Component({
    components: {
        UserSetupComponent,
    },
})
export default class AccountUsersSetupComponent extends VueWrapper {
    public accountId: string = '';

    public account: AccountViewModel = new AccountViewModel();

    public accountApi: AccountsApi = new AccountsApi();

    public created() {
        this.accountId = this.$route.params.id;
    }

    public mounted() {
        this.getAccount();
    }

    public async getAccount() {
        try {
            this.LoaderSrv.showFullScreenLoader('Loading account details...');
            this.account = await this.accountApi.getAccountById(this.accountId);
        } catch (error: any) {
            this.AlertSrv.show('error', error?.message ?? 'Unable to get account details');
        }
        this.LoaderSrv.hideFullScreenLoader();
    }
}
