import {SessionModel} from '../models/authentication/session.model';
import {CookieBrowser, Cookies} from './cookie-browser';

import {BehaviorSubject} from 'rxjs';
import {ROLES, parseJwt} from '@/globals';
import {JwtPayloadModel, RolePermission} from '..';
import {addDays} from 'date-fns';
import {ServiceClass} from '@/decorators';

@ServiceClass()
export class UserSession {
    private prefix: string = '$Foex$:';
    public _session = new BehaviorSubject<SessionModel | null>(null);

    public rolePermissions = new BehaviorSubject<Array<string>>([]);

    private Cookie: Cookies = new CookieBrowser();

    get Session() {
        return this._session.value;
    }

    set Session(session: SessionModel | null) {
        this._session.next(session);
    }

    constructor() {
        this.fetchCookies();
        if (this.Session?.token) {
            // console.log('decoded token: ', parseJwt(this.Session?.token));
        }
    }

    public setJwt(token: string) {
        const data = this.Session;
        if (data) {
            data.token = token;
            this._session.next(data);
            this.save();
        }
    }

    public fetchCookies() {
        this.Cookie.remove(`${this.prefix}Membership`);
        this._session.next(
            new SessionModel({
                email: this.load('email'),
                loginId: this.load('loginId'),
                Expiry: this.load('Expiry'),
                FirstName: this.load('FirstName'),
                LastName: this.load('LastName'),
                PhoneNumber: this.load('PhoneNumber'),
                token: this.load('token'),
                rememberMe: this.load('rememberMe'),
                role: this.load('role'),
                roleId: this.load('roleId'),
                userId: this.load('userId'),
                accountId: this.load('accountId'),
                // UserProfile: this.load('UserProfile'),
            })
        );
    }

    public permission(permission: string) {
        return this.rolePermissions.value.indexOf(permission) !== -1;
    }

    public get role() {
        return this.Session?.role;
    }

    public get isSystemAdmin() {
        return this.role === ROLES.SYS_ADMIN;
    }

    public get isUserAuthenticated() {
        if (!this.Session?.token || !this.Session.userId) {
            this.clear();
            return false;
        }

        const jwtData = parseJwt<JwtPayloadModel>(this.Session.token);

        if (!jwtData.exp) {
            this.clear();
            return false;
        }

        //  - 10 * 60 * 1000
        if (new Date().valueOf() >= jwtData.exp * 1000) {
            this.clear();
            return false;
        }

        return true;
    }

    public get isTokenExpired() {
        return !this.isUserAuthenticated;
    }

    public get userId() {
        return this.Session?.userId ?? null;
    }

    public get accountId() {
        return this.Session?.accountId ?? null;
    }

    public save() {
        if (this.Session) {
            for (const item of Object.entries(this.Session)) {
                this.persist(item[0], item[1], addDays(Date.now(), 1));
            }
            return true;
        }
        return false;
    }

    private load(key: string): any {
        if (this.Cookie) {
            return this.Cookie.get(`${this.prefix}${key}`);
        }
    }
    private persist(key: string, value: any, expires: Date) {
        this.Cookie.set(`${this.prefix}${key}`, typeof value === 'object' ? JSON.stringify(value) : value, {expires, domain: '.mhparks.com'});
    }

    public clear(): void {
        if (this._session.value) {
            Object.keys(this._session.value).forEach((key: string) => this.Cookie.remove(`${this.prefix}${key}`));
        }
        if (this._session.value) {
            this._session.next(null);
        }
    }
}
