import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {Component} from 'vue-property-decorator';

@Component
export default class HeaderComponent extends VueWrapper {
    public goToAddPlantPage() {
        console.log('go to add plant page clicked');
        this.$router.push({name: 'AddPlant'});
    }

    public areas: String[] = ['hello', 'bye bye']; // null = null;
    search: string = '';
}
