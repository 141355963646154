import MainLayoutComponent from '@/components/layouts/main-layout/main-layout.component';
import {RouteConfig} from 'vue-router';
import AccountCreationComponent from '../accountCreation/accountCreation.component';
import SigninComponent from '../login/signin.component';
import SignupComponent from '../signup/signup.component';
import AuthLayoutComponent from '@/components/layouts/auth-layout/auth-layout.component';

export const AuthenticationRoutes: Array<RouteConfig> = [
    {
        path: '/auth',
        name: 'auth',
        component: AuthLayoutComponent,
        redirect(to) {
            return {name: 'signin'};
        },
        children: [
            {
                path: 'signin',
                name: 'signin',
                component: SigninComponent,
            },
            {
                path: 'signup',
                name: 'signup',
                component: SignupComponent,
            },
            {
                path: '/accountCreation',
                name: 'accountCreation',
                component: AccountCreationComponent,
            },
        ],
    },
];
