export class CorrectiveActionResultViewModel {
    id?: string | null = null;
    title: string | null = null;
    mediaId: string | null = null;
    fileName: string | null = null;
    fileType: string | null = null;
    jobId: string | null = null;
    created: Date | null = null;

    constructor(data?: Partial<CorrectiveActionResultViewModel>) {
        Object.assign(this, data);
    }
}

export class SaveCorrectiveActionResultViewModel {
    title: string | null = null;

    constructor(data?: Partial<SaveCorrectiveActionResultViewModel>) {
        Object.assign(this, data);
    }
}
