export class GasDbModel {
    id?: string | null = null;
    casNo: string | null = null;
    name: string | null = null;
    otherName: string | null = null;
    formula: string | null = null;
    formula1: string | null = null;
    molarMass: string | null = null;
    vaporPressure: string | null = null;
    liquidDensity: string | null = null;
    relativeDensity: string | null = null;
    meltingPoint: string | null = null;
    boilingPoint: string | null = null;
    flashPoint: string | null = null;
    LFLVol: string | null = null;
    UFLVol: string | null = null;
    LFL: string | null = null;
    UFL: string | null = null;
    AIT: string | null = null;
    mostIncMixture: string | null = null;
    MESG: string | null = null;
    g100g0: string | null = null;
    MICRatio: string | null = null;
    tClass: string | null = null;
    equipmentGroup: string | null = null;
    methodOfClass: string | null = null;

    constructor(data?: Partial<GasDbModel>) {
        Object.assign(this, data);
    }
}
