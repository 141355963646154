import {ServiceClass} from '@/decorators';
import {AnyObject} from '@/globals';
import {UserViewModel} from '../models';
import {BaseApi} from './base.api';

@ServiceClass()
export class SiteTeamMembersApi extends BaseApi {
    public getSiteTeamMembers(siteId: string) {
        return this.GET_RequestAsync<Array<UserViewModel>>(`${this.ApiUrl}/site-team-members/${siteId}`);
    }

    public getSiteWorkers(siteId: any) {
        return this.GET_Request<Array<UserViewModel>>(`${this.ApiUrl}/site-team-members/${siteId}/workers`);
    }

    // public getSiteInspectorSupervisors(siteId: any) {
    //     return this.GET_Request<Array<UserViewModel>>(`${this.ApiUrl}/site-team-members/${siteId}/inspector-supervisors`);
    // }

    // public getSafetyOfficers(siteId: any) {
    //     return this.GET_Request<Array<UserViewModel>>(`${this.ApiUrl}/site-team-members/${siteId}/safety-officers`);
    // }

    public getJobManagers(siteId: any) {
        return this.GET_Request<Array<UserViewModel>>(`${this.ApiUrl}/site-team-members/${siteId}/job-managers`);
    }

    // public getOperationManagers(siteId: any) {
    //     return this.GET_Request<Array<UserViewModel>>(`${this.ApiUrl}/site-team-members/${siteId}/operation-managers`);
    // }
}
