import {ServiceClass} from '@/decorators';
import {DocumentationModel} from '../models';
import {TestPointModel} from '../models/common/testpoint.model';
import {BaseApi} from './base.api';

@ServiceClass()
export class DocumentationApi extends BaseApi {
    public Create(data: DocumentationModel) {
        delete data.id;

        return this.POST_Request<DocumentationModel>(`${this.ApiUrl}/documentations`, data);
    }

    public GetDocuments(locationId: string) {
        return this.GET_Request<Array<DocumentationModel>>(`${this.ApiUrl}/documentations/${locationId}`);
    }

    public CreateFiles(locationId: string, files: Array<File>, documentationId?: string) {
        const mediaData = new FormData();

        mediaData.append('locationId', locationId!);
        if (documentationId) {
            mediaData.append('documentationId', documentationId);
        }
        for (const file of files) {
            mediaData.append('files[]', file!);
        }

        return this.POST_FileRequest<Array<DocumentationModel>>(`${this.ApiUrl}/documentations/add-files`, mediaData);
    }

    public AddDocument(locationId: string, file: File, documentType: string) {
        const mediaData = new FormData();

        mediaData.append('locationId', locationId!);
        mediaData.append('documentType', documentType!);

        mediaData.append('files', file!);

        return this.POST_FileRequest<Array<DocumentationModel>>(`${this.ApiUrl}/documentations/add-document`, mediaData);
    }

    public AddDocumentCable(locationId: string, equipmentWiringCableId: string, file: File, documentType: string) {
        const mediaData = new FormData();

        console.log('equipmentWiringCableId', equipmentWiringCableId);

        mediaData.append('locationId', locationId!);
        mediaData.append('documentType', documentType!);
        mediaData.append('equipmentWiringCableId', equipmentWiringCableId!);

        mediaData.append('files', file!);

        return this.POST_FileRequest<Array<DocumentationModel>>(`${this.ApiUrl}/documentations/add-document`, mediaData);
    }

    public delete(id: string) {
        return this.DELETE_Request<any>(`${this.ApiUrl}/documentations/${id}`);
    }
}
