import {ServiceClass} from '@/decorators';
import {AnyObject, KeyValuePair} from '@/globals';
import {AlertService, DataFieldStatusModel, LoaderService, LocationModel, SiteModel} from '@/sdk';
import {LocationApi, SitesApi} from '@/sdk/api-services';
import {WiringCableModel} from '@/sdk/models/location/wiring-cables.model';
import {CoreService} from '@/services/core.service';
import EventEmitter from 'events';
import {Subject} from 'rxjs';

@ServiceClass()
export class SiteLocationService {
    public name: string = 'save-location-dialog';
    public location: LocationModel = new LocationModel();

    public clickedLocationId?: string | null = null;

    public enterItemSiteId: string | null = null;
    public enterItemId: string | null = null;
    public selectedItemId: string | null = null;
    public selectedItemElem: HTMLElement | null = null;
    public selectedDragItem: LocationModel | null = null;

    public site: SiteModel | null = null;
    public parentLocation: LocationModel | null = null;

    public selectedLcoation: LocationModel | null = null;

    public deletedLocationId: string | null = null;

    public siteLocations: Array<LocationModel> = [];

    public addAt: string = 'sub';
    private addAtLevel: number = 0;

    public locationDetailDataFieldStatuses: Array<DataFieldStatusModel> = [];

    public eventEmitter: EventEmitter = new EventEmitter();

    // public myCustomEvent = {
    //     listener: new Map(),
    //     on: (label: string, callback: Function) => {
    //         this.myCustomEvent.listener.has(label) || this.myCustomEvent.listener.set(label, []);
    //         this.myCustomEvent.listener.get(label).push(callback);
    //     },
    //     off: (label: string, callback: Function | boolean = true) => {
    //         if (callback === true) {
    //             // remove listeners for all callbackfunctions
    //             this.myCustomEvent.listener.delete(label);
    //         } else {
    //             // remove listeners only with match callbackfunctions
    //             const _off = (inListener: any) => {
    //                 const listeners = inListener.get(label);
    //                 if (listeners) {
    //                     inListener.set(
    //                         label,
    //                         listeners.filter((value: any) => !(value === callback))
    //                     );
    //                 }
    //             };
    //             _off(this.myCustomEvent.listener);
    //         }
    //     },
    //     emit: (label: string, ...args: any) => {
    //         const listeners = this.myCustomEvent.listener.get(label);
    //         if (listeners && listeners.length) {
    //             listeners.forEach((listener: any) => {
    //                 listener(...args);
    //             });
    //             return true;
    //         }
    //         return false;
    //     },
    // };

    public types: Array<KeyValuePair> = [
        {
            key: 'location',
            value: 'Location',
        },
        {
            key: 'equipment',
            value: 'Equipment',
        },
        {
            key: 'distribution',
            value: 'Distribution',
        },
    ];
    AlertSrv: any;

    public get getTypes() {
        // if (this.addAtLevel === 1) {
        //     if (this.addAt === 'sub') {
        //         return [this.types[1], this.types[2]];
        //     }
        //     return this.types;
        // } else if (this.addAtLevel === 2) {
        //     if (this.addAt === 'sub') {
        //         return [this.types[2]];
        //     }
        //     return [this.types[1], this.types[2]];
        // } else if (this.addAtLevel === 3) {
        //     if (this.addAt === 'sub') {
        //         return [];
        //     }
        //     return [this.types[2]];
        // }
        return this.types;
    }

    public get isAreaSelected() {
        return this.selectedLcoation?.type === 'location';
    }

    public get isEquipmentSelected() {
        return this.selectedLcoation?.type === 'equipment';
    }

    public deleteLocationFromCanvas(locationId: string) {
        console.log('deleteLocationFromCanvas = locationId', locationId);
        console.log('deleteLocationFromCanvas = this.deletedLocationId', this.deletedLocationId);
        this.deletedLocationId = locationId;
        return locationId;
    }

    public openSiteLocationDialog(parent?: LocationModel, location?: LocationModel | null, conf: AnyObject = {addAt: 'sub', level: 0}) {
        this.addAt = conf.addAt;
        this.addAtLevel = conf.level;

        // this.site = site;
        this.location = location ?? new LocationModel();

        if (conf.addAt === 'eqp' && !this.location.id) {
            this.location.type = 'equipment';
        } else if (conf.addAt === 'dist' && !this.location.id) {
            this.location.type = 'distribution';
        } else if (conf.addAt === 'sub' || conf.addAt === 'same') {
            this.location.type = 'location';
        }

        this.parentLocation = parent ?? null;
        new CoreService().OpenModal(this.name);
    }
    public closeSiteLocationDialog() {
        new CoreService().CloseModal(this.name);
    }

    public updateSequence(id: string, sequence: number) {
        return new Promise(resolve => {
            new LocationApi()
                .UpdateSequence(id, sequence)
                .subscribe(res => {
                    this.getSiteLocations();
                })
                .add(() => {
                    resolve(null);
                });
        });
    }

    public setParentNull(id: string) {
        const found = this.siteLocations.find(x => x.id == id);
        if (found) {
            found.locationId = null;
            new LoaderService().showFullScreenLoader();
            new LocationApi()
                .UpdateLocation(id, found)
                .subscribe(res => {
                    this.getSiteLocations();
                })
                .add(() => {
                    new LoaderService().hideFullScreenLoader();
                });
        }
    }

    public updateParentId(id: string, parentId: string) {
        const found = this.siteLocations.find(x => x.id == id);
        if (found && !!parentId) {
            found.locationId = parentId;
            new LoaderService().showFullScreenLoader();
            new LocationApi()
                .UpdateLocation(id, found)
                .subscribe(res => {
                    this.getSiteLocations();
                })
                .add(() => {
                    new LoaderService().hideFullScreenLoader();
                });
        }
    }

    public updateLocationToCanvasDiagram() {
        // console.log('this.selectedLcoation');
        // console.log(this.selectedLcoation);
        // console.log('this.site => ', this.site);
        const defaultSavedModelContent = {
            class: 'GraphLinksModel',
            linkFromPortIdProperty: 'fromPort',
            linkToPortIdProperty: 'toPort',
            nodeDataArray: [
                {
                    key: -1,
                    category: 'Conditional',
                    loc: '700 150',
                    text: 'Start',
                    equipment: 'equip.png',
                    dp: 'dp.png',
                    earthWire: 'earthWire.png',
                    arrow: 'arrow.png',
                    hand: 'hand.png',
                    undo: 'undo.png',
                    redo: 'redo.png',
                    zoomIn: 'zoomIn.png',
                    zoomOut: 'zoomOut.png',
                },
                {
                    key: -99,
                    category: 'ConditionalPower',
                    text: 'Power Source',
                    loc: '100 150',
                    icon: 'power.png',
                    color: 'moccasin',
                    move: true,
                },
            ],
            linkDataArray: [],
        };

        const dataToCanvasJsonString = this.site && this.site.drawingCanvas1 ? this.site!.drawingCanvas1! : JSON.stringify(defaultSavedModelContent);

        // this.getSiteLocations();
        const siteLocations = this.siteLocations;

        // console.log('dataToCanvasJsonString', dataToCanvasJsonString);
        // console.log('siteLocations -> ', siteLocations);
        if (dataToCanvasJsonString) {
            const canvasLocations = JSON.parse(dataToCanvasJsonString);
            // console.log(canvasLocations!.nodeDataArray!);
            let initialLocation = 50;
            // console.log('initialLocation', initialLocation);

            let updated = false;
            const locationsToVerify = canvasLocations!.nodeDataArray!;
            if (siteLocations && siteLocations.length) {
                const allPromises = siteLocations.map(async (location: any, index: number) => {
                    const locationInJsonArray = locationsToVerify.find((x: any) => x.key === location.id);
                    // console.log('locationInJsonArray', locationInJsonArray);
                    initialLocation = initialLocation + index * 50;
                    // console.log('initialLocation', initialLocation);
                    if (!locationInJsonArray && location.type !== 'location') {
                        const newNode = {
                            text: location.title || 'New Node',
                            icon: location.type === 'equipment' ? 'equip.png' : 'dp.png',
                            ind0: '#fff',
                            ind1: '#fff',
                            move: true,
                            loc: `0,${initialLocation}`,
                            key: location.id,
                        };
                        // console.log('UPDATING canvasLocations newNode -> ', newNode);
                        canvasLocations.nodeDataArray.push(newNode);
                        // console.log(canvasLocations);
                        await new Promise((resolve: any) => {
                            new SitesApi()
                                .UpdateSite(this.site!.id!, new SiteModel({...this.site, drawingCanvas1: JSON.stringify(canvasLocations)}))
                                .subscribe(
                                    res => {
                                        console.log('Site has been saved successfully!', res);
                                        console.log('Site has been saved successfully!', res.Data);
                                        // this.AlertSrv.show('success', 'Site has been saved successfully!');
                                    },
                                    err => {
                                        // this.AlertSrv.show('error', 'Unable to save site. Please try again later.');
                                    }
                                )
                                .add(() => {
                                    resolve(null);
                                });
                        });
                        updated = true;
                    }
                });

                Promise.all(allPromises).then(() => {
                    if (updated) window.location.reload();
                });
            }
        }
    }

    public getSiteLocations(id?: string) {
        if (id || this.site?.id) {
            new LoaderService().showFullScreenLoader();
            new SitesApi()
                .getSiteLocations(id ?? this.site!.id!, {
                    include: [
                        {
                            relation: 'locationDetail',
                        },
                        {
                            relation: 'equipmentDetail',
                        },
                        {
                            relation: 'distributionPanel',
                        },
                        {
                            relation: 'switchBoards',
                            order: ['sequence'],
                        },
                        {
                            relation: 'coresGroups',
                        },
                        {
                            relation: 'wiringCables',
                            order: ['sequence'],
                        },
                    ],
                })
                .subscribe(
                    res => {
                        const locations = res.Data ?? [];

                        for (const location of locations) {
                            const found = this.siteLocations.find(x => x.id === location.id);
                            location.expanded = found?.expanded || false;
                        }
                        this.siteLocations = locations;
                        // console.log('res.Data', locations);
                        // console.log('this.siteLocations pre', this.siteLocations);
                        this.eventEmitter.emit('onSiteLocationsLoaded', 'this.siteLocations');

                        this.updateLocationToCanvasDiagram();
                    },
                    err => {
                        new AlertService().show('error', err.message ?? err.Message ?? 'Unable to load site.');
                    }
                )
                .add(() => {
                    new LoaderService().hideFullScreenLoader();
                });
        }
    }

    public getSiteLocation(id: string) {
        new Promise((resolve, reject) => {
            new LoaderService().showFullScreenLoader();
            new LocationApi()
                .getLocationById(id!, {
                    include: [
                        {
                            relation: 'equipmentDetail',
                        },
                        {
                            relation: 'parentLocation',
                            scope: {
                                include: [
                                    {
                                        relation: 'locationDetail',
                                    },
                                ],
                            },
                        },
                        {
                            relation: 'distributionPanel',
                        },
                        {
                            relation: 'switchBoards',
                        },
                        {
                            relation: 'wiringCables',
                            order: ['sequence'],
                        },
                        {
                            relation: 'documentations',
                        },
                    ],
                })
                .subscribe(
                    async res => {
                        if (res.Data) {
                            // console.log('RESULT', res.Data);
                            for (const doc of res.Data.documentations ?? []) {
                                const found = this.selectedLcoation?.documentations?.find(x => x.id === doc.id);
                                doc.expanded = found?.expanded || false;
                            }

                            this.selectedLcoation = new LocationModel(res.Data);

                            const wireCableArr = this.selectedLcoation!.wiringCables || [];
                            if (wireCableArr.length) {
                                this.selectedLcoation!.wiringCables = wireCableArr.sort((a, b) => a.sequence! - b.sequence!);
                                // console.log('wireCableArr 2', wireCableArr);
                            } else {
                                this.selectedLcoation!.wiringCables = Object.assign([]);
                            }

                            const switchBoards = this.selectedLcoation!.switchBoards || [];
                            if (switchBoards.length) {
                                for (let index = 0; index < switchBoards.length; index++) {
                                    const element = switchBoards[index];
                                    if (element.connectWith) {
                                        this.selectedLcoation!.switchBoards![index].finalMidArr = await this.makeConnectWithFields(element, index);
                                    }
                                }
                                this.selectedLcoation!.switchBoards = switchBoards.sort((a, b) => Number(a.created!) - Number(b.created!));
                                // console.log('wireCableArr 2', wireCableArr);
                            } else {
                                this.selectedLcoation!.switchBoards = Object.assign([]);
                            }

                            // if (
                            //     this.selectedLcoation.equipmentDetail &&
                            //     (!this.selectedLcoation.equipmentDetail?.certificationScheme ||
                            //         typeof this.selectedLcoation.equipmentDetail?.certificationScheme === 'string')
                            // ) {
                            //     this.selectedLcoation.equipmentDetail.certificationScheme = [];
                            // }
                            // if (
                            //     this.selectedLcoation.equipmentDetail &&
                            //     (!this.selectedLcoation.equipmentDetail?.protectionTechnique ||
                            //         typeof this.selectedLcoation.equipmentDetail?.protectionTechnique === 'string')
                            // ) {
                            //     this.selectedLcoation.equipmentDetail.protectionTechnique = [];
                            // }
                            // if (
                            //     this.selectedLcoation.equipmentDetail &&
                            //     (!this.selectedLcoation.equipmentDetail?.wiringProtectionTechnique ||
                            //         typeof this.selectedLcoation.equipmentDetail?.wiringProtectionTechnique === 'string')
                            // ) {
                            //     this.selectedLcoation.equipmentDetail.wiringProtectionTechnique = [];
                            // }
                        } else {
                            // this.selectedLcoation = new LocationModel({locationId: location.id});
                            this.selectedLcoation = null;
                        }
                        resolve(res);
                    },
                    err => {
                        new AlertService().show('warning', 'Unable to load data.');
                        reject(null);
                    }
                )
                .add(() => {
                    new LoaderService().hideFullScreenLoader();
                });
        });
    }

    public finalListArr: Array<any> = [];
    public allEquipmentDistributionLocations: Array<any> = [];
    public allEquipmentCables: Array<WiringCableModel> = [];
    public allDistributionSwitchBoards: any[] = [];

    public async makeConnectWithFields(entry: any, index: any = 0) {
        return new Promise((resolve: (d: any | null) => void, reject) => {
            const tempList: Array<any> = [];
            new LocationApi()
                .getLocationById(entry.connectWith, {
                    include: [
                        {
                            relation: 'switchBoards',
                        },
                        {
                            relation: 'wiringCables',
                            order: ['sequence'],
                        },
                    ],
                })
                .subscribe(
                    res => {
                        if (res.Data) {
                            // console.log('res.Data', res.Data);
                            if (res.Data.type === 'equipment') {
                                this.allEquipmentCables = res.Data.wiringCables! || [];
                                for (let j = 0; j < this.allEquipmentCables.length; j++) {
                                    const element2 = this.allEquipmentCables[j];
                                    tempList.push({id: element2.id, title: `Wire ${j + 1} (${element2.id})`});
                                    // this.finalListArr.push({id: element2.id, title: `Cable Entry ${j + 1} -> ${element2.name} - (${element2.id})`});
                                    // console.log(`Entry ${j + 1} -> ${element2.name} - (${element2.id})`);
                                }
                            } else if (res.Data.type === 'distribution') {
                                this.allDistributionSwitchBoards = res.Data.switchBoards! || [];
                                for (let j = 0; j < this.allDistributionSwitchBoards.length; j++) {
                                    const element3 = this.allDistributionSwitchBoards[j];
                                    tempList.push({
                                        id: element3.id,
                                        title: `Output ${j + 1} (${element3.id})`,
                                    });
                                    // console.log(`Switch Board ${j + 1} -> ${element3.outpulPhase} - (${element3.id})`);
                                }
                            }
                            console.log(`tempList ${index}`, tempList);
                            resolve(tempList);
                        }
                    },
                    err => {
                        reject(err);
                        // this.AlertSrv.show('warning', 'ID not found or deleted for Connecting Select Equip / DP .');
                        // console.error('warning', 'ID not found.');
                    }
                )
                .add(() => {
                    // this.LoaderSrv.hideFullScreenLoader();
                    // console.log('this.finalListArr[index]', this.finalListArr[index]);
                    // console.log('index', index);
                    console.log('tempList', tempList);
                    // this.finalListArr[index] = tempList;
                });
        });
    }

    public loadLocationDetailRules(locationId: string) {
        new LoaderService().showFullScreenLoader('Loading data field rules status.');
        new LocationApi()
            .GetLocationFormStatus(locationId)
            .subscribe(
                res => {
                    this.locationDetailDataFieldStatuses = res.Data ?? [];
                },
                err => {
                    //
                }
            )
            .add(() => {
                new LoaderService().hideFullScreenLoader();
            });
    }

    public checkFieldError(fieldId: string, subId: string | null = null) {
        if (subId) {
            const errors = this.locationDetailDataFieldStatuses.filter(x => x.dataFieldId === fieldId && x.subId === subId);
            return errors.map(x => x.message) ?? [];
        }
        const errors = this.locationDetailDataFieldStatuses.filter(x => x.dataFieldId === fieldId);
        return errors.map(x => x.message) ?? [];
    }

    // public deleteLocation(id: string) {

    // }
}
