import {ServiceClass} from '@/decorators';
import {AnyObject} from '@/globals';
import {LocationModel, RoleModel, RolePermission as RolePermissionModel, SiteModel, UserProfileModel} from '@/sdk';
import {BaseApi} from './base.api';

@ServiceClass()
export class RoleApi extends BaseApi {
    public getAll(filter: AnyObject = {}) {
        return this.GET_RequestAsync<Array<RoleModel>>(`${this.ApiUrl}/roles`);
    }

    public getRolePermissions(roleId: string) {
        return this.GET_Request<Array<RolePermissionModel>>(`${this.ApiUrl}/roles/${roleId}/role-permissions`);
    }
}
