import JobSetupLayoutComponent from '@/views/job-setup/layout/job-setup-layout.component.vue';
import JobSetupMainComponent from '@/views/job-setup/job-setup-main/job-setup-main.component.vue';
import {RouteConfig} from 'vue-router';
import TeamSetupComponent from '@/views/team-setup/team-setup';

export const TeamSetupRoutes: Array<RouteConfig> = [
    {
        path: '/team-setup',
        name: 'Team Setup',
        component: TeamSetupComponent,
        redirect: {name: 'Team Main'},
        children: [
            {
                path: 'main/:siteId',
                name: 'Team Main',
                component: JobSetupMainComponent,
                meta: {
                    title: '',
                    description: '',
                },
            },
        ],
    },
];
