import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {Component} from 'vue-property-decorator';
import ImportDustDbDialogComponent from './import-dust-db-dialog/import-dust-db-dialog.component';
import {DustDbService} from '@/views/shared/services';
import PreviewDustDbRecordDialogComponent from './preview-dust-db-record-dialog/preview-dust-db-record-dialog.component';
import {DustDbModel} from '@/sdk';

@Component({
    components: {
        ImportDustDbDialogComponent,
        PreviewDustDbRecordDialogComponent,
    },
})
export default class DustDbComponent extends VueWrapper {
    public $refs!: {
        importDustDbDialog: ImportDustDbDialogComponent;
        previewDustObjDialog: PreviewDustDbRecordDialogComponent;
    };

    public dustDbSrv: DustDbService = new DustDbService();

    public mounted() {
        this.LoaderSrv.showFullScreenLoader('Loading Dust DB');
        this.dustDbSrv.loadDustDb().finally(() => {
            this.LoaderSrv.hideFullScreenLoader();
        });
    }

    public preview(obj: DustDbModel) {
        this.dustDbSrv.selectedObj = obj;
        this.$refs.previewDustObjDialog.open();
    }

    public importDustDb() {
        this.$refs.importDustDbDialog.open();
    }
}
