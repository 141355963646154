export class DocumentationModel {
    public id?: string | null = null;
    public title?: string | null = null;
    public locationId?: string | null = null;
    public documentationId?: string | null = null;
    public equipmentWiringCableId?: string | null = null;
    public type?: string | null = null;
    public created?: string;
    public updated?: string;

    [key: string]: any;

    constructor(data?: Partial<DocumentationModel>) {
        Object.assign(this, data);
    }
}
