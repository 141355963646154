import JobSetupLayoutComponent from '@/views/job-setup/layout/job-setup-layout.component.vue';
import JobSetupMainComponent from '@/views/job-setup/job-setup-main/job-setup-main.component.vue';
import {RouteConfig} from 'vue-router';

export const JobSetupRoutes: Array<RouteConfig> = [
    {
        path: '/job-setup',
        name: 'Job Setup',
        component: JobSetupLayoutComponent,
        redirect: {name: 'Job Main'},
        children: [
            {
                path: 'main/:siteId',
                name: 'Job Main',
                component: JobSetupMainComponent,
                meta: {
                    title: '',
                    description: '',
                },
            },
        ],
    },
];
