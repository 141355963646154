import {ServiceClass} from '@/decorators';
import {AnyObject} from '@/globals';
import {
    AddJobNormalWorkPermit,
    CorrectiveActionResultViewModel,
    CreateUserViewModel,
    InspepctionFormModel,
    JobAssignedInspectionForm,
    JobCompletionRegisterViewModel,
    JobNonConformityRecordsModel,
    JobNormalWorkPermit,
    JobNormalWpToBeFilledByContractorViewModel,
    LocationModel,
    SaveCompletionRegisterViewModel,
    SaveCorrectiveActionResultViewModel,
    SignJobNormalWorkPermitViewwModel,
    SiteModel,
    UploadedFilesModel,
    UserViewModel,
} from '../models';
import {BaseApi} from './base.api';
import {JobNormalWworkPermitSignModel} from '../models/job/job-normal-work-permit-sign.model';

@ServiceClass()
export class JobApi extends BaseApi {
    public getJobs(filter: AnyObject = {}) {
        return this.GET_Request<Array<any>>(`${this.ApiUrl}/jobs?filter=${encodeURIComponent(JSON.stringify(filter))}`);
    }
    public updateJobs(jobs: any) {
        console.log(jobs);
        return this.PATCH_RequestAsync<any>(`${this.ApiUrl}/jobs`, {jobs: jobs});
    }
    public deleteJob(jobId: any) {
        return this.DELETE_Request<any>(`${this.ApiUrl}/jobs/${jobId}`);
    }

    public getJobWorkers(jobId: string, filter: AnyObject = {}) {
        return this.GET_Request<Array<UserViewModel>>(`${this.ApiUrl}/jobs/${jobId}/workers?filter=${encodeURIComponent(JSON.stringify(filter))}`);
    }

    // public getJobInspectorSupervisors(jobId: string, filter: AnyObject = {}) {
    //     return this.GET_Request<Array<UserViewModel>>(
    //         `${this.ApiUrl}/jobs/${jobId}/inspector-supervisors?filter=${encodeURIComponent(JSON.stringify(filter))}`
    //     );
    // }

    // public getJobSafetyOfficer(jobId: string, filter: AnyObject = {}) {
    //     return this.GET_Request<Array<UserViewModel>>(`${this.ApiUrl}/jobs/${jobId}/safety-officers?filter=${encodeURIComponent(JSON.stringify(filter))}`);
    // }

    public getJobJobManager(jobId: string, filter: AnyObject = {}) {
        return this.GET_Request<Array<UserViewModel>>(`${this.ApiUrl}/jobs/${jobId}/job-managers?filter=${encodeURIComponent(JSON.stringify(filter))}`);
    }

    // public getJobOperationManager(jobId: string, filter: AnyObject = {}) {
    //     return this.GET_Request<Array<UserViewModel>>(`${this.ApiUrl}/jobs/${jobId}/operation-managers?filter=${encodeURIComponent(JSON.stringify(filter))}`);
    // }

    public linkJobWorker(jobId: string, workerId: string, filter: AnyObject = {}) {
        return this.POST_Request<void>(`${this.ApiUrl}/jobs/${jobId}/link-worker/${workerId}?filter=${encodeURIComponent(JSON.stringify(filter))}`, {});
    }

    // public linkJobInspectorSupervisors(jobId: string, inspectorSupervisorId: string, filter: AnyObject = {}) {
    //     return this.POST_Request<void>(
    //         `${this.ApiUrl}/jobs/${jobId}/link-inspector-supervisor/${inspectorSupervisorId}?filter=${encodeURIComponent(JSON.stringify(filter))}`,
    //         {}
    //     );
    // }

    // public linkJobSafetyOfficer(jobId: string, safetyOfficerId: string, filter: AnyObject = {}) {
    //     return this.POST_Request<void>(
    //         `${this.ApiUrl}/jobs/${jobId}/link-safety-officer/${safetyOfficerId}?filter=${encodeURIComponent(JSON.stringify(filter))}`,
    //         {}
    //     );
    // }

    public linkJobJobManager(jobId: string, safetyManagerId: string, filter: AnyObject = {}) {
        return this.POST_Request<void>(
            `${this.ApiUrl}/jobs/${jobId}/link-job-manager/${safetyManagerId}?filter=${encodeURIComponent(JSON.stringify(filter))}`,
            {}
        );
    }

    // public linkJobOperationManager(jobId: string, operationManagerId: string, filter: AnyObject = {}) {
    //     return this.POST_Request<void>(
    //         `${this.ApiUrl}/jobs/${jobId}/link-operation-manager/${operationManagerId}?filter=${encodeURIComponent(JSON.stringify(filter))}`,
    //         {}
    //     );
    // }

    public unlinkJobWorkers(jobId: string, inspectorId: string, filter: AnyObject = {}) {
        return this.DELETE_Request<void>(`${this.ApiUrl}/jobs/${jobId}/unlink-worker/${inspectorId}?filter=${encodeURIComponent(JSON.stringify(filter))}`);
    }

    // public unlinkJobInspectorSupervisors(jobId: string, inspectorSupervisorId: string, filter: AnyObject = {}) {
    //     return this.DELETE_Request<void>(
    //         `${this.ApiUrl}/jobs/${jobId}/unlink-inspector-supervisor/${inspectorSupervisorId}?filter=${encodeURIComponent(JSON.stringify(filter))}`
    //     );
    // }

    // public unlinkJobSafetyOfficer(jobId: string, safetyOfficerId: string, filter: AnyObject = {}) {
    //     return this.DELETE_Request<void>(
    //         `${this.ApiUrl}/jobs/${jobId}/unlink-safety-officer/${safetyOfficerId}?filter=${encodeURIComponent(JSON.stringify(filter))}`
    //     );
    // }

    public unlinkJobJobManager(jobId: string, jobManagerId: string, filter: AnyObject = {}) {
        return this.DELETE_Request<void>(
            `${this.ApiUrl}/jobs/${jobId}/unlink-job-manager/${jobManagerId}?filter=${encodeURIComponent(JSON.stringify(filter))}`
        );
    }

    // public unlinkJobOperationManager(jobId: string, operationManagerId: string, filter: AnyObject = {}) {
    //     return this.DELETE_Request<void>(
    //         `${this.ApiUrl}/jobs/${jobId}/unlink-operation-manager/${operationManagerId}?filter=${encodeURIComponent(JSON.stringify(filter))}`
    //     );
    // }

    // job normal work permits

    public getNormalJobWorkPermits(jobId: string, filter: AnyObject = {}) {
        return this.GET_Request<Array<JobNormalWorkPermit>>(`${this.ApiUrl}/jobs/${jobId}/job-normal-work-permits?filter=${JSON.stringify(filter)}`);
    }

    public getNormalJobWorkPermitById(jobId: string, workPermitId: string, filter: AnyObject = {}) {
        return this.GET_Request<JobNormalWorkPermit>(`${this.ApiUrl}/jobs/${jobId}/job-normal-work-permits/${workPermitId}?filter=${JSON.stringify(filter)}`);
    }

    public saveNewJobNormalWorkPermit(jobId: string, data: AddJobNormalWorkPermit) {
        data.jobId = jobId;
        delete data.workPermitNo;
        return this.POST_Request<JobNormalWorkPermit>(`${this.ApiUrl}/jobs/${jobId}/job-normal-work-permits`, data);
    }

    public saveFilledByContractor(jobId: string, workPeritId: string, data: JobNormalWpToBeFilledByContractorViewModel) {
        return this.PATCH_Request<void>(`${this.ApiUrl}/jobs/${jobId}/job-normal-work-permits/${workPeritId}`, data);
    }

    public signJobNormalWorkPermit(workPermitId: string, data: SignJobNormalWorkPermitViewwModel) {
        delete data.id;
        return this.POST_Request<JobNormalWworkPermitSignModel>(`${this.ApiUrl}/job-normal-work-permits/${workPermitId}/job-normal-work-permit-signs`, data);
    }

    // public getJobAssignedInspectionForms(jobId: string) {
    //     return this.GET_Request<Array<JobAssignedInspectionForm>>(`${this.ApiUrl}/jobs/${jobId}/job-assigned-inspection-forms`);
    // }

    public saveAssignedInspectionForms(jobId: string, assigned: Array<string>) {
        return this.POST_Request<Array<JobAssignedInspectionForm>>(`${this.ApiUrl}/jobs/${jobId}/job-assigned-inspection-forms`, assigned);
    }

    public fillableInspectionForms(jobId: string) {
        return this.GET_RequestAsync<Array<InspepctionFormModel>>(`${this.ApiUrl}/jobs/${jobId}/fillable-inspection-forms`);
    }

    // job-non-conformity-records apis
    public getJobNonConformityRecords(jobId: string, filter: AnyObject = {}) {
        return this.GET_RequestAsync<Array<JobNonConformityRecordsModel>>(
            `${this.ApiUrl}/jobs/${jobId}/job-non-conformity-records?filter=${JSON.stringify(filter)}`
        );
    }

    public saveJobNonConformityRecord(jobId: string, data: JobNonConformityRecordsModel) {
        data.jobId = jobId;
        return this.POST_RequestAsync<JobNonConformityRecordsModel>(`${this.ApiUrl}/jobs/${jobId}/job-non-conformity-records`, data);
    }

    public updateJobNonConformityRecord(jobId: string, data: JobNonConformityRecordsModel) {
        return this.PATCH_RequestAsync<JobNonConformityRecordsModel>(`${this.ApiUrl}/jobs/${jobId}/job-non-conformity-records/${data.id}`, data);
    }

    public deleteJobNonConformityRecord(jobId: string, recordId: string) {
        return this.DELETE_RequestAsync<void>(`${this.ApiUrl}/jobs/${jobId}/job-non-conformity-records/${recordId}`);
    }

    // job-non-conformity-records uploaded-files apis
    public uploadJobNonConformityRecordFile(recordId: string, title: string, file: File) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('title', title);

        return this.POST_FileRequestAsync<UploadedFilesModel>(`${this.ApiUrl}/job-non-conformity-records/${recordId}/uploaded-files`, formData);
    }

    public getJobNonConformityRecordFiles(recordId: string) {
        return this.GET_RequestAsync<Array<UploadedFilesModel>>(`${this.ApiUrl}/job-non-conformity-records/${recordId}/uploaded-files`);
    }

    public deleteJobNonConformityRecordFile(recordId: string, fileId: string) {
        return this.DELETE_RequestAsync<void>(`${this.ApiUrl}/job-non-conformity-records/${recordId}/uploaded-files/${fileId}`);
    }

    public getCorrectiveActionCompletionRegister(jobId: string) {
        return this.GET_RequestAsync<JobCompletionRegisterViewModel>(`${this.ApiUrl}/jobs/${jobId}/corrective-action-completion-register`);
    }

    public saveCorrectiveActionCompletionRegister(jobId: string, data: SaveCompletionRegisterViewModel, file?: File) {
        const formData = new FormData();
        if (data.completionDate) {
            formData.append('completionDate', data.completionDate);
        }
        if (data.personInChargeUserId) {
            formData.append('personInChargeUserId', data.personInChargeUserId);
        }
        if (file) {
            formData.append('file', file);
        }

        return this.POST_FileRequestAsync<void>(`${this.ApiUrl}/jobs/${jobId}/corrective-action-completion-register`, formData);
    }

    public getCorrectiveActionResults(jobId: string) {
        return this.GET_RequestAsync<Array<CorrectiveActionResultViewModel>>(`${this.ApiUrl}/jobs/${jobId}/corrective-action-results`);
    }

    public saveCorrectiveActionResult(jobId: string, data: SaveCorrectiveActionResultViewModel, file: File) {
        const formData = new FormData();
        formData.append('title', data.title!);
        formData.append('file', file);

        return this.POST_FileRequestAsync<void>(`${this.ApiUrl}/jobs/${jobId}/corrective-action-results`, formData);
    }

    public deleteCorrectiveActionResults(jobId: string, resultId: string) {
        return this.DELETE_RequestAsync<void>(`${this.ApiUrl}/jobs/${jobId}/corrective-action-results/${resultId}`);
    }
}
