import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {KeyValuePair, EquipmentSections} from '@/globals';
import {AdminDataFieldService} from '@/views/shared/services';
import {Component} from 'vue-property-decorator';

@Component
export default class EquipmentFieldsDbComponent extends VueWrapper {
    public equipmentSections: Array<KeyValuePair> = [
        {
            key: EquipmentSections.ParentAreaInformation,
            value: 'Parent Area Information',
            active: false,
        },
        {
            key: EquipmentSections.GeneralInformation,
            value: 'General Information',
            active: false,
        },
        {
            key: EquipmentSections.EquipmentClassification,
            value: 'Equipment Certification',
            active: false,
        },
        {
            key: EquipmentSections.WiringCableEntry,
            value: 'Wiring Table Entry',
            active: false,
        },
    ];

    public adminDataFieldsSrv: AdminDataFieldService = new AdminDataFieldService();

    public get getFieldOptions() {
        return this.adminDataFieldsSrv.selectedField ? this.adminDataFieldsSrv.selectedFieldOptions[this.adminDataFieldsSrv.selectedField.id!] : [];
    }

    public getEquipmentFields(key: string) {
        return this.adminDataFieldsSrv.equipmentFields.filter(x => x.section === key && ['select', 'multi-select'].includes(x.type!));
    }
}
