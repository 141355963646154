import {ServiceClass} from '@/decorators';
import {DustDbModel, GasDbModel} from '@/sdk';
import {GasDbApi} from '@/sdk/api-services';
import {DustDbApi} from '@/sdk/api-services/dust-db.api';
import {map} from 'rxjs/operators';

@ServiceClass()
export class DustDbService {
    public dustDbApi: DustDbApi = new DustDbApi();

    public dustDbData: Array<DustDbModel> = [];

    public selectedObj: DustDbModel = new DustDbModel();

    public loadDustDb() {
        return this.dustDbApi
            .getDustDb()
            .pipe(
                map(x => {
                    this.dustDbData = x.Data ?? [];

                    return x;
                })
            )
            .toPromise();
    }

    public uploadFile(file: File) {
        return new Promise((resolve, reject) => {
            this.dustDbApi.uploadFile(file).subscribe(
                res => {
                    resolve(null);
                },
                err => {
                    reject(err);
                }
            );
        });
    }
}
