import {ServiceClass} from '@/decorators';
import {AccountViewModel, SessionModel, SignupViewModel, UserViewModel} from '../models';
import {BaseApi} from './base.api';

@ServiceClass()
export class AutentictionApi extends BaseApi {
    public Login(data: any) {
        return this.POST_Request<SessionModel>(`${this.ApiUrl}/authentication/login`, data);
    }
    public CreateAccount(data: any) {
        return this.POST_Request<SessionModel>(`${this.ApiUrl}/users`, data);
    }

    public SignupAdmin(data: SignupViewModel) {
        return this.POST_RequestAsync<{user: any; account: AccountViewModel}>(`${this.ApiUrl}/authentication/signup-admin`, data);
    }

    public SignupUser(data: SignupViewModel) {
        return this.POST_RequestAsync<UserViewModel>(`${this.ApiUrl}/authentication/signup-user`, data);
    }
}
