import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {SORT_ORDER} from '@/globals';
import {ReportFilter, ReportModel} from '@/sdk';
import {ReportsApi} from '@/sdk/api-services';
import {Component} from 'vue-property-decorator';

@Component
export default class ReportListingComponent extends VueWrapper {
    public reportsApi: ReportsApi = new ReportsApi();

    public filter: ReportFilter = {
        PageLimit: 0,
        SortBy: 'generatedOn',
        SortOrder: SORT_ORDER.DESC,
        CurrentPage: 1,
    };

    public siteId: string = this.$route.params.siteId;

    public reports: Array<ReportModel> = [];
    public reportsCount: number = 0;

    public mounted() {
        this.getReports();
    }

    public async getReports() {
        const reports = await this.reportsApi.getReports(this.siteId, this.filter);

        this.reports = reports?.Data || [];
        this.reportsCount = reports?.Count || 0;
    }

    public downloadReport(report: ReportModel) {
        this.LoaderSrv.showFullScreenLoader('Downloading report...');
        this.reportsApi
            .downloadReport(report.id || '')
            .then((response: any) => {
                const url = window.URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${report.fileName ?? '' + 'area report'}.docx`);
                document.body.appendChild(link);
                link.click();

                //clean
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch((e: any) => {
                this.AlertSrv.show('error', e?.error?.messagge ?? 'Failed to download report.');
            })
            .finally(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    public deleteReport(report: ReportModel) {
        this.ConfirmSrv.open('Delete Report', `Are you sure you want to delete this report ${report.fileName}?`).then(yes => {
            if (yes) {
                this.LoaderSrv.showFullScreenLoader('Deleting report...');
                this.reportsApi
                    .deleteReport(report.id || '')
                    .then(() => {
                        this.AlertSrv.show('success', 'Report deleted successfully');
                        this.getReports();
                    })
                    .catch((e: any) => {
                        this.AlertSrv.show('error', e?.error?.messagge ?? 'Failed to delete report.');
                    })
                    .finally(() => {
                        this.LoaderSrv.hideFullScreenLoader();
                    });
            }
        });
    }
}
