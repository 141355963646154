import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {KeyValuePair, PaymentMethodsList} from '@/globals';
import {SignupInvitationModel, SignupViewModel} from '@/sdk';
import {AutentictionApi} from '@/sdk/api-services';
import {SignupInvitationAppi} from '@/sdk/api-services/signup-invitation.api';
import {Component} from 'vue-property-decorator';

@Component
export default class SignupComponent extends VueWrapper {
    public orgCode: String | null = null;
    public loginId: String | null = null;
    public password: String | null = null;

    public show1: boolean = false;

    public signupInvitationApi: SignupInvitationAppi = new SignupInvitationAppi();

    public periods: Array<KeyValuePair> = [
        {
            key: 'Annual',
            value: 'Annual',
        },
        {
            key: 'Monthly',
            value: 'Monthly',
        },
        {
            key: 'Customize',
            value: 'Customize',
        },
    ];

    public paymentMethods = PaymentMethodsList;
    public authenticationApi: AutentictionApi = new AutentictionApi();

    public isCheckingInvitation: boolean = false;

    public invitation: SignupInvitationModel | null = null;

    public signupData: SignupViewModel = new SignupViewModel();

    public created() {
        this.signupData.invitationCode = this.$route.query.code as string;
        if (this.signupData.invitationCode) {
            this.checkInvitationId();
        }
    }

    public mounted() {
        //
    }

    public localeChange() {
        //
    }

    public signup() {
        if (this.invitation?.type === 'AdminInvitation') {
            this.authenticationApi
                .SignupAdmin(this.signupData)
                .then(res => {
                    this.AlertSrv.show('success', 'You have been signed up successfully!').then(() => {
                        this.$router.push({name: 'signin'});
                    });
                })
                .catch(err => {
                    this.AlertSrv.show('error', err?.error?.message ?? 'Unable to signup. Please try again.');
                });
        } else if (this.invitation?.type === 'UserInvitation') {
            this.authenticationApi
                .SignupUser({...this.signupData, accountId: this.invitation.accountId})
                .then(res => {
                    this.AlertSrv.show('success', 'You have been signed up successfully!').then(() => {
                        this.$router.push({name: 'signin'});
                    });
                })
                .catch(err => {
                    this.AlertSrv.show('error', err?.error?.message ?? 'Unable to signup. Please try again.');
                });
        }
    }

    public get isValidInvitation() {
        return this.invitation && this.invitation.expiresAt && new Date(this.invitation.expiresAt) > new Date();
    }

    public checkInvitationId() {
        this.LoaderSrv.showFullScreenLoader('Checking Invitation...');

        this.isCheckingInvitation = true;
        this.signupInvitationApi
            .checkInvitationByCode(this.signupData.invitationCode!)
            .then(res => {
                this.invitation = res;

                if (!res || (res.expiresAt && new Date(res.expiresAt) < new Date())) {
                    this.AlertSrv.show('error', 'Your invitateion has been expired.');
                } else {
                    this.signupData.email = res.email;
                }
            })
            .catch(err => {
                this.AlertSrv.show('error', 'Invalid or expired invittion.');
            })
            .finally(() => {
                this.isCheckingInvitation = false;
                this.LoaderSrv.hideFullScreenLoader();
            });
    }
}
