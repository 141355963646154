import {ServiceClass} from '@/decorators';
import {AnyObject, AreaSections} from '@/globals';
import {debounceTime} from 'rxjs/operators';
import {AccountViewModel, AssignRoleViewModel, CreateUserViewModel, SiteModel, UserViewModel} from '../models';
import {BaseApi} from './base.api';

@ServiceClass()
export class UsersApi extends BaseApi {
    // public getById(id: string, filter: AnyObject = {}) {
    //     return this.GET_Request<any>(`${this.ApiUrl}/users/${id}?filter=${JSON.stringify(filter)}`);
    // }

    public getUserAccount(userId: string) {
        return this.GET_RequestAsync<AccountViewModel>(`${this.ApiUrl}/users/${userId}/account`);
    }

    public getUserToEdit(id: string) {
        return this.GET_RequestAsync<CreateUserViewModel>(`${this.ApiUrl}/users/${id}/edit`);
    }

    public getUsers(filter: AnyObject = {}) {
        return this.GET_Request<Array<UserViewModel>>(`${this.ApiUrl}/users?filter=${encodeURIComponent(JSON.stringify(filter))}`);
    }

    public Create(data: any) {
        delete data.id;
        delete data.created;
        delete data.updated;
        return this.POST_Request<SiteModel>(`${this.ApiUrl}/users`, data);
    }
    public UpdateMember(id: string, data: any) {
        delete data.id;
        delete data.created;
        delete data.updated;
        return this.PATCH_Request<CreateUserViewModel>(`${this.ApiUrl}/users/${id}`, data);
    }

    public deleteSiteMember(memberId: string) {
        return this.DELETE_Request<CreateUserViewModel>(`${this.ApiUrl}/users/${memberId}`);
    }

    public AssignRoles(data: Array<AssignRoleViewModel>) {
        return this.POST_RequestAsync<any>(`${this.ApiUrl}/users/assign-roles`, data);
    }

    public getUserSiteRoles(userId: string) {
        return this.GET_RequestAsync<Array<AssignRoleViewModel>>(`${this.ApiUrl}/users/${userId}/assigned-roles`);
    }

    public getUserSiteRolesBySite(siteId: string) {
        return this.GET_RequestAsync<Array<AssignRoleViewModel>>(`${this.ApiUrl}/users/site-assigned-roles/${siteId}`);
    }
}
