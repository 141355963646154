import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {Component} from 'vue-property-decorator';
import SubmitJobWorkPermitDialogComponent from '../submit-job-work-permit-dialog/submit-job-work-permit.dialog';
import {JobService, JobSubmittedWorkPermitsService, WorkPermitsService} from '@/views/shared/services';
import {JobSubmitedWorkPermitModel, WorkPermitModel} from '@/sdk';
import {WorkPermitApi} from '@/sdk/api-services';
import {WorkPermitTypes} from '@/globals';

@Component({
    components: {
        SubmitJobWorkPermitDialogComponent,
    },
})
export default class WorkPermitHistoryComponent extends VueWrapper {
    public $refs!: {
        submitWorkPermitDialogRef: SubmitJobWorkPermitDialogComponent;
    };

    public workPermitTab: string | null = null;
    public get workPermits() {
        return WorkPermitTypes;
    }

    public jobsSrv: JobService = new JobService();
    public workPermitsSrv: WorkPermitsService = new WorkPermitsService();
    public jobSubmittedWorkPermitsSrv: JobSubmittedWorkPermitsService = new JobSubmittedWorkPermitsService();

    public workPermitTabChange(e: string) {
        const wp = this.workPermitsSrv.workPermits.find(x => x.id === e);
        if (wp) {
            this.workPermitsSrv.selectedWorkPermit = wp;

            this.LoaderSrv.showFullScreenLoader();
            this.jobSubmittedWorkPermitsSrv
                .loadWorkPermits(this.jobsSrv.selectedJob!.id!, wp.type! as any)
                .then(() => {
                    //
                })
                .catch(err => {
                    this.AlertSrv.show('warning', err.message || err.error?.message || 'Unable to load work permits.');
                })
                .finally(() => {
                    this.LoaderSrv.hideFullScreenLoader();
                });
        }
    }

    public downloadWorkPermitFiel(wp: WorkPermitModel) {
        this.LoaderSrv.showFullScreenLoader();
        new WorkPermitApi()
            .downloadFile(wp.id!)
            .then(res => {
                const href = URL.createObjectURL(res.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', wp.filename!); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch(err => {
                this.AlertSrv.show('error', err.message || err.error?.message || 'Unable to download file.');
            })
            .finally(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    public submitWorkPermit(wp: WorkPermitModel) {
        this.$refs.submitWorkPermitDialogRef.open();
    }

    public downloadSubmittedWorkPermit(submitedWorkPermit: JobSubmitedWorkPermitModel) {
        if (!this.jobsSrv.selectedJob) {
            return;
        }
        this.LoaderSrv.showFullScreenLoader();
        this.jobSubmittedWorkPermitsSrv.jobSubbmittedWorkPermitsApi
            .downloadSubmitedWorkPermit(this.jobsSrv.selectedJob.id!, submitedWorkPermit.id!)
            .then(res => {
                const href = URL.createObjectURL(res.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', submitedWorkPermit.filename!); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch(err => {
                this.AlertSrv.show('error', err.message || err.error?.message || 'Unable to download file.');
            })
            .finally(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    public deleteSubmittedWorkPermit(submitedWorkPermit: JobSubmitedWorkPermitModel) {
        if (!this.jobsSrv.selectedJob) {
            return;
        }

        this.ConfirmSrv.open('Delete Work Permit', 'Are you sure you want to delete this submitted work permit?').then(yes => {
            if (yes) {
                this.LoaderSrv.showFullScreenLoader();
                this.jobSubmittedWorkPermitsSrv.jobSubbmittedWorkPermitsApi
                    .deleteSubmitedWorkPermit(this.jobsSrv.selectedJob!.id!, submitedWorkPermit.id!)
                    .then(() => {
                        this.AlertSrv.show('success', 'Work permit deleted successfully.');

                        this.workPermitTabChange(this.workPermitTab!);
                    })
                    .catch(err => {
                        this.AlertSrv.show('error', err.message || err.error?.message || 'Unable to delete work permit.');
                    })
                    .finally(() => {
                        this.LoaderSrv.hideFullScreenLoader();
                    });
            }
        });
    }

    public workPermitSubmitted() {
        this.workPermitTabChange(this.workPermitTab!);
    }
}
