import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#1b3054',
                secondary: '#26AF5F',
                accent: '#109144',
                error: '#f44336',
                warning: '#ffc107',
                info: '#00bcd4',
                success: '#4caf50',
                body: '#e4e4e4',
                white: '#ffffff',
                link: '#8ea0bc',
                yellow: 'feb702',
                lightgrey: '#7f7f7f',
                darkGrey: '#2c2c2c',
                light_primary: '#00b3ff',
                light_secondary: '#04dd9d',
                light_accent: '#feb702',
                accent1: {
                    base: '#e1e1e1',
                    darken1: '#ffffff',
                    darken2: '#ffffff',
                    darken3: '#ffffff',
                    darken4: '#ffffff',
                },
                'admin-primary': '#1B3054',
                'admin-light-grey': '#EDEEF1',
            },
        },
    },
    // breakpoints: {
    //     xs: {
    //         min: 0,
    //         max: 5,
    //     },
    //     sm: {
    //         min: 6,
    //         max: 7,
    //     },
    //     md: {
    //         min: 7,
    //     },
    //     lg: {
    //         min: 7,
    //     },
    //     xl: {
    //         min: 7,
    //     },
    // },
});
