import AdminLayoutComponent from '@/components/layouts/admin-layout/admin-layout.component';
import {RouteConfig} from 'vue-router';
import FieldsDbComponent from './fields-db/fields-db.component';
import GasDbComponent from './gas-db/gas-db.component';
import DustDbComponent from './dust-db/dust-db.component';
import AccountSetupComponent from './account-setup/account-setup.component';
import AccountUsersSetupComponent from './account-setup/account-users-setup/account-users-setup.component';

export const AdminPanelRoutes: Array<RouteConfig> = [
    {
        path: '/admin-panel',
        name: 'admin-panel',
        component: AdminLayoutComponent,
        children: [
            {
                path: '',
                redirect: {name: 'fields-db'},
            },
            {
                path: 'fields-db',
                name: 'fields-db',
                component: FieldsDbComponent,
            },
            {
                path: 'gas-db',
                name: 'gas-db',
                component: GasDbComponent,
            },
            {
                path: 'dust-db',
                name: 'dust-db',
                component: DustDbComponent,
            },
            {
                path: 'account-setup',
                name: 'account-setup',
                component: AccountSetupComponent,
            },
            {
                path: 'account-setup/:id/users',
                name: 'account-users',
                component: AccountUsersSetupComponent,
            },
            {
                path: 'inspection-forms-db',
                name: 'inspection-forms-db',
                component: () => import('./inspection-forms-db/inspection-forms-db.component.vue'),
                children: [
                    {
                        path: 'inspection-form-setup/:id',
                        name: 'inspection-form-setup',
                        component: () => import('./inspection-forms-db/inspection-form-setup/inspection-form-setup.component.vue'),
                    },
                ],
            },
        ],
    },
];
