import {Component, Prop} from 'vue-property-decorator';
import {ValidationProvider} from 'vee-validate';
import BaseInput from '../Input/base-input';

@Component({
    components: {
        ValidationProvider,
    },
})
export default class BaseAutocomplete extends BaseInput {
    @Prop({
        type: Number,
        default: 0,
    })
    public limit!: number;

    public limiter(e: any) {
        // console.log('limiter', e, this.limit, this.$attrs['multiple']);
        if (this.limit > 0 && (this.$attrs['multiple'] as any) === true) {
            if (e.length > this.limit) {
                e.pop();
            }
        }

        this.$emit('update:value', !!e ? e : null);
    }

    getErrorMessage(error: string) {
        const msg = this.$attrs['error-messages'];

        if (msg?.length) {
            return msg;
        } else {
            return error;
        }
    }
}
