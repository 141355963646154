import {ServiceClass} from '@/decorators';
import {AnyObject} from '@/globals';
import {debounceTime} from 'rxjs/operators';
import {AccountViewModel, CreateUserViewModel, SiteModel, UserViewModel} from '../models';
import {BaseApi} from './base.api';

@ServiceClass()
export class AccountsApi extends BaseApi {
    public getAccountById(id: string) {
        return this.GET_RequestAsync<AccountViewModel>(`${this.ApiUrl}/accounts/${id}`);
    }

    public getAccounts(filter: AnyObject = {}) {
        return this.GET_RequestAsync<Array<AccountViewModel>>(`${this.ApiUrl}/accounts?filter=${encodeURIComponent(JSON.stringify(filter))}`);
    }

    public Create(data: AccountViewModel) {
        delete data.id;
        delete data.created;
        delete data.updated;

        data.accountStartDate = data.accountStartDate ? new Date(data.accountStartDate).toISOString() : null;
        data.accountEndDate = data.accountEndDate ? new Date(data.accountEndDate).toISOString() : null;

        data.licenseStartDate = data.licenseStartDate ? new Date(data.licenseStartDate).toISOString() : null;
        data.licenseEndDate = data.licenseEndDate ? new Date(data.licenseEndDate).toISOString() : null;

        return this.POST_RequestAsync<AccountViewModel>(`${this.ApiUrl}/accounts`, data);
    }
    public UpdateAccount(id: string, data: any) {
        delete data.id;
        delete data.created;
        delete data.updated;

        data.accountStartDate = data.accountStartDate ? new Date(data.accountStartDate).toISOString() : null;
        data.accountEndDate = data.accountEndDate ? new Date(data.accountEndDate).toISOString() : null;

        data.licenseStartDate = data.licenseStartDate ? new Date(data.licenseStartDate).toISOString() : null;
        data.licenseEndDate = data.licenseEndDate ? new Date(data.licenseEndDate).toISOString() : null;

        return this.PATCH_RequestAsync<void>(`${this.ApiUrl}/accounts/${id}`, data);
    }

    public deleteAccount(accountId: string) {
        return this.DELETE_RequestAsync<void>(`${this.ApiUrl}/accounts/${accountId}`);
    }

    public getAccountUsers(accountId: string, filter: AnyObject = {}) {
        return this.GET_RequestAsync<Array<UserViewModel>>(`${this.ApiUrl}/accounts/${accountId}/users?filter=${encodeURIComponent(JSON.stringify(filter))}`);
    }
}
