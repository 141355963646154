import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {JobApi} from '@/sdk/api-services/job.api';
import {Component} from 'vue-property-decorator';
import {SiteTeamMembersService} from '../../../shared/services';
import {JobService} from '../../../shared/services/job.service';

@Component
export default class JobLinkWorkerComponent extends VueWrapper {
    public jobsSrv: JobService = new JobService();
    public siteTeamMembersSrv: SiteTeamMembersService = new SiteTeamMembersService();

    public jobsApi: JobApi = new JobApi();

    public isInspectorLinked(inspectorId: string) {
        return !!this.jobsSrv.jobWorkers.find(x => x.id === inspectorId);
    }

    public unlinkInspector(jobId: string, inspectorId: string) {
        this.ConfirmSrv.open('Unlink Worker', 'Are you sure you want to unlink worker from this job?').then(yes => {
            if (yes) {
                this.jobsApi.unlinkJobWorkers(jobId, inspectorId).subscribe(
                    res => {
                        if (res.Status) {
                            this.AlertSrv.show('success', 'Worker has been unlinked successfully!');
                            this.jobsSrv.getJobWorkers(jobId);
                        }
                    },
                    err => {
                        this.AlertSrv.show('error', err.message || 'Unable to unlink worker');
                    }
                );
            }
        });
    }

    public linkInspector(jobId: string, inspectorId: string) {
        this.ConfirmSrv.open('Link Worker', 'Are you sure you want to link worker to this job?').then(yes => {
            if (yes) {
                this.jobsApi.linkJobWorker(jobId, inspectorId).subscribe(
                    res => {
                        if (res.Status) {
                            this.AlertSrv.show('success', 'Worker has been linked successfully!');
                            this.jobsSrv.getJobWorkers(jobId);
                        }
                    },
                    err => {
                        this.AlertSrv.show('error', err.message || 'Unable to link worker');
                    }
                );
            }
        });
    }
}
