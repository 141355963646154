import VueWrapper from '@/components/core/Vue/vue.wrapper';
import BaseFormComponent from '@/components/vuetify/Form/base-form';
import {SignupInvitationAppi} from '@/sdk/api-services/signup-invitation.api';
import {AxiosError} from 'axios';
import {Component} from 'vue-property-decorator';

@Component
export default class InviteClientAdminDialogComponent extends VueWrapper {
    public readonly name = 'invite-client-admin-dialog';
    public $refs!: {
        form: BaseFormComponent;
    };

    public email: string | null = null;

    public signupInvitationApi: SignupInvitationAppi = new SignupInvitationAppi();

    public async isvalidForm() {
        return await this.$refs?.form?.validate();
    }

    public invite() {
        if (this.email) {
            this.signupInvitationApi
                .sendAdminInvitation(this.email)
                .then(() => {
                    this.close();
                    this.AlertSrv.show('success', 'Invitation has been sent successfully!');
                })
                .catch((error: any) => {
                    console.log(error);
                    this.AlertSrv.show('error', error?.error?.message ?? 'Unable to send invitation. Please try again later.');
                });
        }
    }

    public open() {
        this.email = null;
        this.$refs.form?.reset();
        this.CoreSrv.OpenModal(this.name);
    }

    close() {
        this.CoreSrv.CloseModal(this.name);
    }
}
