export class SwitchBoardModel {
    id?: string | null = null;
    outpulPhase?: string | null = null;
    name?: string | null = null;
    outputVoltage?: string | null = null;
    outputFrequency?: string | null = null;
    connectWith?: string | null = null;
    connectWithMid?: string | null = null;
    connectDirection?: string | null = null;
    sequence?: number | 0 = 0;

    created?: string | null = null;
    updated?: string | null = null;
    locationId?: string | null = null;
    finalMidArr?: Array<any> | [] = [];

    constructor(data?: Partial<SwitchBoardModel>) {
        Object.assign(this, data);
    }
}
