import {Component, Prop} from 'vue-property-decorator';
import {ValidationProvider} from 'vee-validate';
import BaseInput from '../Input/base-input';

@Component({
    components: {
        ValidationProvider,
    },
})
export default class BaseDatePickerComponent extends BaseInput {
    @Prop({
        default: () => ({
            label: '',
        }),
        type: Object,
    })
    fieldProps!: object;

    dialog = false;

    @Prop({
        type: Boolean,
    })
    private readonly format?: boolean;

    @Prop({
        type: String,
    })
    private readonly formatStr?: string;

    get DateValue() {
        const oldVal = this.value ?? '';

        if (this.format) {
            return oldVal ? this.$helpers.formatDate(this.value as any, this.formatStr ?? 'MM/dd/yyy') : null;
        }
        // if (oldVal.length <= 10) {
        //     oldVal = `${oldVal} 00:00:00`;
        // }
        console.log(this.value);
        // if (!!oldVal) {
        //     return new Date(oldVal);
        // }
        return oldVal;
    }
}
