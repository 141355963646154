import {ServiceClass} from '@/decorators';
import {BaseApi} from './base.api';
import {SignupInvitationModel} from '../models';

@ServiceClass()
export class SignupInvitationAppi extends BaseApi {
    public sendAdminInvitation(email: string) {
        return this.POST_RequestAsync<SignupInvitationModel>(`${this.ApiUrl}/signup-invitations/invite-account-admin`, {email});
    }

    public sendUserInvitation(email: string, accountId: string) {
        return this.POST_RequestAsync<SignupInvitationModel>(`${this.ApiUrl}/signup-invitations/invite-account-user/${accountId}`, {email});
    }

    public checkInvitationByCode(invitationCode: string) {
        return this.GET_RequestAsync<SignupInvitationModel | null>(`${this.ApiUrl}/signup-invitations/${invitationCode}/check-invitation`);
    }
}
