import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {AnyObject, KeyValuePair, makeSlug} from '@/globals';
import {Component, Prop} from 'vue-property-decorator';
import SiteSetupAreaBrowserComponent from '../../client/site-setup/site-setup-area-browser/site-setup-area-browser.component';

import {ReportsApi, SitesApi} from '@/sdk/api-services';
import {JobModel, LocationModel, ProjectModel, SiteModel, UserSession} from '@/sdk';

import {SiteLocationService} from '@/views/shared/services/site-location.service';

import {LocationApi} from '@/sdk/api-services/location.api';
import {JobLocationService, JobSubmittedWorkPermitsService, SiteProjectsService, SiteTeamMembersService} from '@/views/shared/services';
import JobReportAreaBrowserComponent from '../job-report-area-browser/job-report-area-browser.component';
import {JobService} from '@/views/shared/services/job.service';
import {JobApi} from '@/sdk/api-services/job.api';
import ProjectSelectionDialogComponent from '@/views/shared/project-selection-dialog/project-selection-dialog.component';
import JobReportFilterComponent from '../job-report-filter/job-report-filter.component';
import ReportListingComponent from '../report-listing/report-listing.component';

@Component({
    name: 'JobSetupMainComponent',
    components: {
        SiteSetupAreaBrowserComponent,
        JobReportAreaBrowserComponent,

        ProjectSelectionDialogComponent,

        JobReportFilterComponent,
        ReportListingComponent,
    },
})
export default class JobReportMainComponent extends VueWrapper {
    public $refs!: {
        areaBrowserElem: HTMLDivElement;
        jobListElem: HTMLDivElement;
        jobListParentElem: HTMLDivElement;
        projectSelectionDialogRef: ProjectSelectionDialogComponent;
        reportListingRef: ReportListingComponent;
    };

    public siteToEdit: SiteModel = new SiteModel();

    public siteId: string | null = null;

    public jobLocationsSrv: JobLocationService = new JobLocationService();
    public siteLocationsSrv: SiteLocationService = new SiteLocationService();
    public siteProjectsSrv: SiteProjectsService = new SiteProjectsService();
    public reportsApi: ReportsApi = new ReportsApi();

    public siteTeamMembersSrv: SiteTeamMembersService = new SiteTeamMembersService();
    public jobService: JobService = new JobService();

    public locationPath: Array<{id: string; title: string; type: string}> = [];

    public value: any = '';
    public events: any = [];
    public colors: any = ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'];
    public names: any = ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'];

    //INPUTS
    titleInput: String | null = null;
    typeInput: String | null = null;
    areaInput: String | null = null;
    subAreaInput: String | null = null;
    flammableInput: String | null = null;
    explosionInput: String | null = null;
    ipxInput: String | null = null;
    ipyInput: String | null = null;
    ambTempInput: String | null = null;
    inspFrequencyInput: String | null = null;
    approvalTypeInput: String | null = null;
    gasZoneInput: String | null = null;
    gasGroupInput: String | null = null;
    tempClassInput: String | null = null;
    dustZoneInput: String | null = null;
    dustGroupInput: String | null = null;
    dustIgnitionSelectionInput: String | null = null;
    dustIgnitionInput: String | null = null;
    envCondInput: String | null = null;

    /////////////////////////////////////////////
    public classification: string | null = null;
    public equipmentTypeSelected: string | null = null;

    public startX: number | null = null;
    public startY: number | null = null;
    public startWidth: number | null = null;
    public startHeight: number | null = null;

    public onBrowserMouseDown(e: any) {
        this.startX = e.clientX;
        this.startY = e.clientY;
        this.startWidth = parseInt(document.defaultView?.getComputedStyle(this.$refs.areaBrowserElem)?.width ?? '0', 10);
        // this.startHeight = parseInt(document.defaultView?.getComputedStyle(this.$refs.areaBrowserElem)?.height ?? '0', 10);

        document.documentElement.addEventListener('mousemove', this.doDrag, false);
        document.documentElement.addEventListener('mouseup', this.stopDrag, false);
    }

    doDrag(e: any) {
        this.$refs.areaBrowserElem.style.width = `${this.startWidth + e.clientX - (this.startX ?? 0)}px`;
        // this.$refs.areaBrowserElem.style.height = `${this.startHeight + e.clientY - (this.startY ?? 0)}`;
    }

    stopDrag(e: any) {
        document.documentElement.removeEventListener('mousemove', this.doDrag, false);
        document.documentElement.removeEventListener('mouseup', this.stopDrag, false);
    }

    public startX1: number | null = null;
    public startY1: number | null = null;
    public startWidth1: number | null = null;
    public jobListElemHeight: number | null = null;

    public parentHeight: number | null = null;
    public currentHeight: number | null = null;

    public onListMouseDown(e: any) {
        this.startX1 = e?.clientX ?? 0;
        this.startY1 = e?.clientY ?? 0;
        // this.startWidth1 = parseInt(document.defaultView?.getComputedStyle(this.$refs.jobListElem)?.width ?? '0', 10);
        this.jobListElemHeight = parseInt(document.defaultView?.getComputedStyle(this.$refs.jobListElem)?.height ?? '0', 10);

        // this.parentHeight = parseInt(document.defaultView?.getComputedStyle(this.$refs.jobListParentElem)?.height ?? '0', 10);

        document.documentElement.addEventListener('mousemove', this.doDrag1, false);
        document.documentElement.addEventListener('mouseup', this.stopDrag1, false);
    }

    doDrag1(e: any) {
        // this.$refs.jobListElem.style.width = `${this.startWidth1 + e.clientX - (this.startX1 ?? 0)}px`;
        this.currentHeight = this.jobListElemHeight + e.clientY - (this.startY1 ?? 0);
        this.$refs.jobListElem.style.height = `${this.currentHeight}px`;
    }

    stopDrag1(e: any) {
        document.documentElement.removeEventListener('mousemove', this.doDrag1, false);
        document.documentElement.removeEventListener('mouseup', this.stopDrag1, false);
    }

    public inilizeHeights() {
        this.parentHeight = parseInt(document.defaultView?.getComputedStyle(this.$refs.jobListParentElem)?.height ?? '0', 10);
        if (this.parentHeight > 10) {
            this.parentHeight = this.parentHeight - 4;
        }

        this.jobListElemHeight = this.parentHeight / 4 - 6;
        this.currentHeight = this.jobListElemHeight;
    }

    public get equipmentTypes(): Array<any> {
        if (this.siteLocationsSrv.selectedLcoation?.equipmentDetail?.equipmentClassification) {
            return this.$formOptions.equipmentTypesList[this.siteLocationsSrv.selectedLcoation!.equipmentDetail!.equipmentClassification as any] as Array<any>;
        }
        return [];
    }

    // Change to protectionTechniquesSubDYD
    public get protectionTechniquesDYD(): Array<any> {
        if (this.siteLocationsSrv.selectedLcoation?.equipmentDetail?.protectionTechnique) {
            return this.$formOptions.protectionTechniquesDYDList[
                this.siteLocationsSrv.selectedLcoation!.equipmentDetail!.protectionTechnique as any
            ] as Array<any>;
        }
        return [];
    }

    public mouseDrag: boolean = false;

    public userSession: UserSession = new UserSession();

    public created() {
        this.siteId = this.$route.params['siteId'];
    }
    public async mounted() {
        this.siteProjectsSrv.reset();
        this.jobService.reset();

        this.inilizeHeights();

        // this.inspectorService.getInspectors(this.siteId);
        this.siteTeamMembersSrv.getSiteWorkers(this.siteId);
        this.siteTeamMembersSrv.getJobManagers(this.siteId);
        // this.siteTeamMembersSrv.getSafetyManagers(this.siteId);
        // this.siteTeamMembersSrv.getSafetyOfficers(this.siteId);
        // this.siteTeamMembersSrv.getOperationManagers(this.siteId);

        this.getSiteToEdit(this.siteId!);

        // this.jobLocationsSrv.getSiteLocations(this.siteId!);
        // this.jobLocationsSrv.selectedLcoation = null;

        //call jobs
        // this.getJobsDB();
    }

    public getSiteToEdit(id: string) {
        this.LoaderSrv.showFullScreenLoader();
        new SitesApi()
            .getSiteById(id)
            .subscribe(
                res => {
                    this.siteToEdit = res.Data ?? new SiteModel();
                    // this.jobLocationsSrv.site = this.siteToEdit;
                },
                err => {
                    this.AlertSrv.show('error', err.message ?? err.Message ?? 'Unable to load site.');
                }
            )
            .add(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    getEventColor(event: any) {
        return event.color;
    }

    public generateAreaReport() {
        if (this.siteId) {
            this.LoaderSrv.showFullScreenLoader('Generating report...');
            this.reportsApi
                .generateAreaReport(this.siteId!)
                .then(res => {
                    this.AlertSrv.show('success', 'Report generated successfully.');
                    // const href = URL.createObjectURL(res.data);

                    // // create "a" HTML element with href to file & click
                    // const link = document.createElement('a');
                    // link.href = href;
                    // link.setAttribute('download', `${makeSlug(this.siteToEdit?.siteName ?? '' + 'area report')}.docx`); //or any other extension
                    // document.body.appendChild(link);
                    // link.click();

                    // // clean up "a" element & remove ObjectURL
                    // document.body.removeChild(link);
                    // URL.revokeObjectURL(href);

                    this.$refs.reportListingRef?.getReports();
                })
                .catch(err => {
                    this.AlertSrv.show('error', err.message ?? 'Unable to download report.');
                })
                .finally(() => {
                    this.LoaderSrv.hideFullScreenLoader();
                });
        }
    }
    public generateEquipmentReport() {
        if (this.siteId) {
            this.LoaderSrv.showFullScreenLoader('Generating equipment report...');
            this.reportsApi
                .generateEquipmentReport(this.siteId!, true)
                .then(res => {
                    this.AlertSrv.show('success', 'Equipment Report generated successfully.');

                    this.$refs.reportListingRef?.getReports();
                })
                .catch(err => {
                    this.AlertSrv.show('error', err.message ?? 'Unable to download report.');
                })
                .finally(() => {
                    this.LoaderSrv.hideFullScreenLoader();
                });
        }
    }

    public generateInspectionReportExd() {
        if (this.siteId) {
            this.LoaderSrv.showFullScreenLoader('Generating inspection report (Ex d)...');
            this.reportsApi
                .generateInspectionReportExd(this.siteId!, true)
                .then(res => {
                    this.AlertSrv.show('success', 'Inspection Report (Ex d) generated successfully.');

                    this.$refs.reportListingRef?.getReports();
                })
                .catch(err => {
                    this.AlertSrv.show('error', err.message ?? 'Unable to download report.');
                })
                .finally(() => {
                    this.LoaderSrv.hideFullScreenLoader();
                });
        }
    }

    public generateInspectionReportExi() {
        if (this.siteId) {
            this.LoaderSrv.showFullScreenLoader('Generating inspection report (Ex i)...');
            this.reportsApi
                .generateInspectionReportExi(this.siteId!, true)
                .then(res => {
                    this.AlertSrv.show('success', 'Inspection Report (Ex i) generated successfully.');

                    this.$refs.reportListingRef?.getReports();
                })
                .catch(err => {
                    this.AlertSrv.show('error', err.message ?? 'Unable to download report.');
                })
                .finally(() => {
                    this.LoaderSrv.hideFullScreenLoader();
                });
        }
    }

    public generateInspectionReportExp() {
        if (this.siteId) {
            this.LoaderSrv.showFullScreenLoader('Generating inspection report (Ex p)...');
            this.reportsApi
                .generateInspectionReportExp(this.siteId!, true)
                .then(res => {
                    this.AlertSrv.show('success', 'Inspection Report (Ex p) generated successfully.');

                    this.$refs.reportListingRef?.getReports();
                })
                .catch(err => {
                    this.AlertSrv.show('error', err.message ?? 'Unable to download report.');
                })
                .finally(() => {
                    this.LoaderSrv.hideFullScreenLoader();
                });
        }
    }
}
