import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {Component} from 'vue-property-decorator';
// import SiteSetupAreaBrowserComponent from '../../site-setup-area-browser/site-setup-area-browser.component';
// import SiteSetupHeaderComponent from '../../site-setup-header/site-setup-header.component';
// import SiteSetupButtonBarComponent from '../../site-setup-button-bar/site-setup-button-bar.component';
import ClientAppBarComponent from '@/components/vuetify/client-app-bar/client-app-bar.component';
import JobSetupMainComponent from '../job-setup-main/job-setup-main.component';
import SiteSetupButtonBarComponent from '@/views/client/site-setup/site-setup-button-bar/site-setup-button-bar.component';

@Component({
    components: {
        ClientAppBarComponent,
        JobSetupMainComponent,
        SiteSetupButtonBarComponent,
    },
})
export default class JobSetupLayoutComponent extends VueWrapper {}
