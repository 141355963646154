import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {Component, Prop} from 'vue-property-decorator';
import {SiteProjectsService} from '../services';

@Component
export default class AddSiteProjectDialogComponent extends VueWrapper {
    public readonly name: string = 'add-site-project-dialog';
    public siteProjectsSrv: SiteProjectsService = new SiteProjectsService();

    @Prop({
        type: String,
        required: true,
    })
    public readonly siteId!: string;

    public mounted() {
        //
    }

    public open() {
        this.CoreSrv.OpenModal(this.name);
    }
    public close() {
        this.CoreSrv.CloseModal(this.name);
    }
}
