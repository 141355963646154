export function parseJwt<T>(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );

    return JSON.parse(jsonPayload) as T;
}

export function makeStr(len: number, char: string) {
    return Array.prototype.join.call({length: (len || -1) + 1}, char || 'x');
}

export function scrollToTop() {
    setTimeout(() => {
        const main = document.getElementsByTagName('main');
        if (main && main[0]) {
            main[0].scrollTo(0, 0);
        }
    }, 100);
}

export function imageToBase64(image: File) {
    return new Promise((resolve: (base64: string) => void, reject) => {
        if (image) {
            const reader = new FileReader();
            reader.onload = e => {
                resolve((e.target as any).result);
            };
            reader.readAsDataURL(image);
        } else {
            resolve('');
        }
    });
}

export function makeid(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

export function makeSlug(str: string) {
    return str
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');
}

export function PaginationSkipCounter(filter: any): number {
    return !filter ? 0 : (filter.CurrentPage - 1) * filter.PageLimit;
}
