import {ServiceClass} from '@/decorators';
import {WorkPermitModel} from '@/sdk';
import {WorkPermitApi} from '@/sdk/api-services';

@ServiceClass()
export class WorkPermitsService {
    public workPermitApi: WorkPermitApi = new WorkPermitApi();

    public workPermits: Array<WorkPermitModel> = [];

    public selectedWorkPermit: WorkPermitModel | null = null;

    public async getWorkPermits() {
        this.workPermits = await this.workPermitApi.getWorkPermits({
            order: ['sequence'],
        });

        return this.workPermits;
    }
}
