import Vue from 'vue';

import AppComponent from './app.component';
import {filters} from './filters';
import vuetify from './plugins/vuetify';
import router from './router';
import store from '@/store';
Vue.config.productionTip = false;

import './plugins';
import './components';
import './options';

new Vue({
    router,
    // store,
    vuetify,
    filters,
    render: h => h(AppComponent),
}).$mount('#app');
