import {ServiceClass} from '@/decorators';
import {AccountViewModel, AlertService, LoaderService} from '@/sdk';
import {AccountsApi} from '@/sdk/api-services';
import {throwError} from 'rxjs';
import {map} from 'rxjs/operators';

@ServiceClass()
export class AccountSetupService {
    public accounts: Array<AccountViewModel> = [];

    public accountsApi: AccountsApi = new AccountsApi();

    public account: AccountViewModel = new AccountViewModel();

    public alerSrv: AlertService = new AlertService();
    public loaderSrv: LoaderService = new LoaderService();

    public loadAccounts() {
        return this.accountsApi.getAccounts().then(x => {
            return (this.accounts = x ?? []);
        });
    }

    public getAccountById(id: string) {
        return this.accountsApi.getAccountById(id).then(x => {
            if (x) {
                return (this.account = new AccountViewModel(x));
            }

            return throwError('Account not found');
        });
    }

    public async save() {
        this.loaderSrv.showFullScreenLoader();
        try {
            if (this.account.id) {
                const res = await this.accountsApi.UpdateAccount(this.account.id, this.account);
                this.alerSrv.show('success', 'Account has been saved successfully!');

                this.loadAccounts()
                    .then(() => {})
                    .catch(e => {});
                return true;
            } else {
                const res = await this.accountsApi.Create(this.account);
                this.alerSrv.show('success', 'Account has been saved successfully!');

                this.loadAccounts()
                    .then(() => {})
                    .catch(e => {});
                return true;
            }
        } catch (error) {
            this.alerSrv.show('error', 'Unable to save account.');
            this.loaderSrv.hideFullScreenLoader();

            return false;
        } finally {
            this.loaderSrv.hideFullScreenLoader();
        }
    }
    public reset() {
        this.account = new AccountViewModel();
    }
}
