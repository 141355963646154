import {ServiceClass} from '@/decorators';
import {BaseApi} from './base.api';
import {GasDbModel} from '../models';

@ServiceClass()
export class GasDbApi extends BaseApi {
    public uploadFile(file: File) {
        const formData = new FormData();
        formData.append('file', file);

        return this.POST_FileRequest(`${this.ApiUrl}/gas-db/upload-file`, formData);
    }

    public getGasDb() {
        return this.GET_Request<Array<GasDbModel>>(`${this.ApiUrl}/gas-db`);
    }
}
