import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {LocationModel, SiteModel} from '@/sdk';

import LocationListItemComponent from '@/views/shared/location-list-item/location-list-item.component';
import {JobLocationService} from '@/views/shared/services';

import {Component, Prop} from 'vue-property-decorator';
import draggable from 'vuedraggable';

@Component({
    name: 'JobReportAreaBrowserComponent',
    components: {
        draggable,
        LocationListItemComponent,
    },
})
export default class JobReportAreaBrowserComponent extends VueWrapper {
    @Prop({
        type: Object,
    })
    public site!: SiteModel;

    @Prop({
        type: Array,
    })
    public siteLocations!: Array<LocationModel>;

    public jobLocationsSrv: JobLocationService = new JobLocationService();

    public mounted() {
        //
    }

    public allowDrop(ev: any) {
        ev.preventDefault();

        this.jobLocationsSrv.enterItemSiteId = ev.target.closest('.site-box')?.id;
    }

    public onDragEnter(event: any) {
        event.preventDefault();
        this.jobLocationsSrv.enterItemSiteId = event?.target?.id;
        console.log('enter: ', event?.target?.id);
    }

    public onItemDrop(event: any) {
        event.preventDefault();
        this.jobLocationsSrv.enterItemSiteId = null;
    }
}
