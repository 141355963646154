import {ServiceClass} from '@/decorators';
import {BaseApi} from './base.api';
import {AnyObject} from '@/globals';
import {JobSubmitedWorkPermitModel} from '../models';

@ServiceClass()
export class JobSubmitedWorkPermitsApi extends BaseApi {
    public async getSubmitedWorkPermits(jobId: string, workPermitType: string, filter: AnyObject = {}) {
        return this.GET_RequestAsync<Array<JobSubmitedWorkPermitModel>>(
            `${this.ApiUrl}/jobs/${jobId}/job-submitted-work-permits/${workPermitType}?filter=${encodeURIComponent(JSON.stringify(filter))}`
        );
    }

    public async deleteSubmitedWorkPermit(jobId: string, id: string) {
        return this.DELETE_RequestAsync(`${this.ApiUrl}/jobs/${jobId}/job-submitted-work-permits/${id}`);
    }

    public async submitWorkPermit(jobId: string, workPermitId: string, file: File) {
        const formData = new FormData();
        formData.append('file', file);
        // if (data.submittedByUserId) {
        //     formData.append('submittedByUserId', data.submittedByUserId);
        // }
        // if (data.submitedOn) {
        //     formData.append('submitedOn', data.submitedOn);
        // }

        return this.POST_FileRequestAsync(`${this.ApiUrl}/jobs/${jobId}/job-submitted-work-permits/${workPermitId}`, formData);
    }

    public downloadSubmitedWorkPermit(jobId: string, id: string) {
        return this.downloadRequest<any>(`${this.ApiUrl}/jobs/${jobId}/job-submitted-work-permits/${id}/download-file`);
    }
}
