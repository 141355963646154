export class JobNonConformityRecordsModel {
    id?: string | null = null;
    type?: string | null = null;
    faultCode?: string | null = null;
    item?: string | null = null;
    result?: string | null = null;
    comment?: string | null = null;

    jobId?: string | null = null;
    created?: Date | null = null;
    updated?: Date | null = null;

    editable?: boolean = false;

    constructor(data?: Partial<JobNonConformityRecordsModel>) {
        Object.assign(this, data);
    }
}
