import {LocationModel} from '../location/location.model';

export class JobModel {
    id?: string | null = null;
    siteId?: string | null = null;
    eqClass?: string | null = null;
    eqType?: string | null = null;
    eqPath?: string | null = null;
    status?: string | null = null;
    inspectorId?: string | null = null;
    locationId?: string | null = null;
    projectId?: string | null = null;
    sequence?: number | null = null;

    startDate?: Date | null = null;
    endDate?: Date | null = null;

    created?: Date | null = null;
    updated?: Date | null = null;

    gradeOfInspection: Array<string> = [];

    jobType?: string | null = null;
    typeOfInspection?: string | null = null;
    nonConformity?: string | null = null;
    actionPlanDetail?: string | null = null;
    actionType?: string | null = null;
    causeOfFault?: string | null = null;
    faultLevel?: string | null = null;
    actionPriority?: number | null = null;

    completedPercentage?: number | null = null;
    daysLeft?: number | null = null;

    location?: LocationModel;

    [key: string]: any;

    constructor(data?: Partial<JobModel>) {
        Object.assign(this, data);
    }
}
