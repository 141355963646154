export class AssignRoleViewModel {
    public userId: string | null = null;
    public siteId: string | null = null;
    public siteRoleId: string | null = null;
    public teamRoleId: string | null = null;
    public jobRoleId: string | null = null;

    public siteUserRoleId: string | null = null;
    public teamUserRoleId: string | null = null;
    public jobUserRoleId: string | null = null;

    constructor(data?: Partial<AssignRoleViewModel>) {
        Object.assign(this, data);
    }
}
