import { render, staticRenderFns } from "./diagram-view-main.component.vue?vue&type=template&id=7f5ae6ad&scoped=true"
import script from "./diagram-view-main.component.ts?vue&type=script&lang=ts&external"
export * from "./diagram-view-main.component.ts?vue&type=script&lang=ts&external"
import style0 from "./diagram-view-main.component.vue?vue&type=style&index=0&id=7f5ae6ad&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f5ae6ad",
  null
  
)

export default component.exports