import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {debounceTime} from 'rxjs/operators';
import {Component} from 'vue-property-decorator';
import ConfirmComponent from './components/shared/Confirm/confirm.component';

@Component({
    components: {
        ConfirmComponent,
    },
})
export default class AppComponent extends VueWrapper {
    public userMembership: any = '';

    public created() {
        // console.log(this.$route.query);
    }
    public mounted() {
        //
    }

    get cssProps() {
        const themeColors: any = {};

        return themeColors;
    }
}
