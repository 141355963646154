import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {GasDbModel} from '@/sdk';
import {Component, Prop} from 'vue-property-decorator';

@Component
export default class PreviewGasDbRecordDialogComponent extends VueWrapper {
    public readonly name: string = 'preview-gas-db-record-dialog';

    @Prop({
        type: Object,
        required: true,
    })
    public obj!: GasDbModel;

    public open() {
        this.CoreSrv.OpenModal(this.name);
    }

    public close() {
        this.CoreSrv.CloseModal(this.name);
    }
}
