import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {Component} from 'vue-property-decorator';
import SaveAccountDialogComponent from './save-account-dialog/save-account-dialog.component';
import {AccountSetupService, UserSetupService} from '@/views/shared/services';
import {AccountViewModel} from '@/sdk';
import UserSetupComponent from '@/views/user-setup/user-setup.component';
import InviteClientAdminDialogComponent from './invite-client-admin-dialog/invite-client-admin-dialog.component';

@Component({
    components: {
        SaveAccountDialogComponent,
        UserSetupComponent,
        InviteClientAdminDialogComponent,
    },
})
export default class AccountSetupComponent extends VueWrapper {
    public $refs!: {
        saveAccountDialogRef: SaveAccountDialogComponent;
        userSetupRef: UserSetupComponent;
        inviteClientAdminDialogRef: InviteClientAdminDialogComponent;
    };

    public accountSetupSrv: AccountSetupService = new AccountSetupService();
    public userSetupSrv: UserSetupService = new UserSetupService();

    public selectedAccount: AccountViewModel | null = null;

    public mounted() {
        this.accountSetupSrv
            .loadAccounts()
            .then(d => {})
            .catch(e => {});
    }

    public createAccountClick() {
        this.accountSetupSrv.reset();
        this.$refs?.saveAccountDialogRef?.open();
    }

    public inviteClick() {
        this.$refs.inviteClientAdminDialogRef.open();
    }

    public async editClick(account: AccountViewModel) {
        this.accountSetupSrv.reset();
        try {
            this.LoaderSrv.showFullScreenLoader();
            await this.accountSetupSrv.getAccountById(account.id!);
            this.$refs.saveAccountDialogRef?.open();
        } catch (error: any) {
            this.AlertSrv.show('error', error?.message ?? '');
        }

        this.LoaderSrv.hideFullScreenLoader();
    }

    // public accountUsersClick(account: AccountViewModel) {
    //     this.userSetupSrv.accountUsersList = [];

    //     this.selectedAccount = account;
    // }

    public selectedAccountCancelClick(account: AccountViewModel) {
        this.selectedAccount = null;
    }
}
