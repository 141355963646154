import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {Component} from 'vue-property-decorator';
import {SiteLocationService} from '../services';

@Component
export default class AreaRulesSummaryDialogComponent extends VueWrapper {
    public readonly name: string = 'area-rules-summary';

    public siteLocationSrv: SiteLocationService = new SiteLocationService();

    public open() {
        this.CoreSrv.OpenModal(this.name);
    }

    public close() {
        this.CoreSrv.CloseModal(this.name);
    }
}
