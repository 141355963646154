import {Component} from 'vue-property-decorator';
import {ValidationProvider} from 'vee-validate';
import BaseInput from '../Input/base-input';
import {AnyObject} from '@/globals';

@Component({
    components: {
        ValidationProvider,
    },
})
export default class BaseCombobox extends BaseInput {
    public get v() {
        if (this.$attrs['item-text'] && this.$attrs['item-value']) {
            const found = ((this.$attrs as any)['items'] as Array<AnyObject>)?.find(x => x[this.$attrs['item-value']] === this.value);
            if (found) return found[this.$attrs['item-text']];
        }
        return null;
    }
}
