import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {DocumentationModel, LocationModel} from '@/sdk';
import {DocumentationApi} from '@/sdk/api-services';
import {LocationApi} from '@/sdk/api-services/location.api';
import {Component, Prop} from 'vue-property-decorator';
import {LocationDcoumentationService} from '../services';
import {SiteLocationService} from '../services/site-location.service';

@Component({
    name: 'DocumentationListItemComponent',
})
export default class DocumentationListItemComponent extends VueWrapper {
    @Prop({
        type: String,
    })
    public parentId!: string;

    @Prop({
        type: Number,
        default: 1,
        required: true,
    })
    public level!: number;

    @Prop({
        type: String,
        required: true,
    })
    public parentType!: string;

    @Prop({
        type: Array,
    })
    public documentations!: Array<DocumentationModel>;

    // public treeData: Array<any> = [];

    public siteLocationsSrv: SiteLocationService = new SiteLocationService();
    public locationDocumentationSrv: LocationDcoumentationService = new LocationDcoumentationService();

    public mounted() {
        // this.treeData = [
        //     {
        //         id: '1',
        //         type: 'area',
        //         title: 'Area 1',
        //         siteId: '585425cd-ac7d-4d38-aa9a-296817eaf636',
        //     },
        //     {
        //         id: '2',
        //         type: 'area',
        //         title: 'Area 2',
        //         siteId: '585425cd-ac7d-4d38-aa9a-296817eaf636',
        //     },
        //     {
        //         id: '1',
        //         type: 'sub-area',
        //         title: 'Subarea 1',
        //         areaId: '1',
        //     },
        // ];
    }

    public locationIcon(location: LocationModel) {
        if (location.type === 'folder') {
            return '/images/common/d-folder.png';
        } else if (location.type === 'file') {
            return '/images/common/d-file.png';
        }
        return '';
    }

    public get getDcoumentations() {
        // if (this.parentType === 'site') {
        //     return this.locations?.filter(x => x.siteId === this.parentId && !x.locationId)?.sort((a, b) => (a.sequence ?? 0) - (b.sequence ?? 0)) ?? [];
        // }
        // if (this.parentType === 'area') {
        //     return this.locations?.filter(x => x.areaId === this.parentId) ?? [];
        // }
        // console.log(
        //     this.parentType,
        //     this.parentId,
        //     this.locations,
        //     this.locations?.filter(x => x.locationId === this.parentId)
        // );
        return !this.parentId
            ? this.documentations?.filter(x => x.documentationId == null)
            : this.documentations?.filter(x => x.documentationId === this.parentId);
        // ?.sort((a, b) => (a.sequence ?? 0) - (b.sequence ?? 0)) ?? [];
    }

    public locationItemClick(location: LocationModel) {
        //
    }

    public hasSubLocations(id: any) {
        return this.documentations?.some(x => x.documentationId === id);
    }

    public addFileClick(documentation: DocumentationModel) {
        this.locationDocumentationSrv.openAddFileDialog(this.siteLocationsSrv.selectedLcoation!, documentation, {level: this.level});
    }

    public addFolderClick(documentation: DocumentationModel) {
        this.locationDocumentationSrv.openAddFolderDialog(this.siteLocationsSrv.selectedLcoation!, documentation, {level: this.level});
    }

    public editLocation(documentation: DocumentationModel) {
        // const loc = this.documentations.find(x => x.id === documentation.);
        // console.log(loc, location);
        // this.siteLocationsSrv.openSiteLocationDialog(loc, location, {addAt: 'edit', level: this.level});
    }

    public deleteLocation(documentation: DocumentationModel) {
        this.ConfirmSrv.open('Delete Location', `Are you sure you want to delete this ${documentation.type}?`).then(y => {
            if (y) {
                this.LoaderSrv.showFullScreenLoader();
                new DocumentationApi()
                    .delete(documentation.id!)
                    .subscribe(
                        res => {
                            this.AlertSrv.show('success', `${documentation.type} has been deleted successfully!`);
                            this.siteLocationsSrv.getSiteLocation(this.siteLocationsSrv.selectedLcoation!.id!);
                        },
                        err => {
                            this.AlertSrv.show('error', `Unable to delete this ${documentation.type}. Please try again later!`);
                        }
                    )
                    .add(() => {
                        this.LoaderSrv.hideFullScreenLoader();
                    });
            }
        });
    }
}
