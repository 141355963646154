export class InspectionQuestionFieldOptionsModel {
    public id?: string | null = null;
    public inspectionFormQuestionId?: string | null = null;
    public option: string | null = null;
    public type: string | null = null;

    public constructor(init?: Partial<InspectionQuestionFieldOptionsModel>) {
        Object.assign(this, init);
    }
}
