import {ServiceClass} from '@/decorators';
import {AlertService, CreateUserViewModel, LoaderService, UserSession, UserViewModel} from '@/sdk';
import {AccountsApi, UsersApi} from '@/sdk/api-services';

@ServiceClass()
export class UserSetupService {
    public userToEdit: CreateUserViewModel = new CreateUserViewModel();

    public alerSrv: AlertService = new AlertService();
    public loaderSrv: LoaderService = new LoaderService();

    public usersApi: UsersApi = new UsersApi();
    public accountApi: AccountsApi = new AccountsApi();

    public accountUsersList: Array<UserViewModel> = [];

    public isRestricted: boolean = false;

    constructor() {
        //
    }

    public async getUserToEdit(userId: string, isRestricted: boolean = false) {
        this.isRestricted = isRestricted;

        this.loaderSrv.showFullScreenLoader('Loading User data...');

        try {
            const res = await this.usersApi.getUserToEdit(userId);
            this.userToEdit = res ?? new CreateUserViewModel();

            return true;
        } catch (error) {
            this.alerSrv.show('error', 'Unable to load user data.');

            return false;
        } finally {
            this.loaderSrv.hideFullScreenLoader();
        }
    }

    public async getAccountUsers(accountId: string) {
        try {
            this.loaderSrv.showFullScreenLoader('Loading Users...');
            const res = await this.accountApi.getAccountUsers(accountId, {
                include: [
                    {
                        relation: 'roles',
                        targetType: 'account',
                    },
                ],
            });
            this.accountUsersList = res ?? [];
        } catch (err: any) {
            this.alerSrv.show('error', err.message || 'Unable to load users.');
        } finally {
            this.loaderSrv.hideFullScreenLoader();
        }
    }

    public async save(accountId: string) {
        if (!this.isRestricted && accountId) {
            this.userToEdit.accountId = accountId;

            this.loaderSrv.showFullScreenLoader();
            try {
                if (this.userToEdit.id) {
                    const res = await this.usersApi.UpdateMember(this.userToEdit.id, new CreateUserViewModel(this.userToEdit)).toPromise();
                    this.alerSrv.show('success', 'User has been saved successfully!');

                    this.getAccountUsers(accountId);
                    return true;
                } else {
                    const res = await this.usersApi.Create(this.userToEdit).toPromise();
                    this.alerSrv.show('success', 'User ahs been saved successfully!');

                    this.getAccountUsers(accountId);
                    return true;
                }
            } catch (error: any) {
                this.alerSrv.show('error', error?.message ?? error?.error?.message ?? 'Unable to save user.');

                return false;
            } finally {
                this.loaderSrv.hideFullScreenLoader();
            }
        }
    }

    public reset() {
        this.userToEdit = new CreateUserViewModel();
    }
}
