import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {AnyObject} from '@/globals';
import {Component, Prop} from 'vue-property-decorator';

@Component
export default class SetPasswordDialogComponent extends VueWrapper {
    @Prop({
        type: String,
        default: () => {
            return 'user-set-password-dialog';
        },
    })
    public readonly name!: string;

    public showPassword: AnyObject = {
        pass: false,
        confirmPass: false,
    };

    public password: string | null = null;
    public passwordAgain: string | null = null;

    public async ok(validate: Function) {
        if (await validate()) {
            this.$emit('password', this.password);
            this.close();
        }
    }

    public open() {
        this.password = this.passwordAgain = null;
        this.CoreSrv.OpenModal(this.name);
    }

    public close() {
        this.CoreSrv.CloseModal(this.name);
    }
}
