import {ServiceClass} from '@/decorators';
import {UserViewModel} from '@/sdk';
import {SiteTeamMembersApi} from '@/sdk/api-services';

@ServiceClass()
export class SiteTeamMembersService {
    public siteWorkers: Array<UserViewModel> = [];
    // public siteInspectorSupervisors: Array<UserViewModel> = [];
    public siteJobManagers: Array<UserViewModel> = [];
    // public siteSafetyOfficers: Array<UserViewModel> = [];
    // public siteOperationManagers: Array<UserViewModel> = [];

    public getSiteWorkers(siteId: any) {
        new SiteTeamMembersApi().getSiteWorkers(siteId).subscribe(res => {
            this.siteWorkers = res.Data ?? [];
        });
    }

    // public getSiteInspectorSupervisors(siteId: any) {
    //     new SiteTeamMembersApi().getSiteInspectorSupervisors(siteId).subscribe(res => {
    //         this.siteInspectorSupervisors = res.Data ?? [];
    //     });
    // }

    // public getSafetyOfficers(siteId: any) {
    //     new SiteTeamMembersApi().getSafetyOfficers(siteId).subscribe(res => {
    //         this.siteSafetyOfficers = res.Data ?? [];
    //     });
    // }

    public getJobManagers(siteId: any) {
        new SiteTeamMembersApi().getJobManagers(siteId).subscribe(res => {
            this.siteJobManagers = res.Data ?? [];
        });
    }

    // public getOperationManagers(siteId: any) {
    //     new SiteTeamMembersApi().getOperationManagers(siteId).subscribe(res => {
    //         this.siteOperationManagers = res.Data ?? [];
    //     });
    // }
}
