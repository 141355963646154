import {ServiceClass} from '@/decorators';
import {AnyObject, KeyValuePair} from '@/globals';
import {AlertService, LoaderService, LocationModel, SiteModel} from '@/sdk';
import {LocationApi, SitesApi} from '@/sdk/api-services';
import {CoreService} from '@/services/core.service';

@ServiceClass()
export class JobLocationService {
    public name: string = 'save-location-dialog';
    public location: LocationModel = new LocationModel();

    public clickedLocationId?: string | null = null;

    public enterItemSiteId: string | null = null;
    public enterItemId: string | null = null;
    public selectedItemId: string | null = null;
    public selectedDragItem: LocationModel | null = null;

    public site: SiteModel | null = null;
    public parentLocation: LocationModel | null = null;

    public selectedLcoation: LocationModel | null = null;

    public siteLocations: Array<LocationModel> = [];

    public addAt: string = 'sub';
    private addAtLevel: number = 0;

    public types: Array<KeyValuePair> = [
        {
            key: 'location',
            value: 'Location',
        },
        {
            key: 'equipment',
            value: 'Equipment',
        },
    ];

    public get getTypes() {
        // if (this.addAtLevel === 1) {
        //     if (this.addAt === 'sub') {
        //         return [this.types[1], this.types[2]];
        //     }
        //     return this.types;
        // } else if (this.addAtLevel === 2) {
        //     if (this.addAt === 'sub') {
        //         return [this.types[2]];
        //     }
        //     return [this.types[1], this.types[2]];
        // } else if (this.addAtLevel === 3) {
        //     if (this.addAt === 'sub') {
        //         return [];
        //     }
        //     return [this.types[2]];
        // }
        return this.types;
    }

    public getSiteLocations(id?: string) {
        if (id || this.site?.id) {
            new LoaderService().showFullScreenLoader();
            new SitesApi()
                .getSiteLocations(id ?? this.site!.id!, {
                    // include: [
                    //     {
                    //         relation: 'equipmentDetail',
                    //     },
                    // ],
                })
                .subscribe(
                    res => {
                        const locations = res.Data ?? [];

                        for (const location of locations) {
                            const found = this.siteLocations.find(x => x.id === location.id);
                            location.expanded = found?.expanded || false;
                        }
                        this.siteLocations = locations;
                    },
                    err => {
                        new AlertService().show('error', err.message ?? err.Message ?? 'Unable to load site.');
                    }
                )
                .add(() => {
                    new LoaderService().hideFullScreenLoader();
                });
        }
    }
}
