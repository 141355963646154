import {ServiceClass} from '@/decorators';
import {AccountViewModel, AlertService, LoaderService} from '@/sdk';
import {AccountsApi, UsersApi} from '@/sdk/api-services';
import {map} from 'rxjs/operators';

@ServiceClass()
export class AccountService {
    public usersApi: UsersApi = new UsersApi();
    public accountApi: AccountsApi = new AccountsApi();

    public account: AccountViewModel = new AccountViewModel();

    public loaderSrv: LoaderService = new LoaderService();
    public alertSrv: AlertService = new AlertService();

    public loadUserAccount(userId: string) {
        return this.usersApi.getUserAccount(userId).then(x => {
            return (this.account = x ?? new AccountViewModel());

            return x;
        });
    }

    public async save() {
        this.loaderSrv.showFullScreenLoader();
        try {
            if (this.account.id) {
                const res = await this.accountApi.UpdateAccount(this.account.id, this.account);
                this.alertSrv.show('success', 'Account has been saved successfully!');

                return true;
            } else {
                this.alertSrv.show('error', 'Unable to save account.');
            }
        } catch (error) {
            this.alertSrv.show('error', 'Unable to save account.');

            return false;
        } finally {
            this.loaderSrv.hideFullScreenLoader();
        }
    }

    public reset() {
        this.account = new AccountViewModel();
    }
}
