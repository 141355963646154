import VueWrapper from '@/components/core/Vue/vue.wrapper';
import ClientAppBarComponent from '@/components/vuetify/client-app-bar/client-app-bar.component';
import {Component} from 'vue-property-decorator';

@Component({
    components: {
        ClientAppBarComponent,
    },
})
export default class MainLayoutComponent extends VueWrapper {}
