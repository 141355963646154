import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {Component} from 'vue-property-decorator';
import {AdminDataFieldService, JobService} from '../../../shared/services';
import JobNonConformityRecordFilesDialogComponent from '../job-non-conformity-record-files-dialog/job-non-conformity-record-files-dialog.component';
import {
    CorrectiveActionResultViewModel,
    JobCompletionRegisterViewModel,
    JobNonConformityRecordsModel,
    SaveCompletionRegisterViewModel,
    SaveCorrectiveActionResultViewModel,
} from '@/sdk';
import JobDetailsFormComponent from '../job-details-form/job-details-form.component';

@Component({
    components: {
        JobNonConformityRecordFilesDialogComponent,
        JobDetailsFormComponent,
    },
})
export default class JobCorrectiveActionStatusComponent extends VueWrapper {
    public $refs!: {
        jobNonConformityRecordFilesDialog: JobNonConformityRecordFilesDialogComponent;
    };

    public jobsSrv: JobService = new JobService();
    public adminDataFieldSrv: AdminDataFieldService = new AdminDataFieldService();

    public correctiveActionRegister: SaveCompletionRegisterViewModel = new SaveCompletionRegisterViewModel();
    public savedCorrectiveActionRegister: JobCompletionRegisterViewModel | null = null;

    public correctiveActionResults: CorrectiveActionResultViewModel[] = [];

    addResult: boolean = false;

    reportFile: File | null = null;

    title: string | null = null;
    file: File | null = null;

    public created() {
        this.getCorrectiveActionRegister();
        this.getCorrectiveActionResults();
    }

    viewDocs(ncr: JobNonConformityRecordsModel) {
        this.$refs.jobNonConformityRecordFilesDialog.openDialog(ncr.id!);
    }

    get savedReportFileName() {
        return this.savedCorrectiveActionRegister?.completionReports?.[0]?.fileName ?? '';
    }

    getCorrectiveActionRegister() {
        this.jobsSrv.jobsApi
            .getCorrectiveActionCompletionRegister(this.jobsSrv.selectedJob!.id!)
            .then(res => {
                this.savedCorrectiveActionRegister = res || null;
                if (res) {
                    this.correctiveActionRegister = new SaveCompletionRegisterViewModel({
                        completionDate: res.completionDate,
                        personInChargeUserId: res.personInChargeUserId,
                    });
                } else {
                    this.correctiveActionRegister = new SaveCompletionRegisterViewModel();
                }
            })
            .catch(() => {
                //
            });
    }

    saveCorrectiveActionRegister() {
        this.LoaderSrv.showFullScreenLoader('Saving corrective action status.');
        this.jobsSrv.jobsApi
            .saveCorrectiveActionCompletionRegister(this.jobsSrv.selectedJob!.id!, this.correctiveActionRegister, this.reportFile!)
            .then(() => {
                this.AlertSrv.show('success', 'Corrective action status saved successfully.');
                this.getCorrectiveActionRegister();
                this.correctiveActionRegister = new SaveCompletionRegisterViewModel();
                this.reportFile = null;
            })
            .catch(err => {
                this.AlertSrv.show('error', 'Unable to save corrective action status.');
            })
            .finally(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    getCorrectiveActionResults() {
        this.jobsSrv.jobsApi
            .getCorrectiveActionResults(this.jobsSrv.selectedJob!.id!)
            .then(res => {
                this.correctiveActionResults = res;
            })
            .catch(err => {
                this.AlertSrv.show('error', 'Unable to load corrective action results.');
            });
    }

    addCorrectiveActionResult() {
        this.LoaderSrv.showFullScreenLoader('Saving corrective action result.');
        this.jobsSrv.jobsApi
            .saveCorrectiveActionResult(this.jobsSrv.selectedJob!.id!, new SaveCorrectiveActionResultViewModel({title: this.title}), this.file!)
            .then(() => {
                this.AlertSrv.show('success', 'Corrective action result saved successfully.');
                this.getCorrectiveActionResults();
                this.title = null;
                this.file = null;
                this.addResult = false;
            })
            .catch(err => {
                this.AlertSrv.show('error', 'Unable to save corrective action result.');
            })
            .finally(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    deleteCorrectiveActionResult(obj: CorrectiveActionResultViewModel) {
        this.ConfirmSrv.open('Delete corrective action result?', 'Are you sure you want to delete this corrective action result?').then(y => {
            if (y) {
                this.LoaderSrv.showFullScreenLoader('Deleting corrective action result.');
                this.jobsSrv.jobsApi
                    .deleteCorrectiveActionResults(this.jobsSrv.selectedJob!.id!, obj.id!)
                    .then(() => {
                        this.AlertSrv.show('success', 'Corrective action result deleted successfully.');
                        this.getCorrectiveActionResults();
                    })
                    .catch(err => {
                        this.AlertSrv.show('error', 'Unable to delete corrective action result.');
                    })
                    .finally(() => {
                        this.LoaderSrv.hideFullScreenLoader();
                    });
            }
        });
    }

    view(file: CorrectiveActionResultViewModel) {
        //
    }
}
