import {ServiceClass} from '@/decorators';
import {GasDbModel} from '@/sdk';
import {GasDbApi} from '@/sdk/api-services';
import {map} from 'rxjs/operators';

@ServiceClass()
export class GasDbService {
    public gasDbApi: GasDbApi = new GasDbApi();

    public gasDbData: Array<GasDbModel> = [];

    public selectedObj: GasDbModel = new GasDbModel();

    public loadGasDb() {
        return this.gasDbApi
            .getGasDb()
            .pipe(
                map(x => {
                    this.gasDbData = x.Data ?? [];

                    return x;
                })
            )
            .toPromise();
    }

    public uploadFile(file: File) {
        return new Promise((resolve, reject) => {
            this.gasDbApi.uploadFile(file).subscribe(
                res => {
                    resolve(null);
                },
                err => {
                    reject(err);
                }
            );
        });
    }
}
