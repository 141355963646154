import VueWrapper from '@/components/core/Vue/vue.wrapper';
import BaseFormComponent from '@/components/vuetify/Form/base-form';
import {UploadedFilesModel} from '@/sdk';
import {JobApi} from '@/sdk/api-services/job.api';
import {Component, Prop} from 'vue-property-decorator';
import PreviewDocumentDialogComponent from '../../../shared/preview-document-dialog/preview-document-dialog.component';

@Component({
    components: {
        PreviewDocumentDialogComponent,
    },
})
export default class JobNonConformityRecordFilesDialogComponent extends VueWrapper {
    @Prop({type: String, default: 'job-non-conformity-record-files-dialog'})
    public readonly name!: string;

    public $refs!: {
        baseForm: BaseFormComponent;
        previewDocumentDialogRef: PreviewDocumentDialogComponent;
    };

    @Prop({type: Boolean, default: false})
    isReadonly!: boolean;

    public filesList: Array<UploadedFilesModel> = [];

    public file: File | null = null;

    public title: string | null = null;

    public jobsApi: JobApi = new JobApi();

    public recordId: string | null = null;

    public loading: boolean = false;

    public mounted() {
        //
    }

    public onFilesSelected(event: FileList) {
        this.file = event[0];
        // const target = event.target as HTMLInputElement;
        // if (target.files) {
        // }
    }

    public loadFiles() {
        this.LoaderSrv.showFullScreenLoader();

        this.jobsApi
            .getJobNonConformityRecordFiles(this.recordId!)
            .then(res => {
                this.filesList = res ?? [];
            })
            .catch(err => {
                this.AlertSrv.show('error', 'Unable to load files. Please try again later!');
            })
            .finally(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    public saveFile() {
        if (!this.file || !this.title) {
            return;
        }

        this.LoaderSrv.showFullScreenLoader();

        this.jobsApi
            .uploadJobNonConformityRecordFile(this.recordId!, this.title, this.file)
            .then(res => {
                this.filesList.push(res);
                this.file = null;
                this.title = null;

                this.AlertSrv.show('success', 'File has been uploaded successfully!');
                this.reset();
            })
            .catch(err => {
                this.AlertSrv.show('error', 'Unable to upload file. Please try again later!');
            })
            .finally(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    public deleteFile(uploadedFile: UploadedFilesModel) {
        this.ConfirmSrv.open('Delete File', 'Are you sure you want to delete this file?').then(yes => {
            if (yes) {
                this.LoaderSrv.showFullScreenLoader();

                this.jobsApi
                    .deleteJobNonConformityRecordFile(this.recordId!, uploadedFile.id!)
                    .then(() => {
                        this.filesList = this.filesList.filter(x => x.id !== uploadedFile.id);
                        this.AlertSrv.show('success', 'File has been deleted successfully!');
                    })
                    .catch(err => {
                        this.AlertSrv.show('error', 'Unable to delete file. Please try again later!');
                    })
                    .finally(() => {
                        this.LoaderSrv.hideFullScreenLoader();
                    });
            }
        });
    }

    public previewFile(uploadedFile: UploadedFilesModel) {
        this.$refs.previewDocumentDialogRef.open(undefined, uploadedFile);
    }

    public downloadFile(uploadedFile: UploadedFilesModel) {
        this.LoaderSrv.showFullScreenLoader();
        this.jobsApi
            .downloadRequest<any>(`${this.ApiUrl}/uploaded-file/${uploadedFile.principalId}/get-file/${uploadedFile.fileName}`)
            .then(res => {
                const href = URL.createObjectURL(res.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', uploadedFile.fileName!); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch(err => {
                this.AlertSrv.show('error', 'Unable to download file. Please try again later!');
            })
            .finally(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    public reset() {
        this.title = null;

        this.$refs.baseForm.reset();
    }

    public openDialog(recordId: string) {
        if (!recordId) {
            return;
        }
        this.recordId = recordId;

        this.filesList = [];
        this.title = null;

        this.loadFiles();

        this.CoreSrv.OpenModal(this.name);
    }

    public closeDialog() {
        this.CoreSrv.CloseModal(this.name);
    }
}
