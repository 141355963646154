import {ServiceClass} from '@/decorators';
import {AnyObject, KeyValuePair} from '@/globals';
import {
    AddJobNormalWorkPermit,
    AlertService,
    EquipmentDetailModel,
    InspepctionFormModel,
    JobAssignedInspectionForm,
    JobModel,
    JobNonConformityRecordsModel,
    JobNormalWorkPermit,
    LoaderService,
    LocationDetailModel,
    LocationModel,
    SiteModel,
    UserViewModel,
} from '@/sdk';
import {LocationApi, ProjectsApi, SitesApi} from '@/sdk/api-services';
import {CoreService} from '@/services/core.service';
import {JobApi} from '@/sdk/api-services/job.api';
import {helpers} from '@/plugins/helpers/helpers';
import {JobLocationService} from './job-location.service';
import {SiteProjectsService} from './site-projects.service';

@ServiceClass()
export class JobService {
    public name: string = 'save-location-dialog';

    public jobLocationsSrv: JobLocationService = new JobLocationService();
    public siteProjectsSrv: SiteProjectsService = new SiteProjectsService();

    public jobsApi: JobApi = new JobApi();

    public jobs: Array<JobModel> = [];

    public loadingJobs: boolean = false;

    public selectedJob: JobModel | null = null;

    public jobsInspectionForms: Array<InspepctionFormModel> = [];

    public jobWorkers: Array<UserViewModel> = [];
    // public jobInspectorSupervisors: Array<UserViewModel> = [];
    public jobJobManagers: Array<UserViewModel> = [];
    // public jobSafetyOfficers: Array<UserViewModel> = [];
    // public jobOperationManagers: Array<UserViewModel> = [];

    public jobNonConformityRecords: Array<JobNonConformityRecordsModel> = [];

    // public selectedWorkPermits: Array<string> = [];
    public selectedInspectionForms: Array<string> = [];

    public jobAssignedInspectionForms: Array<JobAssignedInspectionForm> = [];

    public reset() {
        this.jobs = [];
        this.selectedJob = null;
    }

    // public getJobAssignedInspectionForms(jobId: string) {
    //     return new Promise(resolve => {
    //         new LoaderService().showFullScreenLoader();
    //         this.jobsApi
    //             .getJobAssignedInspectionForms(jobId)
    //             .subscribe(
    //                 res => {
    //                     this.jobAssignedInspectionForms = res.Data as any;
    //                     //this.selectedInspectionForms = this.jobAssignedInspectionForms.map(x => x.inspectionFormKey!) ?? [];
    //                 },
    //                 err => {
    //                     new AlertService().show('error', 'Unable to load job inspection forms');
    //                 }
    //             )
    //             .add(() => {
    //                 resolve(null);
    //                 new LoaderService().hideFullScreenLoader();
    //             });
    //     });
    // }

    public getJobProgress(job: JobModel) {
        if ((job.daysLeft ?? 0) < 0 || (job.endDate && job.endDate < new Date())) {
            return 'Delayed';
        }
        if (job.completedPercentage === 0) {
            return 'Not Started';
        }
        if (job.completedPercentage === 100) {
            return 'Completed';
        }
        return 'On-Going';
    }

    public getJobWorkers(jobId: string) {
        return new Promise(resolve => {
            new LoaderService().showFullScreenLoader();
            this.jobsApi
                .getJobWorkers(jobId)
                .subscribe(
                    res => {
                        this.jobWorkers = res.Data ?? [];
                    },
                    err => {
                        new AlertService().show('error', 'Unable to load workers');
                    }
                )
                .add(() => {
                    resolve(null);
                    new LoaderService().hideFullScreenLoader();
                });
        });
    }

    public getJobJobManagers(jobId: string) {
        return new Promise(resolve => {
            new LoaderService().showFullScreenLoader();
            this.jobsApi
                .getJobJobManager(jobId)
                .subscribe(
                    res => {
                        this.jobJobManagers = res.Data ?? [];
                    },
                    err => {
                        new AlertService().show('error', 'Unable to load job managers.');
                    }
                )
                .add(() => {
                    resolve(null);
                    new LoaderService().hideFullScreenLoader();
                });
        });
    }

    // jobNonConformityRecords
    public getJobNonConformityRecords(jobId: string) {
        return new Promise(resolve => {
            new LoaderService().showFullScreenLoader();
            this.jobsApi
                .getJobNonConformityRecords(jobId)
                .then(res => {
                    this.jobNonConformityRecords = res?.map(x => new JobNonConformityRecordsModel(x)) ?? [];
                })
                .catch(err => {
                    new AlertService().show('error', 'Unable to load job non conformity records.');
                })
                .finally(() => {
                    resolve(null);
                    new LoaderService().hideFullScreenLoader();
                });
        });
    }

    public getJobs(filter: AnyObject = {}) {
        this.loadingJobs = true;
        new JobApi()
            .getJobs(filter)
            .subscribe(res => {
                let jobs: any = res;
                jobs = jobs.map((job: any) => {
                    job.edit = false;
                    job.startDate = helpers.formatDate(new Date(job.startDate).toISOString(), 'yyyy-MM-dd');
                    job.endDate = helpers.formatDate(new Date(job.endDate).toISOString(), 'yyyy-MM-dd');
                    return job;
                });
                this.jobs = jobs;
            })
            .add(() => {
                this.loadingJobs = false;
            });
    }

    public getProjectJobs(projectId: string, filter: AnyObject = {}) {
        this.loadingJobs = true;
        new ProjectsApi()
            .getProjectJobs(projectId, filter)
            .then(res => {
                let jobs: any = res;
                jobs = jobs.map((job: any) => {
                    job.edit = false;
                    job.startDate = helpers.formatDate(new Date(job.startDate).toISOString(), 'yyyy-MM-dd');
                    job.endDate = helpers.formatDate(new Date(job.endDate).toISOString(), 'yyyy-MM-dd');
                    return job;
                });
                this.jobs = jobs;
            })
            .catch(err => {
                //
            })
            .finally(() => {
                this.loadingJobs = false;
            });
    }

    public updateJobs(jobs: any) {
        return new Promise(resolve => {
            new JobApi()
                .updateJobs(jobs)
                .then(res => {
                    console.log('success', res);
                })
                .finally(() => {
                    resolve(null);
                });
        });
    }

    public async addNewJobToList(equipment: LocationModel) {
        try {
            const locationDetails = await this.getLocationDetails(equipment.id!);
            equipment.equipmentDetail = locationDetails;

            let locationPath = [];
            let locationId = equipment.id;
            let foundLocation: LocationModel | undefined = equipment;

            while (locationId != null) {
                if (foundLocation) {
                    locationPath.push({id: foundLocation.id!, title: foundLocation.title!, type: 'equipment'});
                }
                locationId = foundLocation?.locationId;
                if (locationId) {
                    foundLocation = this.jobLocationsSrv.siteLocations.find(x => x.id === locationId);
                }
            }
            if (this.jobLocationsSrv.site) {
                locationPath.push({id: this.jobLocationsSrv.site.id!, title: this.jobLocationsSrv.site.siteName!, type: 'site'});
            }
            locationPath = locationPath.reverse();

            this.jobs.push(
                new JobModel({
                    siteId: equipment.siteId,
                    locationId: equipment.id,
                    projectId: this.siteProjectsSrv.selectedProject?.id,
                    eqType: equipment.equipmentDetail?.equipmentType,
                    eqClass: equipment.equipmentDetail?.equipmentClassification,
                    eqPath: locationPath.map(x => x.title).join(' / '),
                    location: JSON.parse(JSON.stringify(equipment)),
                    startDate: new Date(),
                    endDate: new Date(),
                })
            );
        } catch (error) {
            //
        }
    }

    public async getLocationDetails(locationId: string) {
        return new Promise((resolve: (d: EquipmentDetailModel | null) => void, reject) => {
            new LoaderService().showFullScreenLoader();
            new LocationApi()
                .equipmentDetail(locationId)
                .subscribe(
                    res => {
                        resolve(res.Data);
                    },
                    err => {
                        reject(err);
                    }
                )
                .add(() => {
                    new LoaderService().hideFullScreenLoader();
                });
        });
    }

    public async getFillableJobInspectionForms(jobId: string) {
        return new JobApi().fillableInspectionForms(jobId).then((res: any) => {
            this.jobsInspectionForms = res ?? [];
            return res;
        });
    }
}
