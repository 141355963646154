import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {SessionModel, SiteModel, UserSession} from '@/sdk';
import {SitesApi} from '@/sdk/api-services';
import {RolesService, UserSetupService} from '@/views/shared/services';
import {Component, Prop} from 'vue-property-decorator';
import SetPasswordDialogComponent from '../set-password-dialog/set-password-dialog.component';

@Component({
    components: {
        SetPasswordDialogComponent,
    },
})
export default class SaveUserDialogComponent extends VueWrapper {
    public $refs!: {
        setPasswordRef: SetPasswordDialogComponent;
    };

    @Prop({
        type: String,
        default: () => {
            return 'add-user-dialog';
        },
    })
    public readonly name!: string;

    @Prop({
        type: String,
        default: () => {
            return 'save';
        },
    })
    public readonly type!: string;

    @Prop({
        type: String,
    })
    accountId!: string;

    public rolesSrv: RolesService = new RolesService();
    public sitesApi: SitesApi = new SitesApi();

    public paymentMethods: Array<string> = ['Credit Card', 'Bank transfer/ACH', 'Paypal'];

    public userSetupSrv: UserSetupService = new UserSetupService();
    public sites: Array<SiteModel> = [];

    public userSession = new UserSession();
    public Session = new SessionModel();

    public mounted() {
        //
    }

    public onPassword(password: string) {
        this.userSetupSrv.userToEdit.password = password;
    }

    public get isProfile() {
        return this.type === 'profile';
    }

    public async save(validate: Function) {
        console.log('save click');
        const valid = await validate();

        if (!valid) {
            this.AlertSrv.show('warning', 'Please fill all the required fields in all tabs of the form to save.');
            return;
        }

        if (!this.userSetupSrv.userToEdit.id && !this.userSetupSrv.userToEdit.password) {
            this.AlertSrv.show('warning', `Please set the user's password.`);
            return;
        }

        this.userSetupSrv.userToEdit.selectedSiteRoleIds =
            this.sites
                .filter(x => x.checked)
                ?.map(x => {
                    return {roleId: x.roleId, siteId: x.id!};
                }) ?? [];

        if (await this.userSetupSrv.save(this.accountId)) {
            this.close();
        }
    }

    public loadAllSites() {
        this.LoaderSrv.showFullScreenLoader('Loading Sites...');
        this.sitesApi
            .getAccountSites(this.UserSessionSrv.accountId!)
            .then(res => {
                const sites = res ?? [];

                for (const site of sites) {
                    const f = this.userSetupSrv.userToEdit.selectedSiteRoleIds?.find(x => x.siteId === site.id!);
                    site.checked = !!f;

                    if (!!f) {
                        site.roleId = f.roleId;
                    }
                }

                this.sites = sites;
            })
            .catch(err => {})
            .finally(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    public open() {
        // this.loadAllSites();
        if (this.CoreSrv.isModalOpened(this.name)) {
            this.close();
        }
        this.CoreSrv.OpenModal(this.name);
    }
    public close() {
        this.CoreSrv.CloseModal(this.name);
    }

    public clearForm() {
        this.close();
    }
}
