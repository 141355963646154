import {DocumentationModel} from '../documentation/documentation.model';
import {DistributionPanelModel} from './distrubution-panel.model';
import {EquipmentDetailModel} from './equipment-detail.model';
import {LocationDetailModel} from './location-detail.model';
import {SwitchBoardModel} from './switch-boards.model';
import {WiringCableModel} from './wiring-cables.model';
import {CoresGroup} from './cores-group.model';

export class LocationModel {
    id?: string | null = null;
    title: string | null = null;
    sequence?: number;
    type?: string | null = null;
    created?: Date;
    updated?: Date;
    siteId?: string | null = null;
    locations?: Location[] = [];

    locationId?: string | null = null;

    equipmentDetail?: EquipmentDetailModel | null = null;
    distributionPanel?: DistributionPanelModel | null = null;
    locationDetail?: LocationDetailModel | null = null;
    documentations?: Array<DocumentationModel> | null = null;
    switchBoards?: Array<SwitchBoardModel> | [] = [];
    wiringCables?: WiringCableModel[] = [];
    coresGroups?: CoresGroup[] = [];

    parentLocation?: LocationModel | null = null;

    parentId?: string | null = null;

    [key: string]: any;

    constructor(data?: Partial<LocationModel>) {
        Object.assign(this, data);
        if (!data?.equipmentDetail) {
            this.equipmentDetail = new EquipmentDetailModel();
        } else {
            if (typeof data.equipmentDetail.ipy === 'string') {
                data.equipmentDetail.ipy = [data.equipmentDetail.ipy];
            }
            this.equipmentDetail = new EquipmentDetailModel(data?.equipmentDetail);
        }
        if (!data?.distributionPanel) {
            this.distributionPanel = new DistributionPanelModel();
        } else {
            this.distributionPanel = new DistributionPanelModel(data?.distributionPanel);
        }
        // if (!data?.switchBoards) {
        //     this.switchBoards = new SwitchBoardModel();
        // } else {
        //     this.distributionPanel = new SwitchBoardModel(data?.switchBoards);
        // }
        if (!data?.locationDetail) {
            this.locationDetail = new LocationDetailModel();
        } else {
            this.locationDetail = new LocationDetailModel(data.locationDetail);
        }
    }
}
