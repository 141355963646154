import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {ProjectModel} from '@/sdk';
import {Component, Prop} from 'vue-property-decorator';
import AddSiteProjectDialogComponent from '../add-site-project-dialog/add-site-project-dialog.component';
import {SiteProjectsService} from '../services';

@Component({
    components: {
        AddSiteProjectDialogComponent,
    },
})
export default class ProjectSelectionDialogComponent extends VueWrapper {
    public $refs!: {
        addSiteProjectDialogRef: AddSiteProjectDialogComponent;
    };
    public readonly name: string = 'project-selection-dialog';

    @Prop({
        type: String,
        required: true,
    })
    public readonly siteId!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    public readonly readonly!: boolean;

    public isSelected: boolean | false = false;
    public readonly selectedProjects?: string | null = null;

    public siteProjectsSrv: SiteProjectsService = new SiteProjectsService();

    public mounted() {
        //
    }

    public editProject(project: ProjectModel) {
        this.siteProjectsSrv.project = project;
        this.$refs.addSiteProjectDialogRef.open();
    }

    public checkboxClick(project: ProjectModel) {
        this.siteProjectsSrv.projectsList.forEach(x => (x.checked = false));
        project.checked = true;
        this.isSelected = true;
    }

    // public get isSelected() {
    //     console.log('this.siteProjectsSrv.projectsList', this.siteProjectsSrv.projectsList);
    //     return this.siteProjectsSrv.projectsList.some(x => x.checked);
    // }

    public projectSelected() {
        this.siteProjectsSrv.selectedProject = this.siteProjectsSrv.projectsList.find(x => x.checked) ?? null;
        this.$emit('selected', this.siteProjectsSrv.selectedProject);
        this.close();
    }

    public open() {
        this.LoaderSrv.showFullScreenLoader();
        this.siteProjectsSrv.loadSiteProjects(this.siteId).finally(() => {
            this.LoaderSrv.hideFullScreenLoader();
        });

        this.CoreSrv.OpenModal(this.name);
    }
    public close() {
        this.CoreSrv.CloseModal(this.name);
    }
}
