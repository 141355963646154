import {ServiceClass} from '@/decorators';
import {helpers} from '@/plugins/helpers/helpers';
import {AlertService, LoaderService, ProjectModel} from '@/sdk';
import {SiteProjectsApi} from '@/sdk/api-services';
import {CoreService} from '@/services/core.service';

@ServiceClass()
export class SiteProjectsService {
    public projectsList: Array<ProjectModel> = [];

    public selectedProject: ProjectModel | null = null;

    public project: ProjectModel = new ProjectModel();

    public reset() {
        this.selectedProject = null;
        this.project = new ProjectModel();
        this.projectsList = [];
    }

    public loadSiteProjects(siteId: string) {
        return new Promise(resolve => {
            new SiteProjectsApi()
                .getSiteProjects(siteId)
                .then(res => {
                    this.projectsList =
                        res?.map(
                            x =>
                                new ProjectModel({
                                    ...x,
                                    startDate: x.startDate ? helpers.formatDate(new Date(x.startDate).toISOString(), 'yyyy-MM-dd') : null,
                                    endDate: x.endDate ? helpers.formatDate(new Date(x.endDate).toISOString(), 'yyyy-MM-dd') : null,
                                })
                        ) ?? [];
                    if (this.selectedProject) {
                        const found = this.projectsList.find(x => x.id === this.selectedProject?.id);
                        if (found) {
                            found.checked = true;
                        }
                    }
                })
                .catch(err => {
                    //
                })
                .finally(() => {
                    resolve(null);
                });
        });
    }

    public save(siteId: string) {
        return new Promise(resolve => {
            if (this.project.id) {
                new LoaderService().showFullScreenLoader();
                new SiteProjectsApi()
                    .updateProject(this.project.id, this.project)
                    .subscribe(
                        res => {
                            new AlertService().show('success', 'Project has been saved successfully!');
                            this.loadSiteProjects(siteId);
                            new CoreService().CloseModal('add-site-project-dialog');
                        },
                        err => {
                            new AlertService().show('error', err.message ?? 'Unable to save project.');
                        }
                    )
                    .add(() => {
                        new LoaderService().hideFullScreenLoader();
                        resolve(null);
                    });
            } else {
                new LoaderService().showFullScreenLoader();
                new SiteProjectsApi()
                    .addProject(siteId, this.project)
                    .subscribe(
                        res => {
                            new AlertService().show('success', 'Project has been added successfully!');
                            this.loadSiteProjects(siteId);
                            new CoreService().CloseModal('add-site-project-dialog');
                        },
                        err => {
                            new AlertService().show('error', err.message ?? 'Unable to save project.');
                        }
                    )
                    .add(() => {
                        new LoaderService().hideFullScreenLoader();
                        resolve(null);
                    });
            }
        });
    }
}
