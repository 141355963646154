import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {AnyObject} from '../../../globals';
import {Component, Prop} from 'vue-property-decorator';

@Component({})
export default class BaseFilePickerComponent extends VueWrapper {
    public $refs!: {
        inputField: HTMLInputElement;
        validationRef: any;
    };

    @Prop({
        default: 'auto',
        type: String,
    })
    public readonly height!: string;

    @Prop({
        default: '160px',
        type: String,
    })
    public readonly width!: string;

    @Prop({
        default: 'Browse files',
        type: String,
    })
    public readonly text!: string;

    @Prop({
        default: 'image/*',
        type: String,
    })
    public readonly accept!: string;

    @Prop({default: '', type: String})
    private readonly rules!: string;

    @Prop({
        type: Object,
    })
    public readonly customErrors!: AnyObject;

    @Prop({
        default: 0,
        type: Number,
    })
    public readonly maxSize!: number;

    public files: FileList | null = null;

    public created() {
        //
    }

    public mounted() {
        //
    }

    public pickerClick() {
        this.$refs.inputField?.click();
    }

    public async filesSelected(event: any) {
        // this.files = event.target.files;
        let filesToSend: Array<File> = event.target.files;

        if (this.maxSize > 0) {
            for (const file of event.target.files as Array<File>) {
                if (file.size > this.maxSize) {
                    this.AlertSrv.show('warning', `Each of selected file size should not be <br> greater than ${this.maxSize / 1024 / 1024} MB`);

                    filesToSend = [];
                }
                // console.log(file.size);
            }
        }

        this.$emit('filesSelected', filesToSend);
        // console.log(this.files);
        if (this.$refs.inputField) {
            this.$refs.inputField.value = '';
        }
        setTimeout(() => {
            this.validate();
        }, 500);
    }

    public async validate() {
        return (await this.$refs.validationRef?.validate()) || true;
    }
}
