import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {Component, Prop} from 'vue-property-decorator';
import {InspectionFormsService, JobService, JobsInspectionFormsService} from '../services';

@Component
export default class SingleInspectionFormComponent extends VueWrapper {
    @Prop({
        required: false,
    })
    gradeOfInspection!: Array<string>;

    @Prop({
        type: String,
        required: true,
    })
    public readonly inspectionFormId!: string;

    public jobInspectionFormsSrv: JobsInspectionFormsService = new JobsInspectionFormsService();
    public jobSrv: JobService = new JobService();

    public get inspectionForm() {
        return this.jobInspectionFormsSrv.findInspectionFormById(this.inspectionFormId);
    }

    public get inspectionFormQuestions() {
        return this.jobInspectionFormsSrv.inspectionFormQuestionsAgainsJob[this.inspectionFormId] ?? [];
    }

    public saveInspectionForm() {
        const toSave = this.inspectionFormQuestions
            .map(x => {
                return {
                    inspectionFormQuestionsTableId: x.id,
                    answer: x.inspectionFormQuestionAnswers?.[0]?.answer ?? null,
                    comment: x.inspectionFormQuestionAnswers?.[0]?.comment ?? null,
                    jobId: this.jobSrv.selectedJob ? this.jobSrv.selectedJob.id : null,
                    id: x.inspectionFormQuestionAnswers?.[0]?.id ?? null,
                };
            })
            ?.filter(x => x.answer !== null || !!x.id);

        if (toSave.length === 0) {
            return;
        }

        this.LoaderSrv.showFullScreenLoader();
        this.jobInspectionFormsSrv.inspectionFormQuestionsApi
            .saveQuestionAnswers(this.inspectionFormId!, toSave)
            .then(() => {
                this.jobInspectionFormsSrv.loadInspectionFormQuestionsAgainsJob(
                    this.inspectionFormId!,
                    this.jobSrv.selectedJob!.id!,
                    this.UserSessionSrv.userId!
                );
                this.AlertSrv.show('success', 'Inspection form saved successfully');
            })
            .catch(() => {
                this.AlertSrv.show('error', 'Error while saving inspection form');
            })
            .finally(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }
}
