import {ServiceClass} from '@/decorators';
import {BaseApi} from './base.api';
import {InspectionFormQuestionModel, InspectionQuestionFieldOptionsModel} from '../models';

@ServiceClass()
export class InspectionFormQuestionsApi extends BaseApi {
    public async getInspectionFormQuestionsAsync(inspectionFormId: string, filter?: any) {
        if (!filter) {
            filter = {};
        }

        return await this.GET_RequestAsync<Array<InspectionFormQuestionModel>>(
            `${this.ApiUrl}/inspection-forms/${inspectionFormId}/questions?filter=${encodeURIComponent(JSON.stringify(filter))}`
        );
    }

    public getInspectionFormQuestionsAgainsJobAsync(inspectionFormId: string, jobId: string, filter?: any) {
        if (!filter) {
            filter = {};
        }

        return this.GET_RequestAsync<Array<InspectionFormQuestionModel>>(
            `${this.ApiUrl}/inspection-forms/${inspectionFormId}/questions/${jobId}?filter=${encodeURIComponent(JSON.stringify(filter))}`
        );
    }

    public async getInspectionFormQuestionByIdAsync(id: string) {
        return await this.GET_RequestAsync<InspectionFormQuestionModel>(`${this.ApiUrl}/inspection-form-questions/${id}/by-id`);
    }

    public async updateInspectionFormQuestionByIdAsync(id: string, data: InspectionFormQuestionModel) {
        return await this.PATCH_RequestAsync<void>(`${this.ApiUrl}/inspection-form-questions/${id}`, data);
    }

    public async createInspectionFormQuestionAsync(data: InspectionFormQuestionModel) {
        delete data.id;

        return await this.POST_RequestAsync<InspectionFormQuestionModel>(`${this.ApiUrl}/inspection-form-questions`, data);
    }

    public async deleteInspectionFormQuestionByIdAsync(id: string) {
        return await this.DELETE_RequestAsync<void>(`${this.ApiUrl}/inspection-form-questions/${id}`);
    }

    public getInspectionQuestionOptions(questionId: string) {
        return this.GET_RequestAsync<Array<InspectionQuestionFieldOptionsModel>>(
            `${this.ApiUrl}/inspection-form-questions/${questionId}/inspection-question-field-options`
        );
    }

    public createInspectionQuestionOption(questionId: string, data: InspectionQuestionFieldOptionsModel) {
        return this.POST_RequestAsync<any>(`${this.ApiUrl}/inspection-form-questions/${questionId}/inspection-question-field-options`, data);
    }

    public deleteInspectionQuestionOption(questionId: string, optionId: string) {
        return this.DELETE_RequestAsync<void>(`${this.ApiUrl}/inspection-form-questions/${questionId}/inspection-question-field-options/${optionId}`);
    }

    public getInspectionQuestionComments(questionId: string) {
        return this.GET_RequestAsync<Array<any>>(`${this.ApiUrl}/inspection-form-questions/${questionId}/inspection-question-comments`);
    }

    public createInspectionQuestionComment(questionId: string, data: any) {
        return this.POST_RequestAsync<any>(`${this.ApiUrl}/inspection-form-questions/${questionId}/inspection-question-comments`, data);
    }

    public deleteInspectionQuestionComment(questionId: string, commentId: string) {
        return this.DELETE_RequestAsync<void>(`${this.ApiUrl}/inspection-form-questions/${questionId}/inspection-question-comments/${commentId}`);
    }

    public saveQuestionAnswers(inspectionFormId: string, data: Array<any>) {
        return this.POST_RequestAsync<Array<any>>(`${this.ApiUrl}/inspection-form-questions/save-question-answers/${inspectionFormId}`, data);
    }
}
