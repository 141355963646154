import {AnyObject} from '@/globals';

export const equipmentTypesList: AnyObject = {
    accumulator: [],
    actuator: [
        {
            key: 'rotary actuator',
            value: 'rotary actuator',
        },
        {
            key: 'pneumatic actuator',
            value: 'pneumatic actuator',
        },
    ],
    adapter: [],
    amplifier: [
        {
            key: 'hydraulic amplifier',
            value: 'hydraulic amplifier',
        },
    ],
    analyzer: [],
    antenna: [],
    barrier: [
        {
            key: 'IS barrier',
            value: 'IS barrier',
        },
    ],
    battery: [],
    beacon: [
        {
            key: 'fire beacon',
            value: 'fire beacon',
        },
    ],
    board: [
        {
            key: 'distribution board',
            value: 'distribution board',
        },
    ],
    'conduit box': [],
    'disconnect box': [],
    'gear box': [],
    'junction box': [
        {
            key: 'high voltage junction box',
            value: 'high voltage junction box',
        },
        {
            key: 'heat trace end junction box',
            value: 'heat trace end junction box',
        },
        {
            key: 'IS junction box',
            value: 'IS junction box',
        },
        {
            key: 'socket junction box',
            value: 'socket junction box',
        },
    ],
    'terminal box': [],
    breather: [],
    burner: [
        {
            key: 'mains burner',
            value: 'mains burner',
        },
    ],
    button: [
        {
            key: 'push button',
            value: 'push button',
        },
    ],
    capacitor: [],
    compressor: [
        {
            key: 'gas turbine compressor',
            value: 'gas turbine compressor',
        },
    ],
    control: [
        {
            key: 'purge control',
            value: 'purge control',
        },
    ],
    controller: [
        {
            key: 'flow controller',
            value: 'flow controller',
        },
        {
            key: 'level controller',
            value: 'level controller',
        },
        {
            key: 'pressure controller',
            value: 'pressure controller',
        },
        {
            key: 'temperature controller',
            value: 'temperature controller',
        },
    ],
    converter: [
        {
            key: 'I/P converter',
            value: 'I/P converter',
        },
    ],
    cooler: [],
    damper: [],
    detector: [
        {
            key: 'avtur detector',
            value: 'avtur detector',
        },
        {
            key: 'fire detector',
            value: 'fire detector',
        },
        {
            key: 'gas detector',
            value: 'gas detector',
        },
        {
            key: 'heat detector',
            value: 'heat detector',
        },
        {
            key: 'hydro carbon detector',
            value: 'hydro carbon detector',
        },
        {
            key: 'isolation detector',
            value: 'isolation detector',
        },
        {
            key: 'level detector',
            value: 'level detector',
        },
        {
            key: 'line of sight gas detector',
            value: 'line of sight gas detector',
        },
        {
            key: 'oxygen detector',
            value: 'oxygen detector',
        },
        {
            key: 'smoke detector',
            value: 'smoke detector',
        },
        {
            key: 'UV detector',
            value: 'UV detector',
        },
    ],
    element: [
        {
            key: 'flow element',
            value: 'flow element',
        },
        {
            key: 'heating element',
            value: 'heating element',
        },
        {
            key: 'level element',
            value: 'level element',
        },
        {
            key: 'position element',
            value: 'position element',
        },
        {
            key: 'pressure element',
            value: 'pressure element',
        },
        {
            key: 'speed element',
            value: 'speed element',
        },
        {
            key: 'temperature element',
            value: 'temperature element',
        },
        {
            key: 'vibration element',
            value: 'vibration element',
        },
    ],
    encoder: [
        {
            key: 'shaft encoder',
            value: 'shaft encoder',
        },
    ],
    exchanger: [
        {
            key: 'exchanger',
            value: 'exchanger',
        },
    ],
    extractor: [],
    fan: [],
    filter: [],
    fitting: [
        {
            key: 'fitting',
            value: 'fitting',
        },
    ],
    frame: [],
    gauge: [
        {
            key: 'flow gauge',
            value: 'flow gauge',
        },
        {
            key: 'level gauge',
            value: 'level gauge',
        },
        {
            key: 'pressure gauge',
            value: 'pressure gauge',
        },
        {
            key: 'temperature gauge',
            value: 'temperature gauge',
        },
    ],
    generator: [
        {
            key: 'turbine generator',
            value: 'turbine generator',
        },
    ],
    guard: [
        {
            key: 'flange guard',
            value: 'flange guard',
        },
    ],
    heater: [
        {
            key: 'ignition heater',
            value: 'ignition heater',
        },
    ],
    house: [
        {
            key: 'analyzer house',
            value: 'analyzer house',
        },
    ],
    housing: [
        {
            key: 'bell housing',
            value: 'bell housing',
        },
    ],
    indicator: [
        {
            key: 'flow indicator',
            value: 'flow indicator',
        },
        {
            key: 'level indicator',
            value: 'level indicator',
        },
        {
            key: 'position indicator',
            value: 'position indicator',
        },
        {
            key: 'pressure indicator',
            value: 'pressure indicator',
        },
        {
            key: 'speed indicator',
            value: 'speed indicator',
        },
        {
            key: 'temperature indicator',
            value: 'temperature indicator',
        },
        {
            key: 'vibration indicator',
            value: 'vibration indicator',
        },
    ],
    inlet: [],
    interrupter: [],
    isolator: [],
    light: [
        {
            key: 'bulkhead light',
            value: 'bulkhead light',
        },
        {
            key: 'emergency light',
            value: 'emergency light',
        },
        {
            key: 'halogen light',
            value: 'halogen light',
        },
        {
            key: 'warning light',
            value: 'warning light',
        },
    ],
    luminaire: [],
    meter: [
        {
            key: 'flow meter',
            value: 'flow meter',
        },
    ],
    monitor: [
        {
            key: 'positioning monitor',
            value: 'positioning monitor',
        },
    ],
    motor: [
        {
            key: 'pump motor',
            value: 'pump motor',
        },
    ],
    outlet: [
        {
            key: 'socket outlet',
            value: 'socket outlet',
        },
    ],
    panel: [
        {
            key: 'alarm panel',
            value: 'alarm panel',
        },
        {
            key: 'control panel',
            value: 'control panel',
        },
    ],
    plug: [],
    point: [
        {
            key: 'control point',
            value: 'control point',
        },
    ],
    positioner: [],
    pump: [
        {
            key: 'hydraulic pump',
            value: 'hydraulic pump',
        },
    ],
    ram: [
        {
            key: 'hydraulic ram',
            value: 'hydraulic ram',
        },
    ],
    resistor: [],
    scanner: [
        {
            key: 'flame scanner',
            value: 'flame scanner',
        },
    ],
    socket: [
        {
            key: 'switched socket',
            value: 'switched socket',
        },
    ],
    sounder: [],
    splitter: [
        {
            key: 'pilot mains splitter',
            value: 'pilot mains splitter',
        },
    ],
    starter: [
        {
            key: 'direct online starter',
            value: 'direct online starter',
        },
        {
            key: 'remote starter',
            value: 'remote starter',
        },
    ],
    station: [
        {
            key: 'control station',
            value: 'control station',
        },
        {
            key: 'shutdown station',
            value: 'shutdown station',
        },
    ],
    stirrer: [],
    stop: [
        {
            key: 'emergency stop',
            value: 'emergency stop',
        },
    ],
    strainer: [],
    switch: [
        {
            key: 'flow switch',
            value: 'flow switch',
        },
        {
            key: 'hand switch',
            value: 'hand switch',
        },
        {
            key: 'key switch',
            value: 'key switch',
        },
        {
            key: 'level switch',
            value: 'level switch',
        },
        {
            key: 'limit switch',
            value: 'limit switch',
        },
        {
            key: 'pressure switch',
            value: 'pressure switch',
        },
        {
            key: 'pressure difference switch',
            value: 'pressure difference switch',
        },
        {
            key: 'proximity switch',
            value: 'proximity switch',
        },
        {
            key: 'temperature switch',
            value: 'temperature switch',
        },
        {
            key: 'unit switch',
            value: 'unit switch',
        },
    ],
    system: [
        {
            key: 'control system',
            value: 'control system',
        },
        {
            key: 'instrument system',
            value: 'instrument station',
        },
        {
            key: 'pressure protection system',
            value: 'pressure protection station',
        },
        {
            key: 'battery protection station',
            value: 'battery protection station',
        },
    ],
    tank: [
        {
            key: 'fuel tank',
            value: 'fuel tank',
        },
        {
            key: 'oil tank',
            value: 'oil tank',
        },
    ],
    thermocouple: [],
    thermostat: [],
    trace: [
        {
            key: 'electrical heat trace',
            value: 'electrical heat trace',
        },
    ],
    transducer: [
        {
            key: 'pressure transducer',
            value: 'pressure transducer',
        },
    ],
    transformer: [],
    transistor: [],
    transmitter: [
        {
            key: 'flow indicating transmitter',
            value: 'flow indicating transmitter',
        },
        {
            key: 'level indicating transmitter',
            value: 'level indicating transmitter',
        },
        {
            key: 'pressure indicating transmitter',
            value: 'pressure indicating transmitter',
        },
        {
            key: 'temperature indicating transmitter',
            value: 'temperature indicating transmitter',
        },
        {
            key: 'flow transmitter',
            value: 'flow transmitter',
        },
        {
            key: 'level transmitter',
            value: 'level transmitter',
        },
        {
            key: 'pressure transmitter',
            value: 'pressure transmitter',
        },
        {
            key: 'temperature transmitter',
            value: 'temperature transmitter',
        },
        {
            key: 'speed transmitter',
            value: 'speed transmitter',
        },
        {
            key: 'vibration transmitter',
            value: 'vibration transmitter',
        },
    ],
    unit: [
        {
            key: 'control unit',
            value: 'control unit',
        },
        {
            key: 'interlock unit',
            value: 'interlock unit',
        },
        {
            key: 'stop/start unit',
            value: 'stop/start unit',
        },
        {
            key: 'tocsin unit',
            value: 'tocsin unit',
        },
    ],
    valve: [
        {
            key: 'ball valve',
            value: 'ball valve',
        },
        {
            key: 'blowdown valve',
            value: 'blowdown valve',
        },
        {
            key: 'blow-out valve',
            value: 'blow-out valve',
        },
        {
            key: 'mains burner valve',
            value: 'mains burner valve',
        },
        {
            key: 'check valve',
            value: 'check valve',
        },
        {
            key: 'flow control valve',
            value: 'flow control valve',
        },
        {
            key: 'hand valve',
            value: 'hand valve',
        },
        {
            key: 'positioning monitor valve',
            value: 'positioning monitor valve',
        },
        {
            key: 'motor operated valve',
            value: 'motor operated valve',
        },
        {
            key: 'relief valve',
            value: 'relief valve',
        },
        {
            key: 'pressure safety valve',
            value: 'pressure safety valve',
        },
        {
            key: 'emergency shutdown valve',
            value: 'emergency shutdown valve',
        },
        {
            key: 'solenoid valve',
            value: 'solenoid valve',
        },
        {
            key: 'pilot mains splitter valve',
            value: 'pilot mains splitter valve',
        },
        {
            key: 'level control valve',
            value: 'level control valve',
        },
        {
            key: 'pressure control valve',
            value: 'pressure control valve',
        },
        {
            key: 'temperature control valve',
            value: 'temperature control valve',
        },
    ],
    wash: [
        {
            key: 'wash',
            value: 'wash',
        },
    ],
    wire: [
        {
            key: 'firewire',
            value: 'firewire',
        },
    ],
};
