export class WiringCableModel {
    id?: string | null = null;
    name?: string | null = null;
    cableType?: string | null = null;

    cableModelNo?: string | null = null;
    manufacturer?: string | null = null;
    serialNo?: string | null = null;
    certificationNo?: string | null = null;
    protectionType?: string | null = null;
    modelNo?: string | null = null;
    cableManufacturer?: string | null = null;
    cableFunction?: string | null = null;
    coreInformation?: string | null = null;
    connectWith?: string | null = null;
    sequence?: number;
    connectWithMid?: string | null = null;
    connectDirection?: string | null = null;
    documentCertification?: string | null = null;
    documentDrawing?: string | null = null;
    connectionKey?: string | null = null;

    created?: string | null = null;
    updated?: string | null = null;

    locationId?: string | null = null;

    constructor(data?: Partial<WiringCableModel>) {
        Object.assign(this, data);
    }
}
