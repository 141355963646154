import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {GasDbService} from '@/views/shared/services';
import {Component} from 'vue-property-decorator';

@Component
export default class ImportGasDbDialogComponent extends VueWrapper {
    public readonly name: string = 'import-gas-db-dialog';

    public readonly gasDbSrv: GasDbService = new GasDbService();

    public file: File | null = null;

    public filesSelected(event: FileList) {
        if (event.length > 0) {
            this.file = event.item(0);
        }
    }

    public save() {
        if (this.file) {
            this.LoaderSrv.showFullScreenLoader('Uploding file and importing data.');
            this.gasDbSrv
                .uploadFile(this.file)
                .then(() => {
                    this.file = null;
                    this.close();
                    this.AlertSrv.show('success', 'A background import process has started.');
                })
                .catch(() => {
                    this.AlertSrv.show('error', 'Unable to import data.');
                })
                .finally(() => {
                    this.LoaderSrv.hideFullScreenLoader();
                });
        }
    }

    public open() {
        this.CoreSrv.OpenModal(this.name);
    }

    public close() {
        this.file = null;
        this.CoreSrv.CloseModal(this.name);
    }
}
