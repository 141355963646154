import {ServiceClass} from '@/decorators';
import {AnyObject} from '@/globals';
import {BaseApi} from './base.api';
import {DataFieldModel, FieldOptions} from '../models';

@ServiceClass()
export class DataFieldApi extends BaseApi {
    public areaFields() {
        return this.GET_Request<Array<DataFieldModel>>(`${this.ApiUrl}/data-fields/area-fields`);
    }

    public equipmentFields() {
        return this.GET_Request<Array<DataFieldModel>>(`${this.ApiUrl}/data-fields/equipment-fields`);
    }

    public areaFieldOptions() {
        return this.GET_RequestAsync<AnyObject>(`${this.ApiUrl}/data-fields/area-field-options`);
    }

    public equipmentFieldOptions() {
        return this.GET_RequestAsync<AnyObject>(`${this.ApiUrl}/data-fields/equipment-field-options`);
    }

    public getDataFieldOptions(id: string, filter: AnyObject = {}) {
        return this.GET_Request<Array<FieldOptions>>(`${this.ApiUrl}/data-fields/${id}/field-options?filter=${encodeURIComponent(JSON.stringify(filter))}`);
    }

    public createDataFieldOption(fieldId: string, value: string, dependentOnFieldId?: string, dependentOnFieldOptionId?: string, rp?: number) {
        return this.POST_Request<FieldOptions>(`${this.ApiUrl}/data-fields/${fieldId}/field-options`, {
            value,
            dependentOnFieldId,
            dependentOnFieldOptionId,
            regressPriority: rp != null ? +rp : rp,
        });
    }

    updateDataFieldOption(fieldId: string, id: string, value: string, dependentOnFieldId?: string, dependentOnFieldOptionId?: string, rp?: number) {
        return this.PATCH_Request<any>(`${this.ApiUrl}/data-fields/${fieldId}/field-options/${id}`, {
            value,
            dependentOnFieldId,
            dependentOnFieldOptionId,
            regressPriority: rp != null ? +rp : rp,
        });
    }

    public deleteDataFieldOption(fieldId: string, id: string) {
        return this.DELETE_Request<any>(`${this.ApiUrl}/data-fields/${fieldId}/field-options/${id}`);
    }

    markFieldRequired(id: string) {
        return this.PATCH_Request<any>(`${this.ApiUrl}/data-fields/${id}/mark-required`, {});
    }

    markFieldNotRequired(id: string) {
        return this.PATCH_Request<any>(`${this.ApiUrl}/data-fields/${id}/mark-not-required`, {});
    }

    public getDropdownsWithOptions() {
        return this.GET_Request<Array<DataFieldModel>>(`${this.ApiUrl}/data-fields/dropdowns-with-options`);
    }
}
