import {ServiceClass} from '@/decorators';
import {OPTION_TYPES} from '@/globals';
import {InspectionFormQuestionModel, InspectionQuestionCommentsModel, InspectionQuestionFieldOptionsModel} from '@/sdk';
import {InspectionFormQuestionsApi} from '@/sdk/api-services';

@ServiceClass()
export class InspectionFormQuestionsService {
    public inspectionFormQuestionsList: Array<InspectionFormQuestionModel> = [];
    public inspectionQuestionOptionsList: Array<InspectionQuestionFieldOptionsModel> = [];
    public inspectionQuestionCommentsList: Array<any> = [];

    public inspectionFormQuestion: InspectionFormQuestionModel = new InspectionFormQuestionModel();
    public inspectionQuestionOption: InspectionQuestionFieldOptionsModel = new InspectionQuestionFieldOptionsModel();
    public inspectionQuestionComment: InspectionQuestionCommentsModel = new InspectionQuestionCommentsModel();

    public inspectionFormQuestionsApi: InspectionFormQuestionsApi = new InspectionFormQuestionsApi();

    public selectedQuestionId: string | null = null;

    public get optionTypes() {
        return OPTION_TYPES;
    }

    public async loadInspectionFormQuestions(inspectionFormId: string) {
        return this.inspectionFormQuestionsApi.getInspectionFormQuestionsAsync(inspectionFormId).then(x => {
            this.inspectionFormQuestionsList = x ?? [];

            return x;
        });
    }

    public async loadInspectionFormQuestionById(id: string) {
        return this.inspectionFormQuestionsApi.getInspectionFormQuestionByIdAsync(id).then(x => {
            this.inspectionFormQuestion = x ?? new InspectionFormQuestionModel();

            return x;
        });
    }

    public async updateInspectionFormQuestionById(id: string, data: InspectionFormQuestionModel) {
        return this.inspectionFormQuestionsApi.updateInspectionFormQuestionByIdAsync(id, data).then(x => {
            this.loadInspectionFormQuestions(data.inspectionFormsTableId!);
            this.inspectionFormQuestion = new InspectionFormQuestionModel();

            return x;
        });
    }

    public async createInspectionFormQuestion(data: InspectionFormQuestionModel) {
        return this.inspectionFormQuestionsApi.createInspectionFormQuestionAsync(data).then(x => {
            this.loadInspectionFormQuestions(data.inspectionFormsTableId!);
            this.inspectionFormQuestion = x ?? new InspectionFormQuestionModel();

            return x;
        });
    }

    public async deleteInspectionFormQuestionById(id: string) {
        return this.inspectionFormQuestionsApi.deleteInspectionFormQuestionByIdAsync(id);
    }

    public async loadInspectionQuestionOptions(questionId: string) {
        return this.inspectionFormQuestionsApi.getInspectionQuestionOptions(questionId).then(x => {
            this.inspectionQuestionOptionsList = x ?? [];

            return x;
        });
    }

    public async createInspectionQuestionOption(questionId: string, data: InspectionQuestionFieldOptionsModel) {
        delete data.id;
        return this.inspectionFormQuestionsApi.createInspectionQuestionOption(questionId, data).then(x => {
            this.loadInspectionQuestionOptions(questionId);
            this.inspectionQuestionOption = new InspectionQuestionFieldOptionsModel();

            return x;
        });
    }

    public async deleteInspectionQuestionOption(questionId: string, optionId: string) {
        return this.inspectionFormQuestionsApi.deleteInspectionQuestionOption(questionId, optionId).then(x => {
            this.loadInspectionQuestionOptions(questionId);

            return x;
        });
    }

    public async loadInspectionQuestionComments(questionId: string) {
        return this.inspectionFormQuestionsApi.getInspectionQuestionComments(questionId).then(x => {
            this.inspectionQuestionCommentsList = x ?? [];

            return x;
        });
    }

    public async createInspectionQuestionComment(questionId: string, data: any) {
        delete data.id;
        return this.inspectionFormQuestionsApi.createInspectionQuestionComment(questionId, data).then(x => {
            this.loadInspectionQuestionComments(questionId);
            this.inspectionQuestionComment = new InspectionQuestionCommentsModel();

            return x;
        });
    }

    public async deleteInspectionQuestionComment(questionId: string, commentId: string) {
        return this.inspectionFormQuestionsApi.deleteInspectionQuestionComment(questionId, commentId).then(x => {
            this.loadInspectionQuestionComments(questionId);

            return x;
        });
    }

    public filterOptionsByType(type: string) {
        return this.inspectionQuestionOptionsList.filter(x => x.type === type);
    }

    public reset() {
        this.inspectionFormQuestionsList = [];
        this.inspectionQuestionOptionsList = [];
        this.inspectionQuestionCommentsList = [];

        this.inspectionFormQuestion = new InspectionFormQuestionModel();
        this.inspectionQuestionOption = new InspectionQuestionFieldOptionsModel();
        this.inspectionQuestionComment = new InspectionQuestionCommentsModel();
    }
}
