import {KeyValuePair} from './types';

export class GLOBAL_CONSTANTS {
    public static PageSize: number = 30;
    public static itemsPerPageOptions = [30, 50, 100, 200, -1];
}

export enum AzureDevopsWorkitemTypes {
    Task = 'Task',
    Bug = 'Bug',
}

export enum SORT_ORDER {
    ASC = 'asc',
    DESC = 'desc',
}

export enum PRINCIPAL_TYPES {
    USER = 'USER',
}
export enum MEDIA_TYPES {
    ICON_FILE = 'ICON_FILE',
    PNG_FILE = 'PNG_FILE',
}
export enum PARK_SITE_STATUSES {
    NoParkData = 'NoParkData',
    Claimed = 'Claimed',
    UnClaimed = 'UnClaimed',
    Contacted = 'Contacted',
}
export enum LISTING_STATUS {
    NEWLISTING = 'Listing',
    PRICEREDUCED = 'Price Reduced',
    SALEPENDING = 'Sale Pending',
    SOLD = 'Sold',
}
export enum AD_TYPES {
    ParkForSaleAd = 'ParkForSaleAd',
    ClassifiedAd = 'ClassifiedAd',
}

export enum WORK_PERMIT_TYPES {
    normalWorkPermit = 'normalWorkPermit',
    hotWorkPermit = 'hotWorkPermit',
    confinedSpaceWorkPermit = 'confinedSpaceWorkPermit',
    powerOutageWorkPermit = 'powerOutageWorkPermit',
    radiationWorkPermit = 'radiationWorkPermit',
}

export enum PageHitsType {
    SignUp = 'SignUP',
    HomePage = 'HomePage',
}

export const OPTION_TYPES = ['Pass', 'Fail', 'NA'];

export const WorkPermitTypes: Array<KeyValuePair> = [
    {
        key: 'normal-work-permit',
        value: 'Normal Work Permit',
        active: true,
    },
    {
        key: 'hot-work-permit',
        value: 'Hot Work Permit',
        active: false,
    },
    {
        key: 'confined-space-work-permit',
        value: 'Confined Space Work Permit',
        active: false,
    },
    {
        key: 'electrical-work-permit',
        value: 'Electrical Work Permit',
        active: false,
    },
    {
        key: 'gas-free-work-permit',
        value: 'Gas Free Permit',
        active: false,
    },
];

export const InspectionFormsList: Array<KeyValuePair> = [
    {
        key: 'inspection-form-exd',
        value: 'Inspection Form (Ex d)',
    },
    {
        key: 'inspection-form-exe',
        value: 'Inspection Form (Ex e)',
    },
    {
        key: 'inspection-form-exn',
        value: 'Inspection Form (Ex n)',
    },
    {
        key: 'inspection-form-ext',
        value: 'Inspection Form (Ex t)',
    },
    {
        key: 'inspection-form-exp',
        value: 'Inspection Form (Ex p)',
    },
    {
        key: 'inspection-form-exi',
        value: 'Inspection Form (Ex i)',
    },
];

export enum UserMembershipStatus {
    ACTIVE = 'ACTIVE',
    CANCELLED_SOON = 'CANCELLED_SOON',
    CANCELLED = 'CANCELLED',
    FUTURE = 'FUTURE',
}

export const ROLES = {
    SYS_ADMIN: 'system-admin',
    ACCOUNT_ADMIN: 'account-admin',
    // SAFETY_MANAGER: 'safety-manager',
    // SAFETY_OFFICER: 'safety-officer',
    // INSPECTOR_SUPERVISOR: 'inspector-supervisor',
    // INSPECTOR: 'inspector',
    // OPERATION_MANAGER: 'operation-manager',
    MANAGER: 'manager',
    WORKER: 'worker',
};

export const ROLE_TYPES = {
    ACCOUNT: 'account',
    SITE: 'site',
    TEAM: 'team',
    JOB: 'job',
};

export enum ACCOUNT_STATUS {
    Active = 'Active',
    Suspended = 'Suspended',
    Cancelled = 'Cancelled',
}

export enum PAYMENT_METHODS {
    CreditCard = 'CreditCard',
    BankTransfer = 'BankTransfer',
}

export const PaymentMethodsList: Array<KeyValuePair> = [
    {
        key: PAYMENT_METHODS.CreditCard,
        value: 'Credit Card',
    },
    {
        key: PAYMENT_METHODS.BankTransfer,
        value: 'Bank Transfer',
    },
];

export const SIGNS_TYPES = {
    SubContractorReceivingAuthority: 'sub-contractor-receiving-autority',
    SubContractorPerformingAuthority: 'sub-contractor-performing-autority',
    ContractorIssuingAuthority: 'contractor-issuing-authority',

    ContractorOperatingAuthority: 'contractor-operating-authority',
    SubContractorOperatingAuthority: 'sub-contractor-operating-authority',

    ClosureSubContractorPerformingAuthority: 'closure-sub-contractor-performing-authority',
    ClosureContractorPerformingAuthority: 'closure-contractor-performing-authority',
};

export enum PERMISSIONS {
    CanAddWorkPermit = 'can-add-work-permit',
    CanSignSuContractorReceivingAuthority = 'can-sign-sub-contractor-receiving-authority',
    CanSignSuContractorPerformingAuthority = 'can-sign-sub-contractor-performing-authority',

    CanAddToBeFilledByContractor = 'can-add-to-be-filled-by-contractor',
    CanSignContractorIssuingAuthority = 'can-sign-contractor-issuing-authority',
    CanSignContractorOperatingAuthority = 'can-sign-contractor-operating-authority',
    CanSignSubContractorOperatingAuthority = 'can-sign-sub-contractor-operating-authority',

    CanSignClosureSubContractorPerformingAuthority = 'can-sign-closure-sub-contractor-performing-authority',
    CanSignClosureContractorPerformingAuthority = 'can-sign-closure-contractor-performing-authority',
}

export const resizeImageToSpecificWidth = (base64: string): Promise<File> => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d')!;
    const img = new Image();
    let resolve: Function;
    img.onload = async () => {
        // if (img.width > 1000) {
        canvas.height = canvas.width * (img.height / img.width);
        const oc = document.createElement('canvas');
        const octx = oc.getContext('2d')!;
        oc.width = img.width * 0.5;
        oc.height = img.height * 0.5;
        octx.drawImage(img, 0, 0, oc.width, oc.height);
        octx.drawImage(oc, 0, 0, oc.width * 0.5, oc.height * 0.5);
        ctx.drawImage(oc, 0, 0, oc.width * 0.5, oc.height * 0.5, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(blob => {
            if (blob) {
                resolve(
                    new File([blob], 'media.png', {
                        type: 'image/png',
                        lastModified: Date.now(),
                    })
                );
            }
        });
        // } else {
        //     resolve(await urltoFile(base64, 'profile.png', 'image/png'));
        // }
    };
    img.src = base64;
    return new Promise((res: Function) => {
        resolve = res;
    });
};

export enum areaFieldsList {
    areaFlammableSubstance = 'areaFlammableSubstance',
    areaFlammableDust = 'areaFlammableDust',
    areaExplosionConsequence = 'areaExplosionConsequence',
    areaIpx = 'areaIpx',
    areaIpy = 'areaIpy',
    areaInspectionFrequency = 'areaInspectionFrequency',
    areaApprovalType = 'areaApprovalType',
    areaEnvironmentalCondition = 'areaEnvironmentalCondition',
    areaEnvironmentalRisk = 'areaEnvironmentalRisk',

    // areaTemperatureClassRequirement = 'areaTemperatureClassRequirement',
    areaGasTemperatureClass = 'areaGasTemperatureClass',

    areaGasArea = 'areaGasArea',
    areaGasGroup = 'areaGasGroup',
    areaDustArea = 'areaDustArea',
    areaDustGroup = 'areaDustGroup',
}

export enum equipmentFieldsList {
    equipmentZone = 'equipmentZone',
    // equipmentGasDustGroup = 'equipmentGasDustGroup',
    // equipmentTemperatureClassRequirement = 'equipmentTemperatureClassRequirement',
    equipmentEnvironmentCondition = 'equipmentEnvironmentCondition',
    equipmentType = 'equipmentType',
    equipmentEquipmentClassification = 'equipmentEquipmentClassification',
    equipmentFieldEquipmentAssociatedApparatus = 'equipmentFieldEquipmentAssociatedApparatus',
    equipmentDivisionForRiskLevel = 'equipmentDivisionForRiskLevel',
    equipmentEquipmentType = 'equipmentEquipmentType',
    equipmentPowerType = 'equipmentPowerType',
    equipmentACDC = 'equipmentACDC',
    equipmentIpx = 'equipmentIpx',
    equipmentIpy = 'equipmentIpy',
    equipmentInspectionFrequency = 'equipmentInspectionFrequency',
    equipmentCertificationScheme = 'equipmentCertificationScheme',
    equipmentProtectionTechnique = 'equipmentProtectionTechnique',
    equipmentProtectionTechniqueEpl = 'equipmentProtectionTechniqueEpl',
    equipmentEplGas = 'equipmentEplGas',
    // equipmentEplGasDust = 'equipmentEplGasDust',
    // equipmentEplGasDustSub = 'equipmentEplGasDustSub',
    equipmentGasGroup = 'equipmentGasGroup',
    equipmentEplDust = 'equipmentEplDust',
    equipmentDustGroup = 'equipmentDustGroup',
    equipmentGasTemperatureClass = 'equipmentGasTemperatureClass',
    equipmentWiringCableEntryType = 'equipmentWiringCableEntryType',
    equipmentWiringCableProtectionType = 'equipmentWiringCableProtectionType',
    equipmentWiringCableFunction = 'equipmentWiringCableFunction',
    equipmentWiringDirection = 'equipmentWiringDirection',
}

export enum AreaSections {
    GeneralInformation = 'general-information',
    AreaClassification = 'area-classification',
}

export enum EquipmentSections {
    ParentAreaInformation = 'parent-area-information',
    GeneralInformation = 'general-information',
    EquipmentClassification = 'equipment-classification',
    WiringCableEntry = 'wiring-cable-entry',
}

/* -------------------------------------------------------------------------- */
/*                                  Reporting                                 */
/* -------------------------------------------------------------------------- */
export enum REPORT_PERIODS {
    Date = 'Date',
    // Today = 'Today',
    Yesterday = 'Yesterday',
    // ThisWeek = 'ThisWeek',
    // LastWeek = 'LastWeek',
    // ThisMonth = 'ThisMonth',
    // LastMonth = 'LastMonth',
    // CustomMonth = 'CustomMonth',
    Monthly = 'Monthly',
    Quarterly = 'Quarterly',
    Yearly = 'Yearly',
    CustomRange = 'CustomRange',
}
