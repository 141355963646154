export class CoresGroup {
    id?: string | null = null;
    name?: string | null = null;
    groupColor?: string | null = null;

    created?: string | null = null;
    locationId?: string | null = null;
    coresIds?: Array<string> | [] = [];

    constructor(data?: Partial<CoresGroup>) {
        Object.assign(this, data);
    }
}
