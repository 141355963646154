import {UserProfileModel} from '../user/user-profile.model';

export class SessionModel {
    public email: string | null = null;
    public loginId: string | null = null;
    public Expiry: string | null = null;
    public FirstName: string | null = null;
    public LastName: string | null = null;
    public token: string | null = null;
    public PhoneNumber: string | null = null;
    public rememberMe = false;
    public role: string | null = null;
    public roleId: string | null = null;
    public userId: string | null = null;
    public accountId: string | null = null;
    public SessionLogId: string | null = null;
    public UserProfile: UserProfileModel | null = null;

    constructor(data?: Partial<SessionModel>) {
        Object.assign(this, data);
    }
}
